<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="sen_label" class="label-required">{{
        "Libellé" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        id="sen_label"
        formControlName="sen_label"
        required="true"
        autofocus
        class="p-inputtext w-full"
      />
      @if(isErrorFormControl(editForm.controls['sen_label'])) {
      <small class="text-red-500">{{ "Libellé requis" | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="sen_external_code" class="label-required">{{
        "Code" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        formControlName="sen_external_code"
        id="sen_external_code"
        required="true"
        class="p-inputtext w-full"
      />
      @if(isErrorFormControl(editForm.controls['sen_external_code'])) {
      <small class="text-red-500">{{ "Code requis" | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="sen_cli_id" class="label-required">{{
        "Licence" | transloco
      }}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        (ngModelChange)="onLicenceChange()"
        class="w-full"
        formControlName="sen_cli_id"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence' | transloco"
        [required]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
      ></p-dropdown>
      @if(isErrorFormControl(editForm.controls['sen_cli_id'])) {
      <small class="text-red-500">{{ "Licence requise" | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="sen_cli_id" class="label-required">{{
        "Zone" | transloco
      }}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="filteredWorkspaces"
        class="w-full"
        formControlName="sen_wrk_id"
        [filter]="true"
        optionLabel="name"
        optionValue="id"
        [disabled]="workspaceDisabled"
        [required]="true"
        [group]="true"
      >
      <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
              <span>{{ group.name }}</span>
          </div>
      </ng-template>
      </p-dropdown>
      @if(isErrorFormControl(editForm.controls['sen_wrk_id'])) {
      <small class="text-red-500">{{ "Zone requise" | transloco }}</small>
      }
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-3">
        <label for="sen_min_temp" class="label-required"
          >{{ "Température minimale" | transloco }}
          <i class="fa fa-temperature-low fa-lg text-blue-500"></i
        ></label>
        <br />
        <p-inputNumber
          inputId="sen_min_temp"
          formControlName="sen_min_temp"
          suffix="℃"
          [min]="-50"
          [max]="50"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['sen_min_temp'])) {
        <small class="text-red-500">{{
          "Température minimum requise" | transloco
        }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-3">
        <label for="sen_max_temp" class="label-required"
          >{{ "Température maximale" | transloco }}
          <i class="fa fa-temperature-high fa-lg text-red-500"></i
        ></label>
        <br />
        <p-inputNumber
          inputId="sen_max_temp"
          formControlName="sen_max_temp"
          suffix="℃"
          [min]="-50"
          [max]="50"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['sen_max_temp'])) {
        <br /><small class="text-red-500">Température maximum requise</small>
        } @if(hasFormError(editForm, 'invalidRange')) {
        <br /><small class="text-red-500">{{
          "La température maximale doit être supérieure à la température minimale"
            | transloco
        }}</small>
        }
      </div>

      <div class="field col-12 md:col-12 xl:col-12">
        <h3>
          <i class="fa fa-business-time mr-2"></i>
          {{ "Relever les valeurs" | transloco }}
        </h3>
        {{ "Si aucun choix les valeurs seront relevées toutes les 10 minutes environ." | transloco }}
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_before_morning"
          value="1"
          [label]="'Avant le service du matin' | transloco"
        ></p-checkbox>
        <br />
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_after_morning"
          [label]="'Après le service du matin' | transloco"
        ></p-checkbox>
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_before_lunch"
          value="1"
          [label]="'Avant le service du midi' | transloco"
        ></p-checkbox>
        <br />
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_after_lunch"
          [label]="'Après le service du midi' | transloco"
        ></p-checkbox>
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_before_evening"
          value="1"
          [label]="'Avant le service du soir' | transloco"
        ></p-checkbox>
        <br />
        <p-checkbox
          class="mb-1"
          [binary]="true"
          formControlName="sen_display_after_evening"
          [label]="'Après le service du soir' | transloco"
        ></p-checkbox>
      </div>
    </div>
    @if(saveError) {
      <div class="text-red-500">
        {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
      </div>
      }
    <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <p-button
            type="submit"
            icon="pi pi-save"
            [disabled]="!editForm.valid"
            [label]="'Enregistrer' | transloco"
          ></p-button>
        </div>
        <div class="flex-none flex align-items-center justify-content-center mr-3">
          <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
        </div>
    </div>
  </p-panel>
</form>
