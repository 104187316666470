import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SensorsListComponent } from './components/settings/sensors/sensors-list/sensors-list.component';
import { SensorsEditComponent } from './components/settings/sensors/sensors-edit/sensors-edit.component';
import { SensorsHistoComponent } from './components/settings/sensors/sensors-histo/sensors-histo.component';
import { DishesListComponent } from './components/settings/dishes/dishes-list/dishes-list.component';
import { SensorsAlertListComponent } from './components/settings/sensors/sensors-alert-list/sensors-alert-list.component';
import { SensorsAlertEditComponent } from './components/settings/sensors/sensors-alert-edit/sensors-alert-edit.component';
import { SensorsTriggeredAlertComponent } from './components/infos/sensors-triggered-alert/sensors-triggered-alert.component';
import { UsersListComponent } from './components/settings/users/users-list/users-list.component';
import { UsersEditComponent } from './components/settings/users/users-edit/users-edit.component';
import { UsersLicencesComponent } from './components/settings/users/users-licences/users-licences.component';
import { MonitoringCouchdbComponent } from './components/infos/monitoring-couchdb/monitoring-couchdb/monitoring-couchdb.component';
import { IndicatorsListComponent } from './components/settings/indicators/indicators-list/indicators-list/indicators-list.component';
import { IndicatorsEditComponent } from './components/settings/indicators/indicators-edit/indicators-edit/indicators-edit.component';
import { IndicatorsFieldsEditComponent } from './components/settings/indicators/indicators-fields-edit/indicators-fields-edit/indicators-fields-edit.component';
import { IndicatorsUsersComponent } from './components/settings/indicators/indicators-users/indicators-users.component';
import { ProcessListTraceabilityComponent } from "./components/settings/process/process-list-traceability/process-list-traceability.component";
import { ProcessListTraceabilityParametersComponent } from "./components/settings/process/process-list-traceability-parameters/process-list-traceability-parameters.component";
import { ProcessListTraceabilityOutputsComponent } from "./components/settings/process/process-list-traceability-outputs/process-list-traceability-outputs.component";
import { ProcessListOutputReceptionComponent } from "./components/settings/process/process-list-output-reception/process-list-output-reception.component";
import { ProcessListOutputReceptionAddComponent } from "./components/settings/process/process-list-output-reception-add/process-list-output-reception-add.component";
import { ProcessListReceptionParametersComponent } from "./components/settings/process/process-list-reception-parameters/process-list-reception-parameters.component";
import { IndicatorsStatsComponent } from './components/settings/indicators/indicators-stats/indicators-stats.component';
import { StatsTargetsListComponent } from './components/settings/stats/stats-targets/stats-targets-list/stats-targets-list.component';
import { AlertsForUsersComponent } from "./components/settings/alerts-for-users/alerts-for-users.component";
import { AlertsForUsersAddComponent } from "./components/settings/alerts-for-users-add/alerts-for-users-add.component";
import { EshopComponent } from './components/infos/eshop/eshop/eshop.component';
import { EshopProductComponent } from './components/infos/eshop/eshop-product/eshop-product.component';
import { EshopCartComponent } from './components/infos/eshop/eshop-cart/eshop-cart.component';
import { EshopOrderComponent } from './components/infos/eshop/eshop-order/eshop-order.component';
import { EshopPaymentComponent } from './components/infos/eshop/eshop-payment/eshop-payment.component';
import { EshopPaymentSuccessComponent } from './components/infos/eshop/eshop-payment-success/eshop-payment-success.component';
import { EshopPaymentErrorComponent } from './components/infos/eshop/eshop-payment-error/eshop-payment-error.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'use-token/:dataToken',
    component: LoginComponent,
    data: { tokenLogin: true },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { path: undefined },
  },

  {
    path: 'infos/sensors-triggered-alert',
    component: SensorsTriggeredAlertComponent,
    data: { path: [{ label: 'Informations' }, { label: 'Alertes' }] },
  },
  {
    path: 'infos/monitoring-couchdb',
    component: MonitoringCouchdbComponent,
    data: { path: [{ label: 'Informations' }, { label: 'Monitoring CouchDB' }] },
  },
  {
    path: 'infos/eshop',
    component: EshopComponent,
    data: { path: [{ label: 'Informations' }, { label: 'Boutique' }] },
  },
  {
    path: 'infos/eshop-cart',
    component: EshopCartComponent,
    data: { 
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Mon panier' }
      ],
     },
  },
  {
    path: 'infos/eshop-order',
    component: EshopOrderComponent,
    data: { 
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Panier', routerLink: 'infos/eshop-cart' },
        { label: 'Validation de la commande' }
      ],
     },
  },
  {
    path: 'infos/eshop-payment',
    component: EshopPaymentComponent,
    data: { 
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Panier', routerLink: 'infos/eshop-cart' },
        { label: 'Commande', routerLink: 'infos/eshop-order' },
        { label: 'Paiement' }
      ],
     },
  },
  {
    path: 'infos/eshop-payment-success',
    component: EshopPaymentSuccessComponent,
    data: { 
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Panier', routerLink: 'infos/eshop-cart' },
        { label: 'Commande', routerLink: 'infos/eshop-order' },
        { label: 'Validation du paiement' }
      ],
     },
  },
  {
    path: 'infos/eshop-payment-error',
    component: EshopPaymentErrorComponent,
    data: { 
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Panier', routerLink: 'infos/eshop-cart' },
        { label: 'Commande', routerLink: 'infos/eshop-order' },
        { label: 'Erreur lors du paiement' }
      ],
     },
  },
  {
    path: 'infos/eshop-product/:id/:name',
    component: EshopProductComponent,
    data: {
      path: [
        { label: 'Informations' },
        { label: 'Boutique', routerLink: 'infos/eshop' },
        { label: 'Fiche produit' },
      ],
    },
  },
  {
    path: 'settings/sensors',
    component: SensorsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Sondes' }] },
  },
  {
    path: 'settings/sensors-add',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Ajouter une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-edit/:id',
    component: SensorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Modifier une sonde' },
      ],
    },
  },
  {
    path: 'settings/sensors-histo/:id',
    component: SensorsHistoComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Historique des mesures' },
      ],
    },
  },
  {
    path: 'settings/sensors-alert',
    component: SensorsAlertListComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Alertes' },
      ],
    },
  },
  {
    path: 'settings/sensors-alert-add',
    component: SensorsAlertEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Alertes', routerLink: 'settings/sensors-alert' },
        { label: 'Ajouter une alerte' },
      ],
    },
  },
  {
    path: 'settings/sensors-alert-edit/:id',
    component: SensorsAlertEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Sondes', routerLink: 'settings/sensors' },
        { label: 'Alertes', routerLink: 'settings/sensors-alert' },
        { label: 'Modifier une alerte' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability',
    component: ProcessListTraceabilityComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Process' }] },
  },
  {
    path: 'settings/process/process-list-traceability-parameters/:id',
    component: ProcessListTraceabilityParametersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Modifier les paramètres' },
      ],
    },
  },
  {
    path: 'settings/process/process-list-traceability-outputs/:id',
    component: ProcessListTraceabilityOutputsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process', routerLink: 'settings/process/process-list-traceability' },
        { label: 'Paramètrage des Outputs' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception', //
    component: ProcessListOutputReceptionComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Process de réception' }] },
  },
  {
    path: 'settings/process/process-reception/process-list-output-reception-add/:id',
    component: ProcessListOutputReceptionAddComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process de réception', routerLink: 'settings/process/process-reception' },
        { label: 'Nouvel output de réception' },
      ],
    },
  },
  {
    path: 'settings/process/process-reception/process-list-reception-parameters/:id',
    component: ProcessListReceptionParametersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Process', routerLink: 'settings/process/process-reception' },
        { label: 'Ajout/Modification de paramètre(s)' },
      ],
    },
  },
  {
    path: 'settings/dishes',
    component: DishesListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Recettes' }] },
  },
  {
    path: 'settings/users',
    component: UsersListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Utilisateurs' }] },
  },
  {
    path: 'settings/users-add',
    component: UsersEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Ajouter un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/users-edit/:id',
    component: UsersEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Modifier un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/users-licences/:id',
    component: UsersLicencesComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Utilisateurs', routerLink: 'settings/users' },
        { label: 'Modifier les licences d\'un utilisateur' },
      ],
    },
  },
  {
    path: 'settings/indicators',
    component: IndicatorsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Indicateurs de satisfaction' }] },
  },
  {
    path: 'settings/indicators-add',
    component: IndicatorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Ajouter un indicateur' },
      ],
    },
  },
  {
    path: 'settings/indicators-edit/:id',
    component: IndicatorsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur' },
      ],
    },
  },
  {
    path: 'settings/indicators-fields-add/:indicatorId/:parentFieldId/:nextOrder',
    component: IndicatorsFieldsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur', routerLink: 'settings/indicators-edit/:indicatorId' },
        { label: 'Ajouter un champ' },
      ],
    },
  },
  {
    path: 'settings/indicators-fields-edit/:indicatorId/:id',
    component: IndicatorsFieldsEditComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Modifier un indicateur', routerLink: 'settings/indicators-edit/:indicatorId' },
        { label: 'Modifier un champ' },
      ],
    },
  },
  {
    path: 'settings/indicators-users/:id',
    component: IndicatorsUsersComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Utilisateurs' },
      ],
    },
  },
  {
    path: 'settings/indicators-stats/:id',
    component: IndicatorsStatsComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Indicateurs de satisfaction', routerLink: 'settings/indicators' },
        { label: 'Statistiques' },
      ],
    },
  },
  {
    path: 'settings/stats-targets',
    component: StatsTargetsListComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Objectifs' }] },
  },
  {
    path: 'settings/alerts-for-users',
    component: AlertsForUsersComponent,
    data: { path: [{ label: 'Paramétrages' }, { label: 'Alertes utilisateurs' }] },
  },
  {
    path: 'settings/alerts-for-users-add/alerts-for-users-add/:id',
    component: AlertsForUsersAddComponent,
    data: {
      path: [
        { label: 'Paramétrages' },
        { label: 'Alertes utilisateurs', routerLink: 'settings/alerts-for-users' },
        { label: 'Nouvelle Alerte' },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
