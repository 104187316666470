import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ProcessList } from '../../models/process-list';
import { ProcessListParameters } from '../../models/process-list-parameters';


@Injectable({
  providedIn: 'root',
})
export class ApiProcessListParametersService {
  constructor(private http: HttpClient) {}

  getProcessListParameters(tpr_id:string): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_process_list_parameters.php', { params: { tpr_id } })
      .pipe(
        map((response: any[]) => {
          return response.map((dataProcessList) => {
            return new ProcessListParameters(
                dataProcessList.cli_name,
                dataProcessList.tpr_name_clean,
                parseInt(dataProcessList.tpa_id),
                parseInt(dataProcessList.tpa_tpr_id),
                dataProcessList.tpa_type,
                parseInt(dataProcessList.tpa_length),
                dataProcessList.tpa_validation,
                dataProcessList.tpa_default,
                parseInt(dataProcessList.tpa_default_when),
                parseInt(dataProcessList.tpa_readonly),
                dataProcessList.tpa_placeholder,
                dataProcessList.tpa_input_mode,
                dataProcessList.tpa_values_list,
                parseInt(dataProcessList.tpa_required),
                parseInt(dataProcessList.tpa_order),
                parseInt(dataProcessList.tpa_from_id),
                dataProcessList.tpa_script,
                parseInt(dataProcessList.tpa_is_dlc),
                dataProcessList.tpa_flag,
                parseInt(dataProcessList.tpa_future_date),
                parseInt(dataProcessList.tpa_display_in_table),
                dataProcessList.tpa_name_clean,
              )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

    updateProcessParameter(formData: FormData): Observable<any> {
        return this.http.post<any>(
            environment.apiURL +
            'espace_client/post_update_process_parameters.php',
            formData
        );
    }
    deleteProcessParameter(tpa_id: number): Observable<any> {
        return this.http.get<any>(
            environment.apiURL +
            'espace_client/delete_process_parameters.php?tpa_id=' +
            tpa_id
        );
    }
}
