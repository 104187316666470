@defer (when dataLoaded) {

  <p-table
    [value]="processList"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processList-session"
    [paginator]="true"
    [rows]="20"
    rowGroupMode="rowspan"
    groupRowsBy="client.id"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    [(selection)]="selectedProcess"
  >
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="client.id">{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
      <th pSortableColumn="tpr_name_clean">{{ 'Nom du process' | transloco }}<p-sortIcon field="tpr_name_clean"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_treatments" class="text-center">{{ 'Stats traitement' | transloco }}<p-sortIcon field="tpr_stats_treatments"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_lots" class="text-center">{{ 'Stats Lots' | transloco }}<p-sortIcon field="tpr_stats_lots"></p-sortIcon></th>
      <th pSortableColumn="tpr_stats_photos" class="text-center">{{ 'Stats Photos' | transloco }}<p-sortIcon field="tpr_stats_photos"></p-sortIcon></th>
      <th pSortableColumn="tpr_auto_close" class="text-center">{{ 'Cloture auto' | transloco }}<p-sortIcon field="tpr_auto_close"></p-sortIcon></th>
    @if(canWrite()) {
    <th></th>
    }
    </tr>
   <tr>
    <th>
        <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="licences"
              [placeholder]="'Toutes' | transloco"
              (onChange)="filter($event.value)"
              optionLabel="name"
              optionValue="id"
              [virtualScroll]="true"
              [showToggleAll]="false"
              [virtualScrollItemSize]="50"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
    </th>
    <th>
    <p-columnFilter type="text" field="tpr_name_clean" matchMode="contains"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_treatments" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_lots" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_stats_photos" class="custom-filter"></p-columnFilter>
    </th>
    <th class="text-center">
    <p-columnFilter type="boolean" field="tpr_auto_close" class="custom-filter"></p-columnFilter>
    </th>
    @if(canWrite()) {
    <th></th>
    }
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
      let-rowgroup="rowgroup"
      let-rowspan="rowspan"
      let-process>
  <tr>
    <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ process.client?.name }}</td>
    <td>{{ process.tpr_name_clean }}</td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_treatments, 'fa-regular fa-circle': !process.tpr_stats_treatments }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_lots, 'fa-regular fa-circle': !process.tpr_stats_lots }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_stats_photos, 'fa-regular fa-circle': !process.tpr_stats_photos }"></i>
    </td>
    <td class="text-center">
    <i class="fa fa-lg" [ngClass]="{ 'text-green-500 fa-circle-check': process.tpr_auto_close, 'fa-regular fa-circle': !process.tpr_auto_close }"></i>
    </td>
    @if(canWrite()) {
      <td class="flex align-items-center justify-content-center">
        <button
          pButton
          icon="pi pi-ban"
          class="flex p-button-rounded p-button-unactivate mr-2"
          (click)="deleteTheProcess(process)"
          [pTooltip]="'Supprimer le process' | transloco"
          tooltipPosition="left"
        ></button>
        <button
          pButton
          icon="pi pi-pencil"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="updateTheProcess(process)"
          [pTooltip]="'Modifier le process' | transloco"
          tooltipPosition="left"
        >
        </button>
        @if(process.tpr_hidden == 1) {
          <button
            pButton
            icon="pi pi-eye"
            class="flex p-button-rounded p-button-activate mr-2"
            [pTooltip]="'Re-afficher le process' | transloco"
            tooltipPosition="left"
            (click)="hideTheProcess(process, 0)"
          >
          </button>
          } @else {
          <button
            pButton
            icon="pi pi-eye-slash"
            class="flex p-button-rounded p-button-unactivate mr-2"
            [pTooltip]="'Masquer le process' | transloco"
            tooltipPosition="left"
            (click)="hideTheProcess(process, 1)"
          >
          </button>
        }
        <button
          pButton
          icon="pi pi-print"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="outputSettings(process)"
          [pTooltip]="'Outputs' | transloco"
          tooltipPosition="left"
        >
        </button>
      </td>
      }
  </tr>
  </ng-template>
</p-table>
}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
