import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormsModule,
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  AbstractControlOptions,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';

import { TranslocoModule, setValue } from '@ngneat/transloco';

import { AuthService } from '../../../../services/auth/auth.service';
import { ApiUsersService } from '../../../../services/api/api-users.service';
import { SessionStorageService } from '../../../../services/storage/session-storage.service';

import { EditComponent } from '../../../core/edit/edit.component';
import { User } from '../../../../models/user';
import { GlobalDatas } from '../../../../models/global-datas';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrl: './users-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    PanelModule,
    PasswordModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class UsersEditComponent
  extends EditComponent
  implements OnInit, OnDestroy
{
  PROFIL_USER_ID: string = '3';

  editForm: FormGroup;
  saveError: boolean = false;
  userSubscription: Subscription = new Subscription();

  userId: string = '';
  isPasswordRequired: boolean = false;
  globalDatas: GlobalDatas | null = null;
  //userProfiles: any[] = [];
  writerDisabled: boolean = true;

  constructor(
    private apiUsersService: ApiUsersService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      login: ['', Validators.required],
      password: [''],
      password_confirm:  [''],
      email: ['', Validators.email],
      telephone_portable: [''],
      profil: [this.PROFIL_USER_ID, Validators.required],
      language: ['fr', Validators.required],
      survey_form: [false],
      writer_espace_client: [false],
    }, { validators: this.passwordValidator } as AbstractControlOptions);
  }

  passwordValidator(group: FormGroup) {
    const password = group.controls['password'].value;
    const passwordConfirm = group.controls['password_confirm'].value;

    const containsLetter = /[a-zA-Z]/.test(password);
    const containsNumber = /\d/.test(password);
    
    if(password != '') {
      if(password.length < 8 || password.length > 15) {
        return { badLengthPassword: true };
      } else if(!containsLetter || !containsNumber) {
        return { badFormatPassword: true };
      } else if (password != '' && passwordConfirm != '' && passwordConfirm !== password) {
        return { nonIdenticalPassword: true };
      }
    }

    return null;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.userId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.userId != '') {
      this.userSubscription = this.apiUsersService
        .getUser(this.userId)
        .subscribe((datauser: User) => {          
          this.editForm.controls['last_name'].setValue(datauser.lastName);
          this.editForm.controls['first_name'].setValue(datauser.firstName);
          this.editForm.controls['login'].setValue(datauser.login);
          this.editForm.controls['email'].setValue(datauser.email);
          this.editForm.controls['telephone_portable'].setValue(datauser.mobilePhone);
          this.editForm.controls['profil'].setValue(datauser.profilId?.toString());
          this.editForm.controls['language'].setValue(datauser.lang);

          if(datauser.surveyForm == 1) {
            this.editForm.controls['survey_form'].setValue(true);
          }

          if(datauser.writerEspaceClient == 1) {
            this.editForm.controls['writer_espace_client'].setValue(true);
          }

          this.onChangeProfile(datauser.profilId?.toString());

          this.dataLoaded = true;
        });
    } else {
      this.isPasswordRequired = true;
      this.dataLoaded = true;
    }

    this.globalDatas = this.sessionStorageService.get('globalDatas'); 
  }

  onChangeProfile(event: any): void {
    if (!event || event == this.PROFIL_USER_ID) {
      this.writerDisabled = true;
      this.editForm.controls['writer_espace_client'].setValue(false);
    } else {
      this.writerDisabled = false;
    }
  }

  changeNames(): void {
    const lastName = this.editForm.controls['last_name'].value;
    const firstName = this.editForm.controls['first_name'].value;
    const login = this.editForm.controls['login'].value;

    if(lastName != '' && firstName != '' && login == '') {
      this.editForm.controls['login'].setValue(firstName+'.'+lastName);
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  save(): void {
    let formData = new FormData();
    formData.append('usr_id', this.userId);

    const formControls = Object.keys(this.editForm.controls);

    // Parcourez les clés
    formControls.forEach(controlName => {
      // Vous pouvez accéder au contrôle individuel par son nom
      const control = this.editForm.get(controlName);

      if(control) {
        formData.append(controlName, control.value);
      }
    });
    
    console.log('formData', formData)


    this.apiUsersService.postUser(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        this.router.navigate(['settings/users']);
      }
    });
  }
}
