export class AlertsFrequencies {

  public afr_id: number;
  public afr_name: string;
  constructor(
    afr_id: number,
    afr_name: string,
  ) {
    this.afr_id = afr_id;
    this.afr_name = afr_name;
  }
}
