import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { EditComponent } from '../../../../core/edit/edit.component';
import { ApiIndicatorsService } from '../../../../../services/api/api-indicators.service';
import { SessionStorageService } from '../../../../../services/storage/session-storage.service';
import { GlobalDatas } from '../../../../../models/global-datas';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeSelectModule } from 'primeng/treeselect';
import { IndicatorField } from '../../../../../models/indicator-field';
import { Indicator } from '../../../../../models/indicator';
import { TreeNode } from 'primeng/api';
import { TreeService } from '../../../../../services/tree/tree.service';

@Component({
  selector: 'app-indicators-fields-edit',
  templateUrl: './indicators-fields-edit.component.html',
  styleUrl: './indicators-fields-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TreeSelectModule,
  ],
})
export class IndicatorsFieldsEditComponent
  extends EditComponent
  implements OnInit, OnDestroy
{
  editForm: FormGroup;
  saveError: boolean = false;
  indicSubscription: Subscription = new Subscription();
  fieldId: string = '';
  indicatorId: string = '';
  parentFieldId: string = '';
  globalDatas: GlobalDatas | null = null;
  indicatorFields: TreeNode[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiIndicatorService: ApiIndicatorsService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private treeService: TreeService
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      label: ['', Validators.required],
      parent_field_id: ['', Validators.required],
      field_type: ['', Validators.required],
      order: [1, Validators.required],
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.fieldId = this.route.snapshot.paramMap.get('id') ?? '';
    this.indicatorId = this.route.snapshot.paramMap.get('indicatorId') ?? '';
    this.parentFieldId =
      this.route.snapshot.paramMap.get('parentFieldId') ?? '0';

    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.globalDatas = this.sessionStorageService.get('globalDatas');

    if (this.fieldId != '') {
      // recuperer les données du champ
      this.indicSubscription = this.apiIndicatorService
        .getIndicatorField(this.fieldId)
        .subscribe((indicatorField: IndicatorField) => {
          this.editForm.controls['label'].setValue(indicatorField.label);
          this.editForm.controls['field_type'].setValue(
            indicatorField.fiedType.id
          );
          this.editForm.controls['order'].setValue(indicatorField.ordre);

          this.parentFieldId = indicatorField.parentId
            ? indicatorField.parentId.toString()
            : '0';

          this.loadTree();
        });
    } else {
      this.editForm.controls['order'].setValue(
        this.route.snapshot.paramMap.get('nextOrder')
      );
      
      this.loadTree();
    }
  }

  loadTree() {
    // charge les données de l'indicateur
    this.indicSubscription = this.apiIndicatorService
    .getIndicator(this.indicatorId)
    .subscribe((indicator: Indicator) => {
      indicator.fields = indicator.fields.map((oneField) => {
        oneField.ifi_parent_id = oneField.ifi_parent_id ?? 0;

        return oneField;
      });

      indicator.fields.unshift({
        ifi_id: 0,
        ifi_ift_id: '1',
        ifi_ind_id: '1',
        ifi_parent_id: null,
        ifi_label: 'Aucun',
        ifi_ordre: '2',
        ift_label: '',
        ift_code: 'ROOT',
      });

      this.indicatorFields = this.apiIndicatorService.convertToTreeNodes(
        indicator.fields
      );

      if (this.fieldId != '') {
        // on est sur un champ existant, on ne peut pas le placer sur lui meme ou sur un enfant
        this.indicatorFields = this.treeService.removeNodeAndChildren(this.indicatorFields, this.fieldId);
      }

      //on recherche le noeud à préselectionner
      let nodeToSelect = this.treeService.findNodeById(this.indicatorFields, this.parentFieldId);

      if(nodeToSelect) {
        this.editForm.controls['parent_field_id'].setValue(
          nodeToSelect
        );
      }
    });
  }

  
  ngOnDestroy() {
    this.indicSubscription.unsubscribe();
  }

  save() {
    let formData = new FormData();
    formData.append('ifi_id', this.fieldId);
    formData.append('ifi_ift_id', this.editForm.controls['field_type'].value);
    formData.append('ifi_ind_id', this.indicatorId);
    formData.append('ifi_parent_id', this.editForm.get('parent_field_id')?.value.data.id);
    formData.append('ifi_label', this.editForm.controls['label'].value);
    formData.append('ifi_ordre', this.editForm.controls['order'].value);

    this.apiIndicatorService
      .postIndicatorField(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          this.router.navigate(['settings/indicators-edit', this.indicatorId]);
        }
      });
  }
}
