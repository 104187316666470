export class ProcessOutputType {

  public tot_id: number;
  public tot_name: string;
  constructor(
    tot_id: number,
    tot_name: string,
  ) {
    this.tot_id = tot_id;
    this.tot_name = tot_name;
  }
}
