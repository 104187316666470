@defer (when dataLoaded) {
<div>
<h3 style="text-align: center">{{  "Nouvel output pour " + "\"" + name + "\"" }}</h3>
</div>
<div>
<p-panel [header]="'Configuration du l\'output' | transloco">
  <form [formGroup]="editForm" (ngSubmit)="save()" class="py-5">
    <div class="formgrid grid">
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="outputReceptionType" class="label-required">{{
          "Type de réception" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownOutputReceptionType"
          class="w-full"
          formControlName="param_outputReceptionType"
          [(ngModel)]="selectedOutputReceptionType"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_outputReceptionType'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="printType" class="label-required">{{
          "Type de sortie" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownPrintType"
          class="w-full"
          formControlName="param_printType"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_printType'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-6 xl:col-2">
        <label for="printName" class="label-required">{{ "Nom de l'étiquette" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="param_printName"
          [required]="true"
          class="inputName w-full mr-2"
        />
        @if(isErrorFormControl(editForm.controls['param_printName'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="required" class="label-required">{{
          "Requis ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_required"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_required'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="outputPath" class="label-required">{{
          "Chemin" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="path"
          class="w-full"
          formControlName="param_outputPath"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_outputPath'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
      <label for="param_order" class="label-required">{{ "Ordre" | transloco }}</label>
      <br />
      <p-inputNumber
        inputId="param_order"
        formControlName="param_order"
        [min]="1"
        [max]="100"
        [step]=1
        [showButtons]="true"
        [required]="true"
      ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['param_order'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
    </div>
  </form>
</p-panel>
  <div class="grid py-1">
    <div class="col"></div>
    <div class="col text-right">
      <button
        pButton
        type="submit"
        [label]="'Enregistrer' | transloco"
        icon="pi pi-save"
        class="p-button-success"
        [disabled]="!editForm.valid"
        (click)="save()"
      ></button>
    </div>
  </div>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
