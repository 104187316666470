import moment from 'moment';

export class SensorMeasure {
    public date: moment.Moment;
    public value: number;

    constructor(
        date: moment.Moment,
        value: number
        ) {
        this.date = date;
        this.value = value;
    }
}