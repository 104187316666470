<!--@defer (when dataLoaded) {-->
  <!--<p-table
      [value]="receptionOutputList"
      styleClass="p-datatable-gridlines p-datatable-striped"
      stateStorage="session"
      stateKey="receptionOutputList-session"
      [(selection)]="selectedOutput"
      selectionMode="single"
      [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '100%'}"
      (onRowSelect)="onRowSelect($event)"
      dataKey="rou_id"
      rowGroupMode="rowspan"
      groupRowsBy="client.id"
      editMode="row"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
      <ng-template pTemplate="header">
      <tr>
      <th pSortableColumn="client.id" rowspan="2" >{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
      <th pSortableColumn="cpr_name" rowspan="3" >{{ 'Type de réception' | transloco }}<p-sortIcon field="cpr_name"></p-sortIcon></th>
      <th colspan="4" class="text-center">{{ 'Output' | transloco }}</th>
      <th></th>
<th></th>
      </tr>
      <tr></tr>
      <tr>
        <th>
        <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
            [ngModel]="value"
            [options]="licences"
            [placeholder]="'Toutes' | transloco"
            (onChange)="filter($event.value)"
            optionLabel="name"
            optionValue="id"
            [virtualScroll]="true"
            [showToggleAll]="false"
            [virtualScrollItemSize]="50"
            class="liste-recherche-table">
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
        </th>

        <th pSortableColumn="rou_rot_id" class="text-center">{{ 'Type ' | transloco }}<p-sortIcon field="rou_rot_id"></p-sortIcon></th>
        <th class="text-center">{{ 'Nom' | transloco }}</th>
        <th class="text-center">{{ 'Obligatoire' | transloco }}</th>
        <th class="text-center">{{ 'Chemin' | transloco }}</th>
        <th class="text-center">{{ 'Ordre ' | transloco }}</th>
        <th></th>
      </tr>
      </ng-template>
      <ng-template pTemplate="body"
          let-output let-editing="editing"
          let-ri="rowIndex"
          style="{'align-item': 'center'}"
          let-rowgroup="rowgroup"
          let-rowspan="rowspan">
        <tr [pSelectableRow]="output">
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="text-left">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span>{{ output.cli_id + ' - ' + output.client?.name }}</span>
                <button
                pButton
                icon="pi pi-plus"
                class="p-button-success p-button-rounded py-2"
                [pTooltip]="'Ajouter un Output' | transloco"
                (click)="addOutput(output)"
                ></button>
            </div>
          </td>
          <td  class="text-left">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span>{{ output.cpr_name }}</span>
                <button
                pButton
                icon="pi pi-pencil"
                class="flex p-button-rounded p-button-success mr-2"
                (click)="updateReceptionParameter(output)"
                [pTooltip]="'Ajout/Modification de paramètre(s)' | transloco"
                tooltipPosition="left"
                ></button>
            </div>
          </td>
          <td [pEditableColumn]="output.rou_rot_id" pEditableColumnField="rou_rot_id">
            <p-cellEditor>
              <ng-template pTemplate="input">
              <p-dropdown
              [options]="dropdownOutputType"
              (onBlur)="onCellEdit(output)"
              appendTo="body"
              [(ngModel)]="output.rou_rot_id"
              [style]="{'width':'100%'}">
              </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
              {{ output.rot_name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td [pEditableColumn]="output.rou_name" pEditableColumnField="rou_name">
            <p-cellEditor>
            <ng-template pTemplate="input">
            <input
            pInputText
            type="text"
            (onBlur)="onCellEdit(output)"
            [(ngModel)]="output.rou_name" />
            </ng-template>
            <ng-template pTemplate="output">
            {{ output.rou_name }}
            </ng-template>
            </p-cellEditor>
          </td>
          <td [pEditableColumn]="output.rou_compulsory" pEditableColumnField="rou_compulsory">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                [options]="yesNoOptions"
                (onBlur)="onCellEdit(output)"
                appendTo="body"
                [(ngModel)]="output.rou_compulsory"
                [style]="{'width':'100%'}">
                </p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                {{ output.rou_compulsory ? 'OUI' : 'NON' }}
                </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-dropdown
              [style]="{ width: '100%' }"
              [options]="path"
              class="w-full"
              [filter]="true"
              optionLabel="label"
              optionValue="value"
              [required]="true"
              (onBlur)="onCellEdit(output)"
              [(ngModel)]="output.rou_exec_path"
            ></p-dropdown>
          </td>
          <td [pEditableColumn]="output.rou_order" pEditableColumnField="rou_order">
            <p-cellEditor>
              <ng-template pTemplate="input">
              <input
              pInputText
              type="number"
              (onBlur)="onCellEdit(output)"
              [(ngModel)]="output.rou_order" />
              </ng-template>
              <ng-template pTemplate="output">
              {{ output.rou_order }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="flex align-items-center justify-content-center" style="height:60px; display: flex;">
            <button
              pButton
              icon="pi pi-ban"
              class="flex p-button-rounded p-button-unactivate mr-2"
              (click)="deleteOutput(output)"
              [pTooltip]="'Supprimer l\'Output' | transloco"
              tooltipPosition="left"
            ></button>
            <button
              pButton
              icon="pi pi-save"
              class="flex p-button-rounded p-button-success mr-2"
              (click)="updateOutput(output)"
              [pTooltip]="'Modifier l\'Output' | transloco"
              tooltipPosition="left"
            ></button>
            </td>
      </tr>
    </ng-template>
  </p-table>-->
@defer (when dataLoaded) {
<div class="card">
  <p-table
    [value]="uniqueReceptionOutputList"
    stateStorage="session"
    stateKey="receptionOutputList-session"
    [(selection)]="selectedOutput"
    selectionMode="single"
    sortField="client.name"
    sortMode="single"
    dataKey="rou_id"
    rowGroupMode="subheader"
    groupRowsBy="client.id"
    [rowExpandMode]="'single'"
    (onRowSelect)="onRowSelect($event)"
    [tableStyle]="{ 'align-items': 'center', 'min-width': '50px', 'max-width': '100%' }"
    [paginator]="true"
    [rows]="15"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="client.id" rowspan="2" >{{ 'Licence' | transloco }} <p-sortIcon field="client.id"></p-sortIcon></th>
        <th pSortableColumn="cpr_name" rowspan="3" >{{ 'Type de réception' | transloco }}<p-sortIcon field="cpr_name"></p-sortIcon></th>
        <th colspan="4" class="text-center">{{ 'Output' | transloco }}</th>
        <th></th>
        <th></th>
      </tr>
      <tr></tr>
      <tr>
        <th>
          <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect
                [ngModel]="value"
                [options]="licences"
                [placeholder]="'Toutes' | transloco"
                (onChange)="filter($event.value)"
                optionLabel="name"
                optionValue="id"
                [virtualScroll]="true"
                [showToggleAll]="false"
                [virtualScrollItemSize]="10"
                class="liste-recherche-table">
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>

        <th pSortableColumn="rou_rot_id" class="text-center">{{ 'Type ' | transloco }}<p-sortIcon field="rou_rot_id"></p-sortIcon></th>
        <th class="text-center">{{ 'Nom' | transloco }}</th>
        <th class="text-center">{{ 'Obligatoire' | transloco }}</th>
        <th class="text-center">{{ 'Chemin' | transloco }}</th>
        <th class="text-center">{{ 'Ordre ' | transloco }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-output let-ri="rowIndex" let-expanded="expanded">
      <tr>
        <td colspan="8">
          <div style="display: flex; align-items: center; font-weight: bold; margin: 10px 0px 10px 0px">
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="output"
            (click)="toggleRowExpansion(output.client.id)"
            class="p-button-text p-button-rounded p-button-plain mr-2"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            [ngStyle]="{'color': 'black'}">
          </button>
          <span>{{ output.client?.id + ' - ' + output.client?.name }}</span>
          <button
            pButton
            icon="pi pi-plus"
            class="p-button-success p-button-rounded py-2"
            [pTooltip]="'Ajouter un Output' | transloco"
            [tooltipPosition]="'left'"
            [ngStyle]="{'margin-left': '25px'}"
            (click)="addOutput(output)"
          ></button>
          </div>
        </td>
      </tr>
    </ng-template>

<ng-template pTemplate="rowexpansion" let-output>
<ng-container *ngIf="isRowExpanded(output.client.id)">
<tr *ngFor="let subOutput of output.outputs">
        <td></td>
        <td class="text-left">
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <span>{{ subOutput.cpr_name }}</span>
            <button
              pButton
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              (click)="updateReceptionParameter(subOutput)"
              [pTooltip]="'Ajout/Modification de paramètre(s)' | transloco"
              tooltipPosition="left">
            </button>
          </div>
        </td>
        <td [pEditableColumn]="subOutput.rou_rot_id" pEditableColumnField="rou_rot_id">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="dropdownOutputType"
                (onBlur)="onCellEdit(subOutput)"
                appendTo="body"
                [(ngModel)]="subOutput.rou_rot_id"
                [style]="{ width: '100%', 'text-align': 'center' }">
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              <div style="text-align: center;">
              {{ subOutput.type }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td [pEditableColumn]="subOutput.rou_name" pEditableColumnField="rou_name">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input
                pInputText
                type="text"
                (blur)="onCellEdit(subOutput)"
                [(ngModel)]="subOutput.rou_name"
                style="text-align: center;"/>
            </ng-template>
            <ng-template pTemplate="output">
              <div style="text-align: center;">
              {{ subOutput.rou_name }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td [pEditableColumn]="subOutput.rou_compulsory" pEditableColumnField="rou_compulsory">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="yesNoOptions"
                (onBlur)="onCellEdit(subOutput)"
                appendTo="body"
                [(ngModel)]="subOutput.rou_compulsory"
                [style]="{ width: '100%', 'text-align': 'center'}"
                >
              </p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              <div style="text-align: center;">
              {{ subOutput.rou_compulsory ? 'OUI' : 'NON' }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="path"
            class="w-full"
            [filter]="true"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            (onBlur)="onCellEdit(subOutput)"
            [(ngModel)]="subOutput.rou_exec_path">
          </p-dropdown>
        </td>
        <td [pEditableColumn]="subOutput.rou_order" pEditableColumnField="rou_order">
          <p-cellEditor>
            <ng-template pTemplate="input">
            <p-inputNumber
              (blur)="onCellEdit(subOutput)"
              [(ngModel)]="subOutput.rou_order"
              [min]="1"
              [max]="100"
              [step]=1
              [showButtons]="true"
              [required]="true"
            ></p-inputNumber>
            </ng-template>
            <ng-template pTemplate="output">
              <div style="text-align: center;">
              {{ subOutput.rou_order }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="flex align-items-center justify-content-center" style="height:60px;">
          <button
            pButton
            icon="pi pi-ban"
            class="p-button-rounded p-button-unactivate mr-2"
            (click)="deleteOutput(subOutput)"
            [pTooltip]="'Supprimer l\'Output' | transloco"
            tooltipPosition="left">
          </button>
          <button
            pButton
            icon="pi pi-save"
            class="p-button-rounded p-button-success mr-2"
            (click)="updateOutput(subOutput)"
            [pTooltip]="'Modifier l\'Output' | transloco"
            tooltipPosition="left">
          </button>
        </td>
      </tr>
</ng-container>
    </ng-template>
  </p-table>
</div>
}
@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
