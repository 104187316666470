<div class="container">
    @if(authService.tokenLogin) {
        Authentification en cours....

        @if(loginError !== '') {
            <div class="text-red-500">{{ loginError | transloco }}</div>
        }
    } @else {
        <form #loginForm="ngForm" (ngSubmit)="login()">
            <p-card header="Zest HACCP" [subheader]="'Espace Client'| transloco" class="cardLogin">
                <ng-template pTemplate="header">
                    <img alt="Card" class="mt-2" src="assets/images/logo_login.png" />
                </ng-template>
    
                <div class="field">
                    <label for="username">{{ 'Identifiant' | transloco }}</label>
                    <input pInputText type="text" id="username" name="username" [(ngModel)]="username" required class="p-inputtext w-full" #usernameInput>
                    @if(loginForm.controls['username'] && loginForm.controls['username'].touched && loginForm.controls['username']['errors'] && loginForm.controls['username']['errors']['required']) {
                        <div class="text-red-500">{{ 'Identifiant requis' | transloco }}</div>
                    }
                </div>
                <div class="field">
                    <label for="passwd">{{ 'Mot de passe' | transloco }}</label>
                    <input id="passwd" name="passwd" value="" type="password" [(ngModel)]="passwd" required class="p-inputtext w-full" />
                    @if(loginForm.controls['passwd'] && loginForm.controls['passwd'].touched && loginForm.controls['passwd']['errors'] && loginForm.controls['passwd']['errors']['required']) {
                        <div class="text-red-500">{{ 'Mot de passe requis' | transloco }}</div>
                    }
                </div>
    
                @if(loginError !== '') {
                    <div class="text-red-500">{{ loginError | transloco }}</div>
                }
                    
                <ng-template pTemplate="footer" class="flex justify-content-center flex-wrap">
                    <p-button type="submit" [label]="'Connexion' | transloco" class="flex align-items-center justify-content-center" icon="pi pi-check" [disabled]="!loginForm.valid"></p-button>
                </ng-template>
            </p-card>
        </form>
    }
</div>
