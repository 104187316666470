import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ProcessList } from '../../models/process-list';


@Injectable({
  providedIn: 'root',
})
export class ApiProcessListService {
  constructor(private http: HttpClient) {}

  getProcessList(): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_process_list.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataProcessList) => {
            return new ProcessList(
              parseInt(dataProcessList.tpr_id),
              dataProcessList.tpr_name_clean,
              dataProcessList.tpr_stats,
              dataProcessList.tpr_stats_treatments,
              dataProcessList.tpr_stats_lots,
              dataProcessList.tpr_stats_photos,
              dataProcessList.tpr_auto_close,
              dataProcessList.tpr_hidden,
              dataProcessList.tpr_flag,
              new Client(
                parseInt(dataProcessList.tpr_cli_id),
                dataProcessList.cli_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteProcess(tpr_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_process.php?tpr_id=' +
      tpr_id
    );
  }

  hideProcess(tpr_id: number, processHidden: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/hide_process.php?tpr_id=' +
      tpr_id + '&processHidden='+ processHidden
    );
  }
}
