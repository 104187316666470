export class EshopProduct {
    public id: number;
    public title: string;
    public titleForUrl: string;
    public description: string;
    public shortDescription: string;
    public photo1: string;
    public photo2: string;
    public photo3: string;
    public htPrice: number;
    public tva: number;
    
    constructor(
        id: number,
        title: string,
        titleForUrl: string,
        description: string,
        shortDescription: string,
        photo1: string,
        photo2: string,
        photo3: string,
        htPrice: number,
        tva: number
    ) {
        this.id = id;
        this.title = title;
        this.titleForUrl = titleForUrl;
        this.description = description;
        this.shortDescription = shortDescription;
        this.photo1 = photo1;
        this.photo2 = photo2;
        this.photo3 = photo3;
        this.htPrice = htPrice;
        this.tva = tva;
    }
}