import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';
import {ApiProcessListService} from "../../../../services/api/api-process-list.service";
import {ApiProcessListParametersService} from "../../../../services/api/api-process-list-parameters.service";

import { ActivatedRoute } from '@angular/router';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';
import {ProcessListParameters} from "../../../../models/process-list-parameters";

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmationService, MessageService} from "primeng/api";
import { ChipsModule } from 'primeng/chips';
import {ProcessList} from "../../../../models/process-list";
import {ConfirmDialogModule} from "primeng/confirmdialog";


@Component({
  selector: 'app-process-list-traceability-parameters',
  templateUrl: './process-list-traceability-parameters.component.html',
  styleUrl: './process-list-traceability-parameters.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ChipsModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService],
})

export class ProcessListTraceabilityParametersComponent extends EditComponent implements OnInit, OnDestroy {
  ProcessListParametersSubscription: Subscription = new Subscription();

  processId: string = '';
  name: string = '';
  parameters!: ProcessListParameters[];
  licences : Client[] = [];
  tpaId: any;
  processSelected: any;
  processisSelected: boolean = false;
  areButtonsDisabled = false;
  cancelButtonDisabled= true;
  saveButtonDisabled = true;
  paramDlcByDefault: string = '';

  editForm: FormGroup;
  filteredTypes: { label: string, value: string }[];
  ouiNon: { label: string, value: number }[];
  ouiNonHisto: { label: string, value: number }[];
  longueur: { label: string, value: number|string|null }[];
  mode: { label: string, value: string|null }[];
  modeText: { label: string, value: number|string|null }[];
  longueurText: { label: string, value: number|string|null }[];
  listValues: string[] = [];
  default: any[] = [];
  firstList: string = '';

  constructor(
    private apiProcessListService: ApiProcessListService,
    private apiProcessListParametersService: ApiProcessListParametersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_order: ['', Validators.required],
      param_name: ['', Validators.required],
      param_type: ['', Validators.required],
      param_dlc: ['', Validators.required],
      param_length: [''],
      param_mode: [''],
      param_list:  [''],
      param_default: [''],
      param_default_when: [''],
      param_validation: [''],
      param_placeHolder: [''],
      param_readOnly: [''],
      param_requis: [''],
      param_dateFuture: [''],
      param_displayInTable: [''],
    })
    this.filteredTypes = [];
    this.longueur = [];
    this.ouiNon = [];
    this.ouiNonHisto = [];
    this.mode = [];
    this.modeText = [];
    this.longueurText = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.processId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    this.licences = this.authService.getUserLicences();

    this.ProcessListParametersSubscription = this.apiProcessListParametersService
      .getProcessListParameters(this.processId)
      .subscribe((selectedProcess: ProcessListParameters[]) => {
        this.parameters = selectedProcess;
        this.sortParametersByOrder();
      });
    this.licences = this.authService.getUserLicences();

    this.filteredTypes = [
      { label: 'Texte', value: 'varchar' },
      { label: 'Nombre entier', value: 'int' },
      { label: 'Nombre decimal', value: 'float' },
      { label: 'Date', value: 'date' },
      { label: 'Heure', value: 'hour' },
      { label: 'Separateur', value: 'separator' },
      { label: 'Commentaire', value: 'comment' },
    ];
    this.ouiNon = [
      { label: 'Oui (1)', value : 1 },
      { label: 'Oui (2)', value : 2 },
      { label: 'Non (0)', value : 0 },
      { label: 'Non (-1)', value : -1 }
    ];
    this.ouiNonHisto = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 }
    ];
    this.longueur = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];
    this.mode = [
      {label: 'Champ libre', value: null},
      {label: 'Variateur', value: 'touchspin'},
      {label: 'Horloge', value: 'clock'},
      {label: 'Calendrier',value: 'calendar'},
      {label: 'Liste', value: 'list'},
      {label: 'Table', value: 'table'},
    ];
    this.modeText = [
      {label: 'Champ libre', value: 'null'},
      {label: 'Liste', value: 'list'},
    ];
    this.longueurText = [
      {label: '5', value: 5},
      {label: '10', value: 10},
      {label: '30', value: 30},
      {label: '50', value: 50}
    ];

    this.dataLoaded = true;
  }

  sortParametersByOrder() {
    this.parameters.sort((a, b) => a.tpa_order - b.tpa_order);
  }

  onRowSelect(event: any, tpaId: any) {
    this.areButtonsDisabled = true;
    this.cancelButtonDisabled = false;
    this.saveButtonDisabled = false;
    this.processisSelected = true;
    this.processSelected = event;

    for (const paramKey in event) {
      if (paramKey === "tpa_values_list" && event.tpa_values_list){
        this.firstList = event.tpa_values_list;
        this.listValues = this.firstList.split('|');
      }
      else{
        this.firstList = event.tpa_values_list;
      }
    }

    if (this.processId != '') {
      this.ProcessListParametersSubscription = this.apiProcessListParametersService
        .getProcessListParameters(this.processId)
        .subscribe((dataparam: ProcessListParameters[]) => {
          this.tpaId = tpaId;
          for (const item of dataparam) {
            if (item.tpa_id == event.tpa_id) {
              this.editForm.controls['param_order'].setValue(event.tpa_order);
              this.editForm.controls['param_name'].setValue(event.tpa_name_clean);
              this.editForm.controls['param_length'].setValue(event.tpa_length);
              this.editForm.controls['param_type'].setValue(event.tpa_type);
              this.editForm.controls['param_mode'].setValue(event.tpa_input_mode);
              this.editForm.controls['param_list'].setValue(this.listValues);
              this.editForm.controls['param_default'].setValue(event.tpa_default);
              this.editForm.controls['param_default_when'].setValue(event.tpa_default_when);
              this.editForm.controls['param_validation'].setValue(event.tpa_validation);
              this.editForm.controls['param_placeHolder'].setValue(event.tpa_placeholder);
              this.editForm.controls['param_readOnly'].setValue(event.tpa_readonly);
              this.editForm.controls['param_requis'].setValue(event.tpa_required);
              this.editForm.controls['param_dlc'].setValue(event.tpa_is_dlc);
              this.editForm.controls['param_dateFuture'].setValue(event.tpa_future_date);
              this.editForm.controls['param_displayInTable'].setValue(event.tpa_display_in_table);

              this.paramDlcByDefault = event.tpa_default;
            }
          }
        });
    }
  }

  typeChange(){
      this.updateModeOptions(this.editForm.controls['param_type'].value);
      if (this.editForm.controls['tpa_input_mode'] && this.editForm.controls['tpa_input_mode'].value == 'calendar') {
        this.updateDLCoptions(this.editForm.controls['tpa_is_dlc']);
      }
    console.log('formData', this.editForm.controls);
  }

  deleteProcessParameter(processParameter: ProcessListParameters){
    this.confirmationService.confirm({
      message:
          this.translocoService.translate(
              'Vous êtes sur le point de supprimer définitivement ce paramètre !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListParametersService.deleteProcessParameter( processParameter.tpa_id).subscribe((response) => {
          if (response) {
            window.location.reload();
            console.log('Deletion OK');
          } else{
            console.log('Deletion KO');
          }
        });
      },
    });
  }
  addParameter(){
    this.processisSelected = true;
    this.cancelButtonDisabled = false;
    this.saveButtonDisabled = false;
    this.areButtonsDisabled = true;
    this.listValues = [];
    this.processSelected = {};
    this.processSelected['tpa_id'] = '';

    this.editForm.controls['param_order'].setValue(1);
    this.editForm.controls['param_name'].setValue('');
    this.editForm.controls['param_type'].setValue('');
    this.editForm.controls['param_length'].setValue(5);
  }

  cancel(){
    this.areButtonsDisabled = false;
    this.cancelButtonDisabled = true;
    this.processisSelected = false;
    this.ProcessListParametersSubscription.unsubscribe()

    this.editForm.controls['param_order'].setValue('');
  }

  updateModeOptions(value: string | number): void {
    let selectedValue: string | null = null;
    let selectedValuedefault: string | null = null;
    let selectedValuelongueur: number | null = null;

    switch (value) {
      case 'hour':
        this.mode = [
          {label: 'Horloge', value: 'clock'},
        ];
        this.default = [
          { value: 'date(\'H:i\')'}
        ];
        this.longueur = [
          {label: 'Longueur auto', value: 0}
        ];
        selectedValue = 'clock';
        selectedValuedefault = 'date(\'H:i\')';
        selectedValuelongueur = 0;
        break;
      case 'date':
        this.mode = [
          {label: 'Calendrier', value: 'calendar'},
        ];
        this.default = [
          { value: 'date(\'d/m/Y\')'}
        ];
        this.longueur = [
          {label: 'Longueur auto', value: 0}
        ];
        selectedValue = 'clock';
        selectedValuedefault = 'date(\'d/m/Y\')';
        selectedValuelongueur = 0;
        break;
      case 'separator':
      case 'comment':
        this.longueur = [
          {label: 'Longueur auto', value: 100}
        ];
        selectedValue = '';
        selectedValuedefault = '';
        selectedValuelongueur = 100;
        break;
      case 'varchar':
        this.modeText = [
          {label: 'Champ libre', value: ''},
          {label: 'Liste', value: 'list'},
        ];
        this.longueurText = [
          {label: '5', value: 5},
          {label: '10', value: 10},
          {label: '30', value: 30},
          {label: '50', value: 50}
        ];
        selectedValue = '';
        selectedValuelongueur = 5;
        break;
      case 'int':
        this.modeText = [
          {label: 'Variateur', value: 'touchspin'},
        ];
        this.longueurText = [
          {label: '10', value: 10}
        ];
        selectedValue = 'touchspin';
        selectedValuedefault = '';
        selectedValuelongueur = 10;
        break;
      case 'float':
        this.mode = [
          {label: 'Variateur', value: 'touchspin'},
          {label: 'Champ libre', value: 'null'},
        ];
        this.longueur = [
        {label: 'Longueur auto', value: 10.2}
      ];
        selectedValue = 'touchspin';
        selectedValuedefault = '';
        selectedValuelongueur = 10.2;
        break;
      default:
        this.mode = [
          {label: 'Liste', value: 'list'},
          {label: 'Table', value: 'table'},
          {label: 'Variateur', value: 'touchspin'}
        ];
        this.default = [
          {label: '', value: null}
        ];
        this.longueur = [
          {label: '5', value: 5},
          {label: '10', value: 10},
          {label: '30', value: 30},
          {label: '50', value: 50}
        ];
        selectedValue = null;
        selectedValuedefault = null;
        selectedValuelongueur = 5;
        break;
    }
    this.editForm.patchValue({ param_mode: selectedValue } );
    this.editForm.patchValue({ param_default: selectedValuedefault } );
    this.editForm.patchValue({ param_length: selectedValuelongueur } );
    this.editForm.patchValue({ param_dlc: -1 } );
  }

  updateDLCoptions(value: any) {
    let selectedValue: string | null = null;
    let selectedValuedefault: string | null = null;
    let selectedValuelongueur: number | null = null;
    console.log('update DLC')
    if (value == 0) {
      this.mode = [
        {label: 'Calendrier',value: 'calendar'}
      ];
      selectedValue = 'calendar';
      this.default = [
        {value: 'date(\'d/m/Y\')'}
      ];
      selectedValuedefault = 'date(\'d/m/Y\')';
      this.longueur = [
        {label: 'Longueur auto', value: 0}
      ];
      selectedValuelongueur = 0;
    }
    this.editForm.patchValue({ param_mode: selectedValue } );
    this.editForm.patchValue({ param_default: selectedValuedefault } );
    this.editForm.patchValue({ param_length: selectedValuelongueur } );
  }

  simulateEnterKey() {
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      bubbles: true,
      cancelable: true
    });
    const chipsInput = document.querySelector('p-chips input') as HTMLInputElement;
    if (chipsInput) {
      chipsInput.dispatchEvent(event);
    }
  }
  getTheList(): void {
    this.simulateEnterKey();
    const listValue = this.listValues.join('|');
    this.editForm.patchValue({ param_list: listValue } );
  }

  save(): void {
    let formData = new FormData();
    if (this.processSelected?.tpa_id) {
      formData.append('tpa_id', this.processSelected['tpa_id']);
    } else {
      formData.append('tpa_id', '');
    }
    formData.append('cli_id', this.processSelected?.cli_id)
    formData.append('tpr_id', this.processId);
    formData.append('DelOrUpdate', '1');
    if (this.editForm.controls['param_dlc'].value == 1) {
      this.editForm.patchValue({
        param_default: 'date(\'d/m/Y\', strtotime("+ " .max(function=getDlc(tre_id)/function,'
          + (this.editForm.controls['param_default'].value) * 1440 + ') ." minutes"))'
      });
    } else if (this.editForm.controls['param_dlc'].value == 0){
      this.updateDLCoptions(0);
    }
    if (this.editForm.controls['param_default'].value == "") {
      this.editForm.patchValue({
        param_default: this.paramDlcByDefault
      });
    }
    const formControls = Object.keys(this.editForm.controls);

    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    this.apiProcessListParametersService.updateProcessParameter( formData).subscribe((response) => {
      if (response) {
        window.location.reload();
        //console.log('Update OK');
      }
      else{
        console.log('Update KO');
      }
    });
  }
  ngOnDestroy() {
    this.ProcessListParametersSubscription.unsubscribe();
  }
}
