@defer (when dataLoaded) {
  <div class="grid flex">
    <div class="col-12">
    @if(orderInfos) {
        <p-panel [header]="'Erreur lors du paiement' | transloco">    
            <p>
              {{ "Une erreur est survenue lors du paiement sécurisé." | transloco }}<br>
              {{ "Veuillez réessayer ou utiliser un mode de paiement différent." | transloco }}                
            </p>
            <button
              pButton
              [label]="'Retour' | transloco"
              icon="fa-solid fa-arrow-left"
              class="flex-1"
              (click)="redirectOrder()"
          ></button>
        </p-panel>
    }
    @else {
      <p>
        {{ "Aucune commande en cours" | transloco }}
      </p>
    }
    </div>
  </div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
