import { Client } from "./client";
import { ClientGroup } from "./client-group";

export class User {
    public id: number;
    public login: string;
    public firstName: string;
    public lastName: string;
    public inDate: moment.Moment;
    public outDate: moment.Moment | null;

    public client: Client | null;
    public clientGroup: ClientGroup | null;

    public profilId: number | null = null;
    public lang:string = '';
    public email:string = '';
    public mobilePhone: string ='';
    public surveyForm: number= 0;
    public writerEspaceClient: number = 0;

    public fullName: string = '';

    constructor(
        id: number,
        login: string,
        firstName: string,
        lastName: string,

        inDate: moment.Moment,
        outDate: moment.Moment | null = null,

        client: Client | null = null,
        clientGroup: ClientGroup | null = null
        ) {
        this.id = id;
        
        this.login = login;
        this.firstName = firstName;
        this.lastName = lastName;

        this.inDate = inDate;
        this.outDate = outDate;

        this.client = client;
        this.clientGroup = clientGroup;

        this.fullName = this.lastName + ' ' + this.firstName;
    }
}