import moment from 'moment';
import { Client } from "./client";


export class ClientMonitoring {
    public client: Client;
    public majDate: moment.Moment;
    public totalDocs: number;
    public deletedDocs: number;
    public dbSize: number;
    public anomalie: number;
    public treatment: number;
    public treatmentHisto: number;
    public lot: number;
    public treatmentImage: number;
    public treatmentImageDelete: number;
    public traca: number;
    public reception: number;
    public lsClient: number;
    public task: number;
    public temperature: number;
    public oilChange: number;
    public oilControl: number;
    public measure: number;
    public taskHisto: number;
    public temperatureHisto: number;
    public oilChangeHisto: number;
    public oilControlHisto: number;
    public measureHisto: number;
    
    constructor(
        client: Client,
        majDate: moment.Moment,
    
        totalDocs: number,
        deletedDocs: number,
        dbSize: number,
        anomalie: number,
        treatment: number,
        treatmentHisto: number,
        lot: number,
        treatmentImage: number,
        treatmentImageDelete: number,
        traca: number,
        reception: number,
        lsClient: number,
        task: number,
        temperature: number,
        oilChange: number,
        oilControl: number,
        measure: number,
        taskHisto: number,
        temperatureHisto: number,
        oilChangeHisto: number,
        oilControlHisto: number,
        measureHisto: number,
    ) {
        this.client = client;
        this.majDate = majDate;

        this.totalDocs = totalDocs;
        this.deletedDocs = deletedDocs;
        this.dbSize = dbSize;
        this.anomalie = anomalie;
        this.treatment = treatment;
        this.treatmentHisto = treatmentHisto;
        this.lot = lot;
        this.treatmentImage = treatmentImage;
        this.treatmentImageDelete = treatmentImageDelete;
        this.traca = traca;
        this.reception = reception;
        this.lsClient = lsClient;
        this.task = task;
        this.temperature = temperature;
        this.oilChange = oilChange;
        this.oilControl = oilControl;
        this.measure = measure;
        this.taskHisto = taskHisto;
        this.temperatureHisto = temperatureHisto;
        this.oilChangeHisto = oilChangeHisto;
        this.oilControlHisto = oilControlHisto;
        this.measureHisto = measureHisto;
    }
}