@defer (when dataLoaded) {
<div>
<h3 style="text-align: center">{{  "Nouvel output pour " + "\"" + name + "\"" }}</h3>
</div>
<div>
  <form [formGroup]="editForm" (ngSubmit)="addOutput(editForm.value)" class="py-5">
    <div class="formgrid grid">
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="outputReceptionType" class="label-required">{{
          "Type de réception" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownOutputReceptionType"
          class="w-full"
          formControlName="param_outputReceptionType"
          [(ngModel)]="selectedOutputReceptionType"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_outputReceptionType'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="printType" class="label-required">{{
          "Type de sortie" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownPrintType"
          class="w-full"
          formControlName="param_printType"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_printType'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-6 xl:col-2">
        <label for="printName" class="label-required">{{ "Nom de l'étiquette" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="param_printName"
          [required]="true"
          class="inputName w-full mr-2"
        />
        @if(isErrorFormControl(editForm.controls['param_printName'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="required" class="label-required">{{
          "Requis ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_required"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_required'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-2">
        <label for="outputPath" class="label-required">{{
          "Chemin" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="path"
          class="w-full"
          formControlName="param_outputPath"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_outputPath'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-2 xl:col-2">
        <label for="order" class="label-required">{{
          "Ordre" | transloco
          }}</label>
        <input
          type="number"
          [min]= 1
          [max]= 100
          [step] = 1
          pInputText
          formControlName="param_order"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          class="w-full inputOrder mr-2"
        />
        @if(isErrorFormControl(editForm.controls['param_order'])) {
        <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
        }
      </div>
    </div>
    <div class="grid py-5">
      <div class="col"></div>
      <div class="col text-right">
        <button
          pButton
          type="submit"
          [label]="'Enregistrer' | transloco"
          icon="fa-solid fa-gear"
          class="p-button-success"
          [disabled]="!editForm.valid"
        ></button>
      </div>
    </div>
  </form>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
