import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';

import { ApiSensorsService } from '../../../../services/api/api-sensors.service';
import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco'; 
import { Sensor } from '../../../../models/sensor';
import { SensorAlert } from '../../../../models/sensor-alert';
import { Client } from '../../../../models/client';
import { EditComponent } from '../../../core/edit/edit.component';

@Component({
  selector: 'app-sensors-alert-edit',
  templateUrl: './sensors-alert-edit.component.html',
  styleUrl: './sensors-alert-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class SensorsAlertEditComponent extends EditComponent implements OnInit, OnDestroy {
  sensorAlertSubscription: Subscription = new Subscription();
  sensorSubscription: Subscription = new Subscription();
  sensors: Sensor[] = [];
  
  editForm: FormGroup;

  sensorAlertId: string = '';

  filteredSensors: Sensor[] = [];
  sensorsDisabled:boolean = true;
  
  licences : Client[] = [];

  saveError:boolean = false;
  
  constructor(
    private apiSensorsService: ApiSensorsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
      super(route);

      this.editForm = this.formBuilder.group({
        cli_id: ['', Validators.required],
        sensors: [''],
        delay_start: [10, Validators.required],
        delay_repeat: [30, Validators.required],
        nb_repeat: [10, Validators.required],
        recipients: this.formBuilder.array([]) 
      }); 
   }

  override ngOnInit() {
    super.ngOnInit();

    this.sensorAlertId = this.route.snapshot.paramMap.get('id') ?? '';

    this.licences = this.authService.getUserLicences();    

    this.sensorSubscription = this.apiSensorsService.getSensors().subscribe((data: Sensor[]) => {
        this.sensors = data;

        if(this.sensorAlertId != '') {
          // recuperer les données de la sonde   
          this.sensorAlertSubscription = this.apiSensorsService.getSensorAlert(this.sensorAlertId).subscribe((sensor: SensorAlert) => {

              this.editForm.controls['cli_id'].setValue(sensor.client.id);
              this.editForm.controls['delay_start'].setValue(sensor.delayStart);
              this.editForm.controls['delay_repeat'].setValue(sensor.delayRepeat);
              this.editForm.controls['nb_repeat'].setValue(sensor.nbRepeat);

              sensor.recipients.forEach((oneRecipient: any) => {
                this.addRecipient(oneRecipient.sap_email);
              });

              //on charge la liste des zones
              this.onLicenceChange();

              //on selectionne la zone
              this.editForm.controls['sensors'].setValue(sensor.sensorsList);    
          });    
        } else {
          this.addRecipient();
        }
    });    
  }
  ngOnDestroy() {
      this.sensorSubscription.unsubscribe();
      this.sensorAlertSubscription.unsubscribe();
  }
  
  addRecipient(emailValue:string = '') {
    const emailField = this.formBuilder.control(emailValue, [Validators.required, Validators.email]);
    (this.editForm.get('recipients') as FormArray).push(emailField);
  }
  removeEmail(index: number) {
    (this.editForm.get('recipients') as FormArray).removeAt(index);
  }
  get emailControls() {
    return (this.editForm.get('recipients') as FormArray).controls;
  }

  onLicenceChange() {
    // Filtrer les zones en fonction de la licence sélectionnée
    if (this.editForm.controls['cli_id'].value) {
      this.sensorsDisabled = false;
      this.filteredSensors = this.sensors.filter((sensor : Sensor) => sensor.client?.id === this.editForm.controls['cli_id'].value);
    } else {
      this.sensorsDisabled = true;
      this.filteredSensors = [];
    }
    this.editForm.controls['sensors'].setValue('');
  }

  save() {    
    const formData = {
      sal_id: this.sensorAlertId,
      cli_id: this.editForm.controls['cli_id'].value,
      delay_start: this.editForm.controls['delay_start'].value,
      delay_repeat: this.editForm.controls['delay_repeat'].value,
      nb_repeat: this.editForm.controls['nb_repeat'].value,
      sensors: this.editForm.controls['sensors'].value == '' ? [] : this.editForm.controls['sensors'].value,
      recipients: this.editForm.controls['recipients'].value
    };

    this.apiSensorsService.postSensorAlert(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        this.router.navigate(['settings/sensors-alert']);
      }
    });
  }
}
