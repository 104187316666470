import { Component } from '@angular/core';

import { LoadingModalService } from '../../../services/modal/loading-modal.service';


@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
})
export class LoadingModalComponent {
  display: boolean = false;

  constructor(public loadingModalService: LoadingModalService) {}

  show() {
    this.display = true;
  }

  hide() {
    this.display = false;
  }
}