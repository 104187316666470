import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    private data: any[] = [];
    subject = new Subject<any[]>();
    sessionStorage: Storage = window.sessionStorage;

    private menu = new BehaviorSubject<any>({});
    getmenu$ = this.menu.asObservable();
    currentmenu() {
        return this.menu.value;
    }
    setmenu(x:string) {
        this.menu.next(x);
    }

    constructor() {}

    hasKey(key: string): boolean {
        if (this.isLocalStorageSupported) {
            return this.sessionStorage.getItem(key) !== undefined;
        }
        return false;
    }

    get(key: string): any {
        if (this.isLocalStorageSupported) {
            const data = this.sessionStorage.getItem(key);
            return data ? JSON.parse(data) : null;
        }
        return null;
    }

    set(key: string, value: any): boolean {
        if (this.isLocalStorageSupported) {
            this.sessionStorage.setItem(key, JSON.stringify(value));
            return true;
        }
        return false;
    }

    remove(key: string): boolean {
        if (this.isLocalStorageSupported) {
            this.sessionStorage.removeItem(key);
            return true;
        }
        return false;
    }

    destroy() {
        if (this.isLocalStorageSupported) {
            this.sessionStorage.clear();
            return true;
        }
        return false;
    }

    get isLocalStorageSupported(): boolean {
        return !!this.sessionStorage;
    }
}
