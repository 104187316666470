import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { ApiDishesService } from '../../../../services/api/api-dishes.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { LoadingModalService } from '../../../../services/modal/loading-modal.service';

import { Dishe } from '../../../../models/dishe';
import { Client } from '../../../../models/client';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';

@Component({
  selector: 'app-dishes-list',
  templateUrl: './dishes-list.component.html',
  styleUrl: './dishes-list.component.css',
  standalone: true,
  imports: [ButtonModule, CommonModule, FormsModule, MultiSelectModule, TableModule, TranslocoModule],
})
export class DishesListComponent implements OnInit, OnDestroy {
  constructor(
    private apiDishesService: ApiDishesService,
    private authService: AuthService,
    private loadingModalService: LoadingModalService,
    private translocoService: TranslocoService
  ) {}
  dishesSubscription: Subscription = new Subscription();
  dishes: Dishe[] = [];
  licences : Client[] = [];
  dataLoaded: boolean = false;
  selectedDishes: number[] = [];

  @ViewChild('dt') dt: Table | null= null;

  
  ngOnInit() {
    this.dishesSubscription = this.apiDishesService
      .getDishes()
      .subscribe((data: Dishe[]) => {
        this.dishes = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }
  ngOnDestroy() {
    this.dishesSubscription.unsubscribe();
  }
  
  // exportDisheDisabled() {
  //   return this.selectedDishes.length == 0;
  // }

  labelExport() {
    return this.translocoService.translate((this.selectedDishes.length == 0 ? 'Exporter l\'ensemble des recettes' : 'Exporter les recettes sélectionnées'));
  }
  

  exportDishes() {
    let filtersDishes = {
      'selectedDishes': this.selectedDishes,
      'clients': [],
      'name': '',
      'internalCode':'',
      'identifiant': '',
      'qty': '',
      'unityName': ''
    };

    const filters = this.dt?.filters;

    if(this.selectedDishes.length == 0 && filters) {
      //si pas de recette sélectionnée on recupere les filtres éventuels
      filtersDishes['clients'] = (filters['client.id'] as { value: any }).value ?? [];
      filtersDishes['name'] =  (filters['name'] as { value: string }).value ?? '';
      filtersDishes['internalCode'] =  (filters['internalCode'] as { value: string }).value ?? '';
      filtersDishes['identifiant'] =  (filters['identifiant'] as { value: string }).value ?? '';
      filtersDishes['qty'] =  (filters['qty'] as { value: string }).value ?? '';
      filtersDishes['unityName'] =  (filters['unityName'] as { value: string }).value ?? '';
    }

    this.loadingModalService.show(
      this.translocoService.translate('Export des recettes'),
      this.translocoService.translate('Fichier en cours de génération, merci de patienter...')
    );

    this.apiDishesService.getDishesExport(filtersDishes).subscribe((data: any) => {
      const base64Data = data.data_file;

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'export_plats.xlsx';
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);

      this.loadingModalService.hide();
    });
  }
}
