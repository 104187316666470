import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import {Subscription, take} from 'rxjs';

import {ApiProcessListService} from "../../../../services/api/api-process-list.service";
import { AuthService } from '../../../../services/auth/auth.service';

import { Client } from '../../../../models/client';
import { ProcessList } from '../../../../models/process-list';

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import {TooltipModule} from "primeng/tooltip";
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from "primeng/confirmdialog";

@Component({
  selector: 'app-process-list-traceability',
  templateUrl: './process-list-traceability.component.html',
  styleUrl: './process-list-traceability.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
  ],
})
export class ProcessListTraceabilityComponent implements OnDestroy, OnInit {
  licences: Client[] = [];
  ProcessListSubscription: Subscription = new Subscription();
  processList: ProcessList[] = [];
  selectedProcess = [];
  dataLoaded: boolean = false;

  constructor(
    private apiProcessListService: ApiProcessListService,
    private authService: AuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    // chargement des process de chaque licences
    this.ProcessListSubscription = this.apiProcessListService
      .getProcessList()
      .pipe(take(1))
      .subscribe((data: ProcessList[]) => {
        this.processList = data;console.log('date', data)
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }
  deleteTheProcess(processData: ProcessList){
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE du process'
        ) +
        ' "' +
        processData.tpr_name_clean +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListService.deleteProcess( processData.tpr_id).subscribe((response) => {
          if (response) {
            this.processList = this.processList.filter(
              (val: ProcessList) => val.tpr_id !== processData.tpr_id
            );
          }
        });
      },
    });
  }
  updateTheProcess(processData: ProcessList){
    this.router.navigate(['settings/process/process-list-traceability-parameters', processData.tpr_id],
      { queryParams: { name: processData.tpr_name_clean } });
  }
  hideTheProcess(processData: ProcessList, processHidden: number){
    const actionTitle = (processHidden == 0 ? 'sera de nouveau visible': 'ne sera plus visible');

    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Le process'
        ) +
        ' "' +
        processData.tpr_name_clean +
        '" ' + actionTitle,
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessListService.hideProcess( processData.tpr_id, processHidden).subscribe((response) => {
          if (response) {
            window.location.reload();
          }
        });
      },
    });
  }

  outputSettings(processData: ProcessList) {
    this.router.navigate(['settings/process/process-list-traceability-outputs', processData.tpr_id],
      { queryParams: { name: processData.tpr_name_clean } });
  }

  ngOnDestroy(): void {
    if (this.ProcessListSubscription) {
      this.ProcessListSubscription.unsubscribe();
    }
  }
}
