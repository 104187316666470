import { Client } from "./client";
import { Workspace } from "./workspace";

export class Sensor {
    public id: number;
    public externalCode: string;
    public label: string;
    public minTemp: number | null;
    public maxTemp: number | null;

    public client: Client | null;
    public workspace: Workspace | null;

    public lastMeasure: number | null;
    
    public displayBeforeMorning: number | null;
    public displayAfterMorning: number | null;
    public displayBeforeLunch: number | null;
    public displayAfterLunch: number | null;
    public displayBeforeEvening: number | null;
    public displayAfterEvening: number | null;

    constructor(
        id: number,
        externalCode: string,
        label: string,
        minTemp: number | null = null,
        maxTemp: number | null = null,
        client: Client | null = null,
        workspace: Workspace | null = null,
        lastMeasure: number | null = null,

        displayBeforeMorning: number | null = null,
        displayAfterMorning: number | null = null,
        displayBeforeLunch: number | null = null,
        displayAfterLunch: number | null = null,
        displayBeforeEvening: number | null = null,
        displayAfterEvening: number | null = null,

        ) {
        this.id = id;
        this.externalCode = externalCode;
        this.label = label;
        this.minTemp = minTemp;
        this.maxTemp = maxTemp;

        this.client = client;

        this.workspace = workspace;

        this.lastMeasure = lastMeasure;

        this.displayBeforeMorning = displayBeforeMorning;
        this.displayAfterMorning = displayAfterMorning;
        this.displayBeforeLunch = displayBeforeLunch;
        this.displayAfterLunch = displayAfterLunch;
        this.displayBeforeEvening = displayBeforeEvening;
        this.displayAfterEvening = displayAfterEvening;
    }
}