@defer (when dataLoaded) {
  <div class="grid flex">
    <div class="col-12">
    @if(orderInfos) {
        <p-panel [header]="'Paiement validé' | transloco">    
            <p>{{ "Nous vous remercions pour votre commande." | transloco }}</p>
            <p>{{ "Nous avons bien reçu votre paiement par carte bancaire. Votre commande est en cours de traitement et sera expédiée dans les plus brefs délais." | transloco }}</p>
            <p>{{ "Vous recevrez un email de confirmation dès que votre commande sera expédiée, avec les informations de suivi." | transloco }}</p>
            <p>{{ "Merci de votre confiance et à très bientôt." | transloco }}</p>
        </p-panel>
    }
    @else {
      <p>
        {{ "Aucune commande en cours" | transloco }}
      </p>
    }
    </div>
  </div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
