export class ProcessListParameters {
  public cli_name: string;
  public tpr_name_clean: string;
  public tpa_id: number;
  public tpa_tpr_id: number;
  public tpa_type: string;
  public tpa_length: number;
  public tpa_validation: string;
  public tpa_default: string;
  public tpa_default_when: number;
  public tpa_readonly: number;
  public tpa_placeholder: string;
  public tpa_input_mode: string;
  public tpa_values_list: string;
  public tpa_required: number;
  public tpa_order: number;
  public tpa_from_id: number;
  public tpa_script: string;
  public tpa_is_dlc: number;
  public tpa_flag: string;
  public tpa_future_date: number;
  public tpa_display_in_table: number;
  public tpa_name_clean: string;

  constructor(
    cli_name: string,
    tpr_name_clean: string,
    tpa_id: number,
    tpa_tpr_id: number,
    tpa_type: string,
    tpa_length: number,
    tpa_validation: string,
    tpa_default: string,
    tpa_default_when: number,
    tpa_readonly: number,
    tpa_placeholder: string,
    tpa_input_mode: string,
    tpa_values_list: string,
    tpa_required: number,
    tpa_order: number,
    tpa_from_id: number,
    tpa_script: string,
    tpa_is_dlc: number,
    tpa_flag: string,
    tpa_future_date: number,
    tpa_display_in_table: number,
    tpa_name_clean: string,
  ){
    this.cli_name = cli_name;
    this.tpr_name_clean = tpr_name_clean;
    this.tpa_id = tpa_id;
    this.tpa_tpr_id = tpa_tpr_id;
    this.tpa_type = tpa_type;
    this.tpa_length = tpa_length;
    this.tpa_validation = tpa_validation;
    this.tpa_default = tpa_default;
    this.tpa_default_when = tpa_default_when;
    this.tpa_readonly = tpa_readonly;
    this.tpa_placeholder = tpa_placeholder;
    this.tpa_input_mode = tpa_input_mode;
    this.tpa_values_list = tpa_values_list;
    this.tpa_required = tpa_required;
    this.tpa_order = tpa_order;
    this.tpa_from_id = tpa_from_id;
    this.tpa_script = tpa_script;
    this.tpa_is_dlc = tpa_is_dlc;
    this.tpa_flag = tpa_flag;
    this.tpa_future_date = tpa_future_date;
    this.tpa_display_in_table = tpa_display_in_table;
    this.tpa_name_clean = tpa_name_clean;
  }
}
