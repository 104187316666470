import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ApiUsersService } from '../../../../services/api/api-users.service';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';

import { Client } from '../../../../models/client';
import { ClientGroup } from '../../../../models/client-group';

@Component({
  selector: 'app-users-licences',
  templateUrl: './users-licences.component.html',
  styleUrl: './users-licences.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})
export class UsersLicencesComponent implements OnInit, OnDestroy {
  allLicencesAdmin: Client[] = [];
  licencesIdAdmin: Number[] = [];
  availableLicences: Client[] = [];

  licencesUser: Client[] = [];
  licencesIdUser: Number[] = [];
  groups: ClientGroup[] = [];

  @ViewChild('tableAvailable') licencesAvailableRef!: Table;
  @ViewChild('tableLicences') licencesUserRef!: Table;


  constructor(
    private authService: AuthService,
    private apiUsersService: ApiUsersService,
    private route: ActivatedRoute
  ) {}

  dataLoaded: boolean = false;
  licencesSubscription: Subscription = new Subscription();
  userId: string = '';

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.userId != '') {
      this.allLicencesAdmin = this.authService.getUserLicences();
      this.groups = this.authService.getUserGroups();

      this.refreshLicences();
    }
  }

  refreshLicences() {
    this.allLicencesAdmin.forEach((oneLicence) => {
      this.licencesIdAdmin.push(oneLicence.id);
    });

    this.licencesSubscription = this.apiUsersService
      .getUserLicences(this.userId)
      .subscribe((lstLicences: Client[]) => {
        // on filtre les licences de l'utilisateur
        // en mettant uniquement celle de l'utilisateur connecté
        lstLicences.forEach((oneLicence) => {
          if (this.licencesIdAdmin.indexOf(oneLicence.id) >= 0) {
            this.licencesUser.push(oneLicence);
            this.licencesIdUser.push(oneLicence.id);
          }
        });

        // dans les licences disponibles (pour ajout)
        // on enleve les licences déja affectees
        this.allLicencesAdmin.forEach((oneLicence) => {
          if (this.licencesIdUser.indexOf(oneLicence.id) < 0) {
            this.availableLicences.push(oneLicence);
          }
        });

        this.dataLoaded = true;
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  moveLicence(dataLicence: Client, addLicence: boolean) {
    this.apiUsersService
      .moveLicence(parseInt(this.userId), dataLicence.id, addLicence)
      .subscribe((response: any) => {
        if (response) {
          
          if(addLicence) {          
            const index = this.availableLicences.findIndex(
              (licence) => licence.id === dataLicence.id
            );

            if (index !== -1) {
              const licenceToMove = this.availableLicences.splice(index, 1)[0];
              this.licencesUser.push(licenceToMove);

              this.licencesUserRef.totalRecords += 1;
              this.licencesAvailableRef.totalRecords -= 1;
            }
            

          } else {
            const index = this.licencesUser.findIndex(
              (licence) => licence.id === dataLicence.id
            );
            
            if (index !== -1) {
              const licenceToMove = this.licencesUser.splice(index, 1)[0];
              this.availableLicences.push(licenceToMove);
              
              this.licencesUserRef.totalRecords -= 1;
              this.licencesAvailableRef.totalRecords += 1;
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.licencesSubscription) {
      this.licencesSubscription.unsubscribe();
    }
  }
}
