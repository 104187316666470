import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../../services/api/api-eshop.service';
import { CartService } from '../../../../services/eshop/cart.service';
import { OrderService } from '../../../../services/eshop/order.service';
import { PaymentService } from '../../../../services/eshop/payment.service';
import { environment } from '../../../../../environments/environment';
import { UtilsService } from '../../../../services/utils/utils.service';

@Component({
  selector: 'app-eshop-payment',
  templateUrl: './eshop-payment.component.html',
  styleUrl: './eshop-payment.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    PanelModule,
    TranslocoModule,
  ]
})

export class EshopPaymentComponent implements OnInit {
  
  productSubscription: Subscription = new Subscription();
  cartSubscription: Subscription = new Subscription()
  cartProducts: any[] = [];
  dataLoaded: boolean = false;
  cartTotalPrice: number = 0;
  cartTotalTax: number = 0;
  orderInfos: any = [];
  paymentInfos: any;

  constructor(
    private apiEshopService: ApiEshopService,
    private cartService: CartService,
    public orderService: OrderService,
    private utilsService: UtilsService,
    public paymentService: PaymentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    
    this.orderInfos = this.orderService.getOrderInfos();
    this.paymentInfos = this.paymentService.getPaymentInfos();
    let totalQty = this.cartService.getTotalQuantity()

    this.dataLoaded = true;

    if(this.orderInfos.modePaiement == this.orderService.MODE_VIREMENT) {
      //on vide le panier
      this.cartService.clearCart();      

      //les infos de paiement
      this.paymentService.clear();

      //et une partie des infos de commande
      this.orderService.clear()
    }
    else if(this.orderInfos.modePaiement == this.orderService.MODE_CARTE_BANCAIRE) {
      //Création de l'url e-Transactions
      const urlRetour = environment.v2URL+'scripts/up2pay/callback.php';
      const pbxRetour = 'Ref:R'; //'Mt:M;Ref:R;Auto:A;Erreur:E';

      const pbxEffectue = environment.ecURL+'infos/eshop-payment-success';
      const pbxAnnule = environment.ecURL+'infos/eshop-order';
      const pbxRefuse = environment.ecURL+'infos/eshop-payment-error';

      const pbxDateTime = this.utilsService.getCurrentDateISO();
      
      let pbxShoppingCart = "<?xml version=\"1.0\" encoding=\"utf-8\"?><shoppingcart><total><totalQuantity>"+totalQty+"</totalQuantity></total></shoppingcart>";
      let pbxSouhaitAuthent = '02';
      
      if(this.paymentInfos.total > 3000) {
          pbxSouhaitAuthent = '01';	// Vérification du montant maximal pour l'exemption 3DS
      }

      const pbxPrenomFact = this.utilsService.formatStringPayment(this.orderInfos.prenom, 22);		// Variable prénom du porteur
      const pbxNomFact = this.utilsService.formatStringPayment(this.orderInfos.nom, 22);			// Variable nom du porteur
      const pbxAdresse1Fact = this.utilsService.formatStringPayment(this.orderInfos.rue, 50);		// Variable adresse ligne 1 du porteur
      const pbxAdresse2Fact = this.utilsService.formatStringPayment(this.orderInfos.batiment, 50);		// Variable adresse ligne 2 du porteur
      const pbxZipcodeFact = this.utilsService.formatStringPayment(this.orderInfos.codePostal, 16);	// Variable code postal du porteur
      const pbxCityFact = this.utilsService.formatStringPayment(this.orderInfos.ville, 50);			// Variable ville du porteur

      //pour l'instant on a juste france ou espagne
      const pbxCountryFact = this.utilsService.formatStringPayment(this.paymentService.getCountryCode(this.orderInfos.pays), 3);		// Variable pays du porteur
      const pbxCountryCodeMobile = this.utilsService.getPhoneIndicator(this.orderInfos.pays);											// Variable indicatif pays du numero de telephone mobile du porteur
      const pbxMobilePhone = this.orderInfos.telephone;

      let pbxBilling = "<?xml version=\"1.0\" encoding=\"utf-8\"?><Billing><Address><FirstName>"+pbxPrenomFact+"</FirstName>"+
                        "<LastName>"+pbxNomFact+"</LastName><Address1>"+pbxAdresse1Fact+"</Address1>"+
                        "<Address2>"+pbxAdresse2Fact+"</Address2><ZipCode>"+pbxZipcodeFact+"</ZipCode>"+
                        "<City>"+pbxCityFact+"</City><CountryCode>"+pbxCountryFact+"</CountryCode>"+
                        "<CountryCodeMobilePhone>"+pbxCountryCodeMobile+"</CountryCodeMobilePhone><MobilePhone>"+pbxMobilePhone+"</MobilePhone>"+
                        "</Address></Billing>";

                     
      let msg = "PBX_SITE="+environment.UP2PAY_SITE+
              "&PBX_RANG="+environment.UP2PAY_RANG+
              "&PBX_IDENTIFIANT="+environment.UP2PAY_IDENTIFIANT+
              "&PBX_TOTAL="+this.paymentInfos.total+
              "&PBX_DEVISE=978"+              
              "&PBX_CMD="+this.orderInfos.id+
              "&PBX_PORTEUR="+this.orderInfos.email+
              "&PBX_REPONDRE_A="+urlRetour+
              "&PBX_RETOUR="+pbxRetour+
              "&PBX_EFFECTUE="+pbxEffectue+
              "&PBX_ANNULE="+pbxAnnule+
              "&PBX_REFUSE="+pbxRefuse+
              "&PBX_HASH=SHA512"+              
              "&PBX_TIME="+pbxDateTime+
              "&PBX_SHOPPINGCART="+pbxShoppingCart+
              "&PBX_BILLING="+pbxBilling+
              "&PBX_SOUHAITAUTHENT="+pbxSouhaitAuthent;

      this.utilsService.generateHmacSha512(msg, environment.UP2PAY_CLE_HMAC).then(pbHmac => {
        const pbxHmac = pbHmac;
        
        pbxBilling = this.utilsService.replaceSimpleQuotes(pbxBilling);
        pbxShoppingCart = this.utilsService.replaceSimpleQuotes(pbxShoppingCart);


        const form = document.createElement("form");
        form.method = "POST";
        form.action = this.paymentInfos.urlEtrans;

        // Fonction pour ajouter un champ caché au formulaire
        const addHiddenField = (name: string, value: string) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        };

        // Ajout des champs cachés au formulaire
        addHiddenField("PBX_SITE", environment.UP2PAY_SITE.toString());
        addHiddenField("PBX_RANG", environment.UP2PAY_RANG.toString());
        addHiddenField("PBX_IDENTIFIANT", environment.UP2PAY_IDENTIFIANT.toString());
        addHiddenField("PBX_TOTAL", this.paymentInfos.total);
        addHiddenField("PBX_DEVISE", "978");
        addHiddenField("PBX_CMD", this.orderInfos.id);
        addHiddenField("PBX_PORTEUR", this.orderInfos.email);
        addHiddenField("PBX_REPONDRE_A", urlRetour);
        addHiddenField("PBX_RETOUR", pbxRetour);
        addHiddenField("PBX_EFFECTUE", pbxEffectue);
        addHiddenField("PBX_ANNULE", pbxAnnule);
        addHiddenField("PBX_REFUSE", pbxRefuse);
        addHiddenField("PBX_HASH", "SHA512");
        addHiddenField("PBX_TIME", pbxDateTime);
        addHiddenField("PBX_SHOPPINGCART", pbxShoppingCart);
        addHiddenField("PBX_BILLING", pbxBilling);
        addHiddenField("PBX_SOUHAITAUTHENT", pbxSouhaitAuthent);
        addHiddenField("PBX_HMAC", pbxHmac);

        // Ajout d'un bouton submit pour envoyer le formulaire
        const submitButton = document.createElement("input");
        submitButton.type = "submit";
        submitButton.value = "Envoyer";
        form.appendChild(submitButton);

        // Ajout du formulaire au document et envoi automatique
        document.body.appendChild(form);
        form.submit();
      });      
    }
  }
  
  redirectOrder() {
    this.router.navigate(['infos/eshop-order/']);
  }
  
}
