import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { ApiService } from '../../../../services/api/api.service';
import { ApiSensorsService } from '../../../../services/api/api-sensors.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { Sensor } from '../../../../models/sensor';
import { Client } from '../../../../models/client';
import { Workspace } from '../../../../models/workspace';

@Component({
  selector: 'app-sensors-settings',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
  templateUrl: './sensors-list.component.html',
  styleUrl: './sensors-list.component.css',
  providers: [MessageService, ConfirmationService],
})
export class SensorsListComponent implements OnInit, OnDestroy {
  sensorSubscription: Subscription = new Subscription();
  workspaces: Workspace[] = [];
  workspaceSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;

  licences : Client[] = [];
  sensors: Sensor[] = [];

  constructor(
    private apiService: ApiService,
    private apiSensorService: ApiSensorsService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.sensorSubscription = this.apiSensorService
      .getSensors()
      .subscribe((data: Sensor[]) => {
        this.sensors = data;
        this.dataLoaded = true;
      });

    this.workspaceSubscription = this.apiService
      .getWorkspaces()
      .subscribe((data: Workspace[]) => {
        this.workspaces = data;
      });
    this.licences = this.authService.getUserLicences();
  }
  ngOnDestroy() {
    this.sensorSubscription.unsubscribe();
    this.workspaceSubscription.unsubscribe();
  }
  acheterSonde() {
    const url = 'https://eshop.zest-haccp.fr/';
    window.open(url, '_blank');
  }
  addSensor() {
    this.router.navigate(['settings/sensors-add']);
  }
  editSensor(sensorData: Sensor) {
    this.router.navigate(['settings/sensors-edit', sensorData.id]);
  }
  graphSensor(sensorData: Sensor) {
    this.router.navigate(['settings/sensors-histo', sensorData.id]);
  }
  deleteSensor(sensorData: Sensor) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de la sonde'
        ) +
        ' "' +
        sensorData.label +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiSensorService.deleteSensor(sensorData.id).subscribe((response) => {
          if (response) {
            this.sensors = this.sensors.filter(
              (val: Sensor) => val.id !== sensorData.id
            );
          }
        });
      },
    });
  }
  valueInRanges(sensorData: Sensor) {
    if (
      sensorData.lastMeasure !== null &&
      sensorData.minTemp !== null &&
      sensorData.maxTemp !== null
    ) {
      if (
        sensorData.lastMeasure < sensorData.minTemp ||
        sensorData.lastMeasure > sensorData.maxTemp
      ) {
        return 'temperature-invalid';
      }
      return 'temperature-ok';
    }
    return '';
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
}
