import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { Alerts } from '../../models/alerts-users';
import { AlertsType } from "../../models/alerts-users-type-list";
import { AlertsFrequencies} from "../../models/alerts-users-frequencies-list";


@Injectable({
  providedIn: 'root',
})
export class ApiAlertsListService {
  constructor(private http: HttpClient) {}

  getAlertsList(): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_alerts_users.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataAlerstList) => {
            return new Alerts(
              parseInt(dataAlerstList.cal_id),
              dataAlerstList.ale_name,
              parseInt(dataAlerstList.cal_afr_id),
              parseInt(dataAlerstList.cal_repeat_delay),
              dataAlerstList.cal_to,
              dataAlerstList.cal_message,
              dataAlerstList.cal_message_perso,
              dataAlerstList.cal_importance,
              new Client(
                parseInt(dataAlerstList.cli_id),
                dataAlerstList.cli_name),
              new AlertsType(
                parseInt(dataAlerstList.ale_id),
                dataAlerstList.ale_name),
              new AlertsFrequencies(
                parseInt(dataAlerstList.afr_id),
                dataAlerstList.afr_name)
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  deleteAlerts(cal_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/delete_alerts_users.php?cal_id=' +
      cal_id
    );
  }

  addAlerts(cal_ale_id: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
      'espace_client/post_alerts_users.php?cal_ale_id=' +
      cal_ale_id
    );
  }

  getAlertsType(): Observable<AlertsType[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_alerts_users_type.php?')
      .pipe(
        map((response: any[]) => {
          return response.map((dataAlertsTypeList) => {
            return new AlertsType(
              parseInt(dataAlertsTypeList.ale_id, 10),
              dataAlertsTypeList.ale_name,
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getAlertsFrequencies(): Observable<AlertsFrequencies[]> {
    return this.http
      .get<any[]>(environment.apiURL + 'espace_client/get_alerts_users_frequencies.php?')
      .pipe(
        map((response: any[]) => {
          return response.map((dataAlertsFrequenciesList) => {
            return new AlertsFrequencies(
              parseInt(dataAlertsFrequenciesList.afr_id, 10),
             dataAlertsFrequenciesList.afr_name,
            )
          })
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  updateAlert(rowData: any): Observable<any> {
    const body = new URLSearchParams();
    for (const key in rowData) {
      if (rowData.hasOwnProperty(key)) {
        body.set(key, rowData[key]);
      }
    }
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_update_alerts_users.php',
      body.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }
}
