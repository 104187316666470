import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ApiSensorsService } from '../../../../services/api/api-sensors.service';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { SensorAlert } from '../../../../models/sensor-alert';
import { Client } from '../../../../models/client';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-sensors-alert-list',
  templateUrl: './sensors-alert-list.component.html',
  styleUrl: './sensors-alert-list.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class SensorsAlertListComponent implements OnInit, OnDestroy {
  sensorAlertsSubscription: Subscription = new Subscription();

  sensorsAlerts: SensorAlert[] = [];
  licences : Client[] = [];
  dataLoaded: boolean = false;

  constructor(
    private apiSensorService: ApiSensorsService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {

  }

  ngOnInit(): void {
    this.sensorAlertsSubscription = this.apiSensorService
      .getAlerts()
      .subscribe((data: SensorAlert[]) => {
        this.sensorsAlerts = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }

  ngOnDestroy(): void {    
    this.sensorAlertsSubscription.unsubscribe();
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
  addAlert() {
    this.router.navigate(['settings/sensors-alert-add']);
  }
  editAlert(sensorData: SensorAlert) {
    this.router.navigate(['settings/sensors-alert-edit', sensorData.id]);
  }
  deleteAlert(sensorData: SensorAlert) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression de l\'alerte ? '
        ),
        header: 'Suppression',
        acceptLabel: this.translocoService.translate('Oui'),
        rejectLabel: this.translocoService.translate('Non'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.apiSensorService.deleteSensorAlert(sensorData.id).subscribe((response) => {
            if (response) {
              this.sensorsAlerts = this.sensorsAlerts.filter(
                (val: SensorAlert) => val.id !== sensorData.id
              );
            }
          });
        },
    });
  }
}
