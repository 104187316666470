import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class TreeService {
    // supprime un noeud dans une arborescence (avec ses enfants)
    removeNodeAndChildren(nodes: TreeNode[], nodeId: string): TreeNode[] {
        return nodes.filter(node => {
            if (node.data.id === nodeId) {
                return false; 
            }
            if (node.children) {
                node.children = this.removeNodeAndChildren(node.children, nodeId);
            }
            return true;
        });
    }

    // recherche un noeud par son id
    findNodeById(nodes: TreeNode[], nodeId: string): TreeNode | null {
        for (let node of nodes) {
            if (node.data.id == nodeId) {
                return node;
            }
            if (node.children) {
                const foundNode = this.findNodeById(node.children, nodeId);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    }

    // expand ou reduit tout une arbo
    updateNodeExpansion(nodes: any[], expanded: boolean): any[] {
        return nodes.map(node => ({
          ...node,
          expanded,
          children: node.children ? this.updateNodeExpansion(node.children, expanded) : []
        }));
      }
}