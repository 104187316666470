import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { TableModule } from 'primeng/table';
import { TranslocoModule } from '@ngneat/transloco'; 
import { Client } from '../../models/client';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TableModule,
    TranslocoModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  licences: Client[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.licences = this.authService.getUserLicences();
  }
}
