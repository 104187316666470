import { Component, AfterViewInit, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule  } from '@angular/forms';

import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';

import { ApiSensorsService } from '../../../../services/api/api-sensors.service';

import { Sensor } from '../../../../models/sensor';
import { SensorMeasure } from '../../../../models/sensor-measure';

@Component({
  selector: 'app-sensors-histo',
  standalone: true,
  templateUrl: './sensors-histo.component.html',
  styleUrl: './sensors-histo.component.css',
  imports: [
    CalendarModule,
    ChartModule,
    CommonModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
})
export class SensorsHistoComponent implements AfterViewInit, OnInit, OnDestroy {
  sensorId: string = '';
  sensorSubscription: Subscription = new Subscription();
  titlePanel: string = '';
  sensor: Sensor | null = null;
  dataLoaded: boolean = false;

  @ViewChild('chartTemperatures') chartRef!: any;

  documentStyle = getComputedStyle(document.documentElement);

  dataChart: any = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        borderColor: '#ea5133',
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 7,
        backgroundColor: '#ea5133',
      },
    ],
  };

  optionChart: any = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      y: {
        min: -12,
        max: 12,
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems: any) => {
            if (tooltipItems[0].datasetIndex == 0) {
              return tooltipItems[0].label;
            } else {
              return '';
            }
          },
        },
      },
    },
  };
  
  searchForm: FormGroup;
  titlePage: string = '';

  constructor(
    private apiSensorService: ApiSensorsService,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private formBuilder: FormBuilder,
  ) {

    let tmpDateStart = new Date();
    tmpDateStart.setDate(tmpDateStart.getDate() - 1);

    this.searchForm = this.formBuilder.group({
      periodStart: [tmpDateStart, Validators.required],
      periodEnd: [new Date(), Validators.required]
    }); 
  }

  ngOnInit() {
    this.sensorId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
  }

  ngAfterViewInit(): void {
    if (this.sensorId != '') {
      this.sensorSubscription = this.apiSensorService
        .getSensor(this.sensorId)
        .subscribe((oneSensor: Sensor) => {
          this.sensor = oneSensor;
          this.titlePanel = `${this.titlePage} : ${this.sensor.client?.name} - ${this.sensor.workspace?.name} - ${this.sensor.label}`;

          //on initialise les bornes si on en a
          if (this.sensor.minTemp !== null && this.sensor.maxTemp !== null) {
            this.dataChart.datasets.push(
              {
                label: this.translocoService.translate('Température minimale'),
                data: [],
                fill: true,
                pointStyle: false,
                borderDash: [4, 4],
                tooltip: function () {
                  return false;
                },
              },
              {
                label: this.translocoService.translate('Température maximale'),
                data: [],
                fill: true,
                pointStyle: false,
                borderDash: [4, 4],
              }
            );
          }
          this.dataLoaded = true;

          setTimeout(() => {
            this.loadTemperatures();            
          }, 300);
        });
    }
  }

  ngOnDestroy(): void {
    if(this.sensorSubscription) {
      this.sensorSubscription.unsubscribe();
    }
  }

  clearGraphData() {
    this.dataChart.labels = [];

    this.dataChart.datasets.forEach((dataset: any) => {
      dataset.data = [];
    });
    this.chartRef.refresh();
  }

  loadTemperatures() {
    if(this.searchForm.controls['periodStart'].value > this.searchForm.controls['periodEnd'].value) {
      let tmpDate:Date = this.searchForm.controls['periodStart'].value;      
      tmpDate.setDate(tmpDate.getDate() + 1);
      this.searchForm.controls['periodEnd'].setValue(tmpDate);
    }

    this.clearGraphData();

    this.sensorSubscription = this.apiSensorService
      .getMeasuresSensors(this.sensorId, this.searchForm.controls['periodStart'].value, this.searchForm.controls['periodEnd'].value)
      .subscribe((measureList: SensorMeasure[]) => {
        let i = 0;
        measureList.map((measureData: SensorMeasure) => {
          // ajout date et valeur
          this.dataChart.labels.push(
            measureData.date.format('DD/MM/YYYY HH:mm')
          );
          this.dataChart.datasets[0].data.push(measureData.value);

          // et les bornes si présentes
          if (this.sensor?.minTemp !== null && this.sensor?.maxTemp !== null) {
            this.dataChart.datasets[1].data.push(this.sensor?.minTemp);
            this.dataChart.datasets[2].data.push(this.sensor?.maxTemp);

          }

          i++;
        });
        
        this.optionChart.scales.y.min = (this.sensor?.minTemp ?? 0) - 8;
        this.optionChart.scales.y.max = (this.sensor?.maxTemp ?? 0) + 8;

        this.chartRef.refresh();

      });
  }
}
