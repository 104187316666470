import { Client } from "./client";
import { AlertsType } from "./alerts-users-type-list";
import { AlertsFrequencies } from "./alerts-users-frequencies-list";

export class Alerts {
  public cal_id: number;
  public ale_name: string;
  public cal_afr_id: number;
  public cal_repeat_delay: number;
  public cal_to: string;
  public cal_message: string;
  public cal_message_perso: string;
  public cal_importance: string;
  public client: Client;
  public alertsType: AlertsType;
  public alertsFrequencies: AlertsFrequencies;

  constructor(
    cal_id: number,
    ale_name: string,
    cal_afr_id: number,
    cal_repeat_delay: number,
    cal_to: string,
    cal_message: string,
    cal_message_perso: string,
    cal_importance: string,
    client: Client,
    alertsType: AlertsType,
    alertsFrequencies: AlertsFrequencies,
  ) {
    this.cal_id = cal_id;
    this.ale_name = ale_name;
    this.cal_afr_id = cal_afr_id;
    this.cal_repeat_delay = cal_repeat_delay;
    this.cal_to = cal_to;
    this.cal_message = cal_message;
    this.cal_importance = cal_importance;
    this.cal_message_perso = cal_message_perso;
    this.client = client;
    this.alertsType = alertsType;
    this.alertsFrequencies = alertsFrequencies;
  }
}
