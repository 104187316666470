import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute } from '@angular/router';
import {Router} from "@angular/router";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";

import {ReceptionType} from "../../../../models/reception-type-list";
import {ApiReceptionOutputListService} from "../../../../services/api/api-reception-output-list.service";
import {ReceptionOutputType} from "../../../../models/reception-output-type";

@Component({
  selector: 'app-process-list-output-reception-add',
  templateUrl: './process-list-output-reception-add.component.html',
  styleUrl: './process-list-output-reception-add.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
  ]
})
export class ProcessListOutputReceptionAddComponent extends EditComponent implements OnInit, OnDestroy {
  ReceptionOutputTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPrintTypeSubscription: Subscription = new Subscription();
  ReceptionOutputPathSubscription: Subscription = new Subscription();

  name: string = '';
  licences : Client[] = [];
  outputReceptionId: string = '';

  editForm: FormGroup;
  dropdownOutputReceptionType: { label: string, value: number }[] = [];
  dropdownPrintType: { label: string, value: number }[] = [];
  ouiNon: { label: string, value: number }[];
  selectedOutputReceptionType: any;
  path: any[];

  constructor(
    private apiReceptionOutputListService: ApiReceptionOutputListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_outputReceptionType: ['', Validators.required],
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownOutputReceptionType = [];
    this.dropdownPrintType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputReceptionType = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.outputReceptionId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    this.licences = this.authService.getUserLicences();

    this.ReceptionOutputTypeSubscription = this.apiReceptionOutputListService
      .getReceptionType(parseInt(this.outputReceptionId))
      .subscribe((data: ReceptionType[]) => {
        this.dropdownOutputReceptionType = data.map(receptionType => ({
          label: receptionType.rty_name,
          value: receptionType.cpr_id
        }));
      })

    this.ReceptionOutputPrintTypeSubscription = this.apiReceptionOutputListService
      .getOutputType()
      .subscribe((data: ReceptionOutputType[]) => {
        this.dropdownPrintType = data.map(outputType => ({
          label: outputType.rot_name,
          value: outputType.rot_id
        }));
      })

    this.ReceptionOutputPathSubscription = this.apiReceptionOutputListService
      .getOutputReceptionPath()
      .subscribe((data => {
        this.path = data;
      })
      )

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.dataLoaded = true;
  }



  addOutput(formData: any) {
    const rowData: any = {
      rou_id: -1,
      rou_cpr_id: this.selectedOutputReceptionType,
      rou_name: formData.param_printName,
      cli_id: this.outputReceptionId,
      rou_cli_id: this.outputReceptionId,
      rot_id: formData.param_printType,
      rou_compulsory: formData.param_required,
      rou_exec_path: formData.param_outputPath,
      rou_order: formData.param_order
    };
    console.log('Données du formulaire:', rowData);

    this.apiReceptionOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        this.router.navigate(['settings/process/process-reception']);
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });
  }

  ngOnDestroy() {
    this.ReceptionOutputTypeSubscription.unsubscribe();
    this.ReceptionOutputPrintTypeSubscription.unsubscribe();
    this.ReceptionOutputPathSubscription.unsubscribe();
  }
}
