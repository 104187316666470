@defer (when dataLoaded) {
  <div>
    <h3 style="text-align: center">{{  "Output(s) du process " + "\"" + name + "\"" }}</h3>
  </div>
  <div class="grid">
    <div class="col text-right">
      <button
      pButton
      [label]="'Ajouter un output' | transloco"
      icon="pi pi-plus"
      class="p-button-success"
      (click)="newOutput()"
      ></button>
    </div>
  </div>
  <p-table
    [value]="processOutputList"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processOutputList-session"
    [(selection)]="selectedOutput"
    selectionMode="single"
    (onRowSelect)="onRowSelect($event)"
    dataKey="tou_id"
    editMode="row"
    [paginator]="true"
    [rows]="20"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
  >
  <ng-template pTemplate="header">
  <tr>
    <th pSortableColumn="rou_rot_id" class="text-center">{{ 'Type ' | transloco }}<p-sortIcon field="rou_rot_id"></p-sortIcon></th>
    <th class="text-center">{{ 'Nom' | transloco }}</th>
    <th class="text-center">{{ 'Obligatoire' | transloco }}</th>
    <th class="text-center">{{ 'Chemin' | transloco }}</th>
    <th class="text-center">{{ 'Ordre ' | transloco }}</th>
    <th></th>
  </tr>
  </ng-template>

  <ng-template pTemplate="body" let-output let-editing="editing" let-ri="rowIndex" style="{'align-item': 'center'}">
  <tr [pSelectableRow]="output">
    <td [pEditableColumn]="output.tou_tot_id" pEditableColumnField="tou_tot_id">
      <p-cellEditor>
        <ng-template pTemplate="input">
        <p-dropdown
        [options]="dropdownPrintType"
        (onBlur)="onCellEdit(output)"
        appendTo="body"
        [(ngModel)]="output.tou_tot_id"
        [style]="{'width':'100%'}">
        </p-dropdown>
        </ng-template>
        <ng-template pTemplate="output">
        {{ output.tot_name }}
        </ng-template>
      </p-cellEditor>
    </td>
    <td [pEditableColumn]="output.tou_name" pEditableColumnField="tou_name">
      <p-cellEditor>
        <ng-template pTemplate="input">
        <input
        pInputText
        type="text"
        (onBlur)="onCellEdit(output)"
        [(ngModel)]="output.tou_name" />
        </ng-template>
        <ng-template pTemplate="output">
        {{ output.tou_name }}
        </ng-template>
      </p-cellEditor>
    </td>
    <td [pEditableColumn]="output.tou_compulsory" pEditableColumnField="tou_compulsory">
      <p-cellEditor>
        <ng-template pTemplate="input">
        <p-dropdown
        [options]="ouiNon"
        (onBlur)="onCellEdit(output)"
        appendTo="body"
        [(ngModel)]="output.tou_compulsory"
        [style]="{'width':'100%'}">
        </p-dropdown>
        </ng-template>
        <ng-template pTemplate="output">
        {{ output.tou_compulsory ? 'OUI' : 'NON' }}
        </ng-template>
      </p-cellEditor>
    </td>
    <td [pEditableColumn]="output.tou_exec_path" pEditableColumnField="tou_exec_path">
      <p-dropdown
      [style]="{ width: '100%' }"
      [options]="path"
      class="w-full"
      [filter]="true"
      optionLabel="label"
      optionValue="value"
      [required]="true"
      (onBlur)="onCellEdit(output)"
      [(ngModel)]="output.tou_exec_path"
      ></p-dropdown>
    </td>
    <td [pEditableColumn]="output.tou_order" pEditableColumnField="tou_order">
      <p-cellEditor>
        <ng-template pTemplate="input">
        <input
        pInputText
        type="number"
        (onBlur)="onCellEdit(output)"
        [(ngModel)]="output.tou_order" />
        </ng-template>
        <ng-template pTemplate="output">
        {{ output.tou_order }}
        </ng-template>
      </p-cellEditor>
    </td>
      <td class="flex align-items-center justify-content-center" style="height:50px; display: flex;">
        <button
        pButton
        icon="pi pi-ban"
        class="flex p-button-rounded p-button-unactivate mr-2"
        (click)="deleteOutput(output)"
        [pTooltip]="'Supprimer l\'Output' | transloco"
        tooltipPosition="left"
        ></button>
        <button
        pButton
        icon="pi pi-save"
        class="flex p-button-rounded p-button-success mr-2"
        (click)="updateOutput(output)"
        [pTooltip]="'Modifier l\'Output' | transloco"
        tooltipPosition="left"
        ></button>
      </td>
  </tr>
    </ng-template>
  </p-table>
  <div *ngIf="processisSelected" class="py-5">
    <p-panel [header]="'Configuration du l\'output' | transloco">
      <form [formGroup]="editForm" (ngSubmit)="addOutput(editForm.value)" class="py-5">
      <div class="formgrid grid">
        <div class="field col-2 md:col-3 xl:col-2">
          <label for="printType" class="label-required">{{
          "Type de sortie" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="dropdownPrintType"
          class="w-full"
          formControlName="param_printType"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_printType'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-2 md:col-6 xl:col-2">
          <label for="printName" class="label-required">{{ "Nom de l'étiquette" | transloco }}</label>
          <br />
          <input
          type="text"
          pInputText
          formControlName="param_printName"
          [required]="true"
          class="inputName w-full mr-2"
          />
          @if(isErrorFormControl(editForm.controls['param_printName'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-2 md:col-3 xl:col-2">
          <label for="required" class="label-required">{{
          "Requis ?" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_required"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_required'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-2 md:col-3 xl:col-2">
          <label for="outputPath" class="label-required">{{
          "Chemin" | transloco
          }}</label>
          <p-dropdown
          [style]="{ width: '100%' }"
          [options]="path"
          class="w-full"
          formControlName="param_outputPath"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
          [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_outputPath'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        <div class="field col-2 md:col-2 xl:col-2">
          <label for="order" class="label-required">{{
          "Ordre" | transloco
          }}</label>
          <input
          type="number"
          [min]= 1
          [max]= 100
          [step] = 1
          pInputText
          formControlName="param_order"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          class="inputOrder w-full mr-2"
          />
          @if(isErrorFormControl(editForm.controls['param_order'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
        </div>
        </div>
      </form>
  </p-panel>
      <div class="grid py-1">
      <div class="col"></div>
        <div class="col text-right">
          <button
            pButton
            [label]="'Annuler' | transloco"
            icon="pi pi-minus"
            class="p-button-unactivate"
            style = "margin-left: 15px"
            (click)="cancel()"
          ></button>
          <button
            pButton
            type="submit"
            [label]="'Enregistrer' | transloco"
            icon="pi pi-save"
            class="p-button-success"
            style="margin-left: 20px;"
            [disabled]="!editForm.valid"
          ></button>
        </div>
      </div>
  </div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
