@defer (when dataLoaded) {
<div class="grid">
  <div class="col text-right">
  <button
  pButton
  [label]="'Ajouter une alerte' | transloco"
  icon="pi pi-plus"
  class="p-button-success"
  (click)="addAlert(newAlert)"
  *ngIf="addButtonVisible"
  ></button>
  </div>
</div>
<p-table
  #myTable
  [value]="alertList"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="alertList-session"
  [(selection)]="selectedAlerts"
  selectionMode="single"
  dataKey="cal_id"
  rowGroupMode="rowspan"
  groupRowsBy="client.id"
  editMode="row"
  [paginator]="true"
  [rows]="20"
  [showCurrentPageReport]="true"
  (onFilter)="onFilterApplied($event)"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
<ng-template pTemplate="header">
  <tr>
    <th pSortableColumn="client.id">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>
    <th rowspan="2">{{ 'Alerte' | transloco }}</th>
    <th rowspan="2">{{ 'Niveau d\'alerte' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Fréquence' | transloco }}</th>
    <th class="text-center" rowspan="2">{{ 'Délai' | transloco }}</th>
    <!--<th class="text-center" rowspan="2">{{ 'Destinataire' | transloco }}</th>-->
    <th class="text-center" rowspan="2">{{ 'Message' | transloco }}</th>
    @if(canWrite()) {
    <th rowspan="2"></th>
    }
  </tr>
  <tr>
  <th>
  <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <p-multiSelect
      [ngModel]="value"
      [options]="licences"
      [placeholder]="'Toutes' | transloco"
      (onChange)="onFilterChange($event, filter)"
      optionLabel="name"
      optionValue="id"
      [virtualScroll]="true"
      [virtualScrollItemSize]="50"
      class="liste-recherche-table"
      >
      </p-multiSelect>
    </ng-template>
    @if(canWrite()) {
    <th></th>
    }
</p-columnFilter>
  </th>
  </tr>
</ng-template>
  <ng-template pTemplate="body"
    let-alerts
    let-editing="editing"
    let-ri="rowIndex"
    style="{'align-item': 'center'}"
    let-rowgroup="rowgroup"
    let-rowspan="rowspan">
    <tr [pSelectableRow]="alerts"
        [ngClass]="{'selected-row': selectedRow === alerts}"
        [pSelectableRowDisabled]="!canWrite()"
        (click)="canWrite() ? onRowSelect(alerts) : null"
    >
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="text-left">
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <span>{{ alerts.client?.id + ' - ' + alerts.client?.name }}</span>
          <button
          *ngIf="canWrite()"
          pButton
          icon="pi pi-plus"
          class="p-button-success p-button-rounded py-2"
          [pTooltip]="'Ajouter une alerte' | transloco"
          (click)="addAlert(alerts)"
          ></button>
      </div>
      <td [pEditableColumn]="alerts.alertsType.ale_id" pEditableColumnField="alertsType.ale_id">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
            [options]="dropdownAlertsType"
            (onBlur)="onCellEdit(alerts)"
            appendTo="body"
            [(ngModel)]="alerts.alertsType.ale_id"
            [style]="{'width':'100%'}">
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span>{{ (alerts.alertsType.ale_name).substring(2) }}</span>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
@if(canWrite()){
<td>
  <div style="display: flex; align-items: center; justify-content: space-between;">
    <p-triStateCheckbox
      [(ngModel)]="alerts.cal_importance"
      inputId="tricheckbox-{{alerts.cal_importance}}"
      (onChange)="onImportanceChange(alerts)"
      [ngClass]="{
          'info-checkbox': alerts.cal_importance === 'Information',
          'important-checkbox': alerts.cal_importance === 'Important',
          'tres-important-checkbox': alerts.cal_importance === 'Trés important'
          }"
      [ngStyle]="{backgroundColor:'var(--highlight-bg)', color: 'var(--highlight-text-color)'}"
    ></p-triStateCheckbox>
    <label for="tricheckbox-{{alerts.cal_importance}}" style="margin-left: 5px;">
      {{ alerts.cal_importance }}
    </label>
  </div>
</td>
}
      <td  [pEditableColumn]="alerts.alertsFrequencies.afr_id" pEditableColumnField="alertsFrequencies.afr_id">
        <p-cellEditor>
          <ng-template pTemplate="input">
          <p-dropdown
          [options]="dropdownAlertsFrequencies"
          (onBlur)="onCellEdit(alerts)"
          appendTo="body"
          [(ngModel)]="alerts.alertsFrequencies.afr_id"
          [style]="{'width':'100%'}">
          </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
          {{ alerts.alertsFrequencies.afr_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="alerts.cal_repeat_delay" pEditableColumnField="cal_repeat_delay">
        <p-cellEditor>
          <ng-template pTemplate="input">
          <input
          pInputText
          type="number"
          min="1"
          max="24"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          (blur)="onCellEdit(alerts)"
          [(ngModel)]="alerts.cal_repeat_delay"/>
          </ng-template>
          <ng-template pTemplate="output">
          Toutes les {{ alerts.cal_repeat_delay }} heures
          </ng-template>
        </p-cellEditor>
      </td>
      <td [pEditableColumn]="alerts.cal_message_perso" pEditableColumnField="cal_message_perso">
        <p-cellEditor>
          <ng-template pTemplate="input">
          <textarea
            pInputTextarea
            style="width: 100%; height: 100px;"
            wrap="soft"
            [(ngModel)]="alerts.cal_message_perso">
          </textarea>
          </ng-template>
          <ng-template pTemplate="output">
            {{ alerts.cal_message_perso }}
          </ng-template>
        </p-cellEditor>
      </td>
@if(canWrite()){
      <td class="flex align-items-center justify-content-center" style="height:80px; display: flex;"
        [ngClass]="{'highlighted-column': selectedRow === alerts}">
        <button
          pButton
          icon="pi pi-ban"
          class="flex p-button-rounded p-button-unactivate mr-2"
          (click)="deleteAlerte(alerts)"
          [pTooltip]="'Supprimer l\'alerte' | transloco"
          tooltipPosition="left"
        ></button>
        <button
          pButton
          icon="pi pi-save"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="updateAlerte(alerts)"
          [pTooltip]="'Modifier l\'alerte' | transloco"
          tooltipPosition="left"
        ></button>
      </td>
      }
    </tr>
  </ng-template>
</p-table>




}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
