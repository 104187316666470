import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingModalService {
  private modalVisible: boolean = false;
  private modalTitle:string = '';
  private modalText:string = '';

  constructor() { }

  show(modalTitle:string, modalText:string = ''): void {
    this.modalVisible = true;
    this.modalTitle = modalTitle;
    this.modalText = modalText;
  }

  hide(): void {
    this.modalVisible = false;
  }

  isVisible(): boolean {
    return this.modalVisible;
  }

  getTitle(): string {
    return this.modalTitle;
  }
  getText(): string {
    return this.modalText;
  }
}