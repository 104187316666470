@defer (when dataLoaded) {
  <div class="grid flex">
    <div class="col-12">
    @if(orderInfos) {
        @if(orderInfos.modePaiement == orderService.MODE_VIREMENT) {
          
          <p-panel [header]="'Paiement par virement bancaire' | transloco">  
            <p>
              
              {{ "Effectuez le paiement directement depuis votre compte bancaire." | transloco }}<br>
              {{ "Votre commande ne sera pas expédiée tant que les fonds ne seront pas reçus." | transloco }}<br>

            <table>
              <tr>
                <td class="titre">Nom du compte</td>
                <td>ZEST HACCP</td>
              </tr>
              <tr>
                <td class="titre">Numéro du compte</td>
                <td>00020623501</td>
              </tr>
              <tr>
                <td class="titre">Nom de la banque</td>
                <td>CIC BORDEAUX GAMBETTA</td>
              </tr>
              <tr>
                <td class="titre">Code guichet</td>
                <td>19011</td>
              </tr>
              <tr>
                <td class="titre">IBAN</td>
                <td>FR76 1005 7190 1100 0206 2350 135</td>
              </tr>
              <tr>
                <td class="titre">BIC / Swift</td>
                <td>CMCIFRPP</td>
              </tr>
            </table>
        </p-panel>
        } 
        @else {

          <p-panel [header]="'Paiement par carte bancaire' | transloco">            
            @if(paymentInfos.error) {
              <p>
                {{ "Une erreur est survenue lors du paiement sécurisé." | transloco }}<br>
                {{ "Veuillez réessayer ou utiliser un mode de paiement différent." | transloco }}                
              </p>
              <button
                pButton
                [label]="'Retour' | transloco"
                icon="fa-solid fa-arrow-left"
                class="flex-1"
                (click)="redirectOrder()"
            ></button>
            }
            @else {
              {{ "Chargement en cours..." | transloco }}
            }
          </p-panel>
        }
    }
    @else {
      <p>
        {{ "Aucune commande en cours" | transloco }}
      </p>
    }
    </div>
  </div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
