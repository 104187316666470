import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Indicator } from '../../models/indicator';
import { IndicatorFieldType } from '../../models/indicator-field-type';
import { IndicatorField } from '../../models/indicator-field';
import { TreeNode } from 'primeng/api';
import { User } from '../../models/user';
import moment from 'moment';
import { IndicatorUser } from '../../models/indicator-user';

@Injectable({
  providedIn: 'root',
})
export class ApiIndicatorsService {
  constructor(private http: HttpClient) {}

  getIndicators(): Observable<Indicator[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_indicators.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataIndicator) => {
            return new Indicator(
              parseInt(dataIndicator.ind_id),
              dataIndicator.ind_label,
              [],
              dataIndicator.ind_alluser ? parseInt(dataIndicator.ind_alluser) : null,
              dataIndicator.ind_responses ? parseInt(dataIndicator.ind_responses) : null,
              dataIndicator.ind_average ? parseFloat(dataIndicator.ind_average) : null
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getIndicator(indicatorId:string): Observable<Indicator> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_indicator_detail.php?indicator_id=' + indicatorId
      )
      .pipe(
        map((dataIndicator: any) => {
          return new Indicator(
            parseInt(dataIndicator.ind_id),
            dataIndicator.ind_label,
            dataIndicator.fields
          );
        })
      );
  }

  postIndicator(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_indicator.php',
      formData
    );
  }

  deleteIndicator(indicatorId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_indicator.php?indicator_id=' +
        indicatorId
    );
  }
 
  
  postIndicatorField(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_indicator_field.php',
      formData
    );
  }

  getIndicatorField(fieldId:string): Observable<IndicatorField> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_indicator_field.php?field_id=' + fieldId
      )
      .pipe(
        map((dataField: any) => {
          return new IndicatorField(
            parseInt(dataField.ifi_id),
            (dataField.ifi_parent_id ? parseInt(dataField.ifi_parent_id) : null),
            dataField.ifi_label,
            parseInt(dataField.ifi_ordre),
            new IndicatorFieldType(
              parseInt(dataField.ifi_ift_id),
              dataField.ift_label,
              dataField.ift_code
            )
          );
        })
      );
  }

  deleteIndicatorField(fieldId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/delete_indicator_field.php?field_id=' +
        fieldId
    );
  }

  getIndicatorResponses(indicatorId:string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_indicator_responses.php?indicator_id=' + indicatorId
      );
  }

  getIndicatorUsers(indicatorId:string): Observable<IndicatorUser[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_indicator_users.php?indicator_id=' + indicatorId
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataIndicatorUsr) => {
            return new IndicatorUser(
              dataIndicatorUsr.ius_id,
              moment(new Date(dataIndicatorUsr.ius_date_add)),
              (dataIndicatorUsr.ius_date_response ? moment(new Date(dataIndicatorUsr.ius_date_response)): null),
              dataIndicatorUsr.ius_usr_id,
              null
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  
  addUser(indicatorId: number, userId: number): Observable<IndicatorUser|boolean> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_indicator_user.php?userId=' +
        userId + '&indicatorId='+ indicatorId + '&addUser=1'
    ).pipe(
      map((dataIndicatorUsr: any) => {
        if(dataIndicatorUsr) {
          return new IndicatorUser(
            dataIndicatorUsr.ius_id,
            moment(new Date(dataIndicatorUsr.ius_date_add)),
            (dataIndicatorUsr.ius_date_response ? moment(new Date(dataIndicatorUsr.ius_date_response)): null),
            dataIndicatorUsr.ius_usr_id,
            null
          );
        } else {
          return false;
        }
         
      })
    );
  }

  removeUser(indicatorId: number, userId: number): Observable<any> {
    return this.http.get<any>(
      environment.apiURL +
        'espace_client/post_indicator_user.php?userId=' +
        userId + '&indicatorId='+ indicatorId + '&addUser=0'
    );
  }

  mapFieldTypes(apiData: any[]): IndicatorFieldType[] {
    let fieldTypes: IndicatorFieldType[] = [];

    apiData.forEach((dataFT: any) => {
      fieldTypes.push(
        new IndicatorFieldType(
          parseInt(dataFT['ift_id']),
          dataFT['ift_code'],
          dataFT['ift_label']
        )
      );
    });

    return fieldTypes;
  }

  // Fonction pour construire un arbre de TreeNode à partir des champs
  buildTreeNode(fields: any[], parentId: string | null): TreeNode[] {    
    const nodes = fields
        .filter(field => field.ifi_parent_id === parentId)
        .map(field => {
            const children = this.buildTreeNode(fields, field.ifi_id);
            return {
                data: {
                    label: field.ifi_label,
                    type_champ_label: field.ift_label,
                    type_champ_code: field.ift_code,
                    ordre: field.ifi_ordre,
                    id: field.ifi_id
                },
                label: field.ifi_label,
                expanded: false,
                children: children.length > 0 ? children : []
            };
        });

    return nodes;
  }

  convertToTreeNodes(fields: any[]): TreeNode[] {
    return this.buildTreeNode(fields, null);
  }

}
