import { Client } from "./client";

export class SensorAlert {
    public id: number;
    public delayStart: number;
    public delayRepeat: number;
    public nbRepeat: number;

    public client: Client;
    public sensorsList: Array<number>;
    public sensorsCount: number;
    public recipients: Array<string>;

    constructor(
        id: number,
        delayStart: number,
        delayRepeat: number,
        nbRepeat: number,
        client: Client,
        sensors: string,
        recipients: Array<string>
        ) {
        this.id = id;
        this.delayStart = delayStart;
        this.delayRepeat = delayRepeat;
        this.nbRepeat = nbRepeat;
        this.client = client;

        //sondes
        const tmpSensors = (sensors ?? '');
        this.sensorsList = tmpSensors != '' ? tmpSensors.split(',').map(Number): [];
        this.sensorsCount = this.sensorsList.length;

        //destinataires
        this.recipients = recipients;
    }
}