@defer (when dataLoaded) { @if(canWrite()) {
<div class="grid">
  <div class="col"></div>
  <div class="col text-right">
    <button
      pButton
      [label]="'Ajouter un utilisateur' | transloco"
      icon="pi pi-plus"
      class="p-button-success"
      (click)="addUser()"
    ></button>
  </div>
</div>
}

<p-table
  [value]="userList"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="users-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="clientGroup.name">
        {{ "Groupe" | transloco }} <p-sortIcon field="clientGroup.name"></p-sortIcon>
      </th>
      <th pSortableColumn="client.name">
        {{ "Licence" | transloco }} <p-sortIcon field="client.name"></p-sortIcon>
      </th>
      <th pSortableColumn="login">
        {{ "Identifiant" | transloco }}<p-sortIcon field="login"></p-sortIcon>
      </th>
      <th pSortableColumn="lastName">
        {{ "Nom" | transloco }} <p-sortIcon field="lastName"></p-sortIcon>
      </th>
      <th pSortableColumn="firstName">
        {{ "Prénom" | transloco }} <p-sortIcon field="firstName"></p-sortIcon>
      </th>
      <th pSortableColumn="inDate">
        {{ "Créé le" | transloco }} <p-sortIcon field="inDate"></p-sortIcon>
      </th>
      <th pSortableColumn="outDate">
        {{ "Résilié le" | transloco }} <p-sortIcon field="outDate"></p-sortIcon>
      </th>
      @if(canWrite()) {
      <th></th>
      }
    </tr>
    <tr>
      <th>
        <p-columnFilter field="clientGroup.id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="groups"
              [placeholder]="'Toutes' | transloco"
              (onChange)="filter($event.value)"
              optionLabel="name"
              optionValue="id"
              [virtualScroll]="true"
              [virtualScrollItemSize]="50"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="licences"
              [placeholder]="'Toutes' | transloco"
              (onChange)="filter($event.value)"
              optionLabel="displayName"
              optionValue="id"
              [virtualScroll]="true"
              [virtualScrollItemSize]="50"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="login"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="lastName"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="firstName"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th></th>
      <th></th>
      @if(canWrite()) {
      <th></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-oneUser>
    <tr [ngClass]="{ 'compte-resilie': oneUser.outDate }">
      <td>{{ oneUser.clientGroup.name }}</td>
      <td>{{ oneUser.client.name }} - {{ oneUser.client.id }}</td>
      <td>{{ oneUser.login }}</td>
      <td>{{ oneUser.lastName }}</td>
      <td>{{ oneUser.firstName }}</td>
      <td>{{ oneUser.inDate | date : "dd/MM/yyyy" }}</td>
      <td>{{ oneUser.outDate | date : "dd/MM/yyyy" }}</td>
      @if(canWrite()) {
      <td class="flex align-items-center justify-content-center">
        @if(oneUser.outDate) {
          <button
            pButton
            icon="pi pi-check"
            class="flex p-button-rounded p-button-activate mr-2"
            (click)="activateUser(oneUser, 1)"
          ></button>
        } @else {
          <button
            pButton
            icon="pi pi-ban"
            class="flex p-button-rounded p-button-unactivate mr-2"
            (click)="activateUser(oneUser, 0)"
          ></button>
        }

        <button
          pButton
          icon="pi pi-pencil"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="editUser(oneUser)"
        ></button>
        <button
          pButton
          icon="fa fa-store fa-xl"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="userLicences(oneUser)"
        ></button>
        @if (isRoot()) {
          <button
            pButton
            icon="fa fa-user-secret fa-xl"
            class="flex p-button-rounded p-button-success mr-2"
            (click)="connectV3(oneUser)"
          ></button>
        }
      </td>
      }
    </tr>
  </ng-template>
</p-table>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
