import { Client } from "./client";

export class ClientStatsTarget {
    public client: Client;
    public tasks: number;
    public temperatures: number;
    public ncs: number;
    public treatments: number;
    public lots: number;
    public photos: number;
    
    constructor(
        client: Client,    
        tasks: number,
        temperatures: number,
        ncs: number,
        treatments: number,
        lots: number,
        photos: number
    ) {
        this.client = client;
        this.tasks = tasks;
        this.temperatures = temperatures;
        this.ncs = ncs;
        this.treatments = treatments;
        this.lots = lots;
        this.photos = photos;
    }
}