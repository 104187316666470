import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    // transforme une chaine pour pouvoir la passer dans une URL
    transformStringToUrlFormat(input: string): string {
        return input
            .toLowerCase()               // Convertit la chaîne en minuscule
            .trim()                      // Enlève les espaces au début et à la fin
            .replace(/\s+/g, '-')        // Remplace tous les espaces par des tirets
            .replace(/[^\w\-]+/g, '')    // Supprime les caractères non-alphanumériques
            .replace(/\-\-+/g, '-');     // Remplace plusieurs tirets consécutifs par un seul
    }

    // Fonction pour mélanger un tableau
    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap
        }
        return array;
    }

    formatStringPayment(value: string, maxLength: number): string {
        // 1. Convertir les caractères en majuscules et enlever les accents
        value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
    
        // 2. Supprimer les caractères non alphanumériques (hors A-Z, 0-9 et espaces)
        value = value.replace(/[^A-Z0-9\s]/g, '');
    
        // 3. Remplacer les espaces multiples par un seul espace
        value = value.replace(/\s+/g, ' ');
    
        // 4. Tronquer la chaîne à la longueur maximale spécifiée
        value = value.substring(0, maxLength);
    
        // 5. Supprimer les espaces en début et fin de chaîne
        return value.trim();
      }

      replaceSimpleQuotes(str: string): string {
        return str.replace(/'/g, "&#039;"); 
      }
    
      async generateHmacSha512(message: string, keyHex: string): Promise<string> {
          // Convertir la clé hexadécimale en bytes
          const keyBytes = new Uint8Array(keyHex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    
          // Importer la clé HMAC dans Web Crypto
          const key = await crypto.subtle.importKey(
              'raw',
              keyBytes,
              { name: 'HMAC', hash: { name: 'SHA-512' } },
              false,
              ['sign']
          );
    
          // Encoder le message en UTF-8
          const msgBuffer = new TextEncoder().encode(message);
    
          // Calculer le HMAC
          const signature = await crypto.subtle.sign('HMAC', key, msgBuffer);
    
          // Convertir le résultat en hex et en uppercase
          const hmacHex = Array.from(new Uint8Array(signature))
              .map(b => b.toString(16).padStart(2, '0'))
              .join('')
              .toUpperCase();
    
          return hmacHex;
      }

      getCurrentDateISO(): string {
        const date = new Date();
      
        // Convertir la date en ISO 8601
        const isoString = date.toISOString();
      
        // Extraire la date et l'heure
        const datePart = isoString.split('.')[0];
      
        // Calculer le décalage horaire en minutes
        const timezoneOffset = -date.getTimezoneOffset();
        const sign = timezoneOffset >= 0 ? '+' : '-';
        const pad = (n: number) => String(n).padStart(2, '0');
      
        // Convertir le décalage en heures et minutes
        const hoursOffset = pad(Math.floor(Math.abs(timezoneOffset) / 60));
        const minutesOffset = pad(Math.abs(timezoneOffset) % 60);
      
        // Combiner la date avec le décalage horaire au format ISO 8601
        return `${datePart}${sign}${hoursOffset}:${minutesOffset}`;
      }

      getPhoneIndicator(libellePays:string): string {
        return (libellePays.toLowerCase() == 'france' ? '+33' : '+34')
      }
  
}