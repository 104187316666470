export class Workspace {
    public id: number;
    public name: string;
    public cliId: number;
    public parentId: number | null;

    constructor(
        id: number,
        name: string,
        cliId: number,
        parentId: number | null
        ) {
        this.id = id;
        this.name = name;
        this.cliId = cliId;
        this.parentId = parentId;
    }
}