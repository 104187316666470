import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../../services/api/api-eshop.service';
import { CartService } from '../../../../services/eshop/cart.service';
import { EshopProduct } from '../../../../models/eshop-product';

@Component({
  selector: 'app-eshop',
  templateUrl: './eshop.component.html',
  styleUrl: './eshop.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    FormsModule,
    ToastModule,
    TranslocoModule,
  ],
  providers:[MessageService]
})

export class EshopComponent implements OnDestroy, OnInit {
  
  productSubscription: Subscription = new Subscription();
  products: EshopProduct[] = [];
  dataLoaded: boolean = false;


  constructor(
    private apiEshopService: ApiEshopService,
    private cartService: CartService,
    private messageService: MessageService,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.productSubscription = this.apiEshopService
      .getProducts()
      .subscribe((data: EshopProduct[]) => {
        this.products = data;
        this.dataLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }

  addProductToCart(productId:number, quantity:number) {
    this.cartService.addToCart(productId, quantity);

    this.messageService.add({ 
      severity: 'success', 
      summary: this.translocoService.translate("Succès"), 
      detail: this.translocoService.translate("Produit ajouté au panier avec succès") 
    });
  }

  redirectProduct(oneProduct: EshopProduct) {
    this.router.navigate(['infos/eshop-product/', oneProduct.id, oneProduct.titleForUrl]);
  }
}
