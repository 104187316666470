export class Indicator {
    public id: number;
    public label: string;
    public fields: any[];
    public nbUsers: number | null;
    public nbResponses: number | null;
    public noteAverage: number | null;
    
    constructor(
        id: number,
        label: string,
        fields: any[],
        nbUsers: number | null = null,
        nbResponses: number | null = null,
        noteAverage: number | null = null,
    ) {
        this.id = id;
        this.label = label;
        this.fields = fields;
        this.nbUsers = nbUsers;
        this.nbResponses = nbResponses;
        this.noteAverage = noteAverage;
    }
}