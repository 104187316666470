export class AlertsType {

  public ale_id: number;
  public ale_name: string;
  constructor(
    ale_id: number,
    ale_name: string,
  ) {
    this.ale_id = ale_id;
    this.ale_name = ale_name;
  }
}
