import { Sensor } from "./sensor";

export class SensorTriggeredAlert {
    public id: number;
    public date: moment.Moment;

    public sensor: Sensor;

    constructor(
        id: number,
        date: moment.Moment,
        sensor: Sensor
        ) {
        this.id = id;
        this.date = date;
        this.sensor = sensor;
    }
}