import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit',
  template: `<p></p>`,
  standalone: true,
  styleUrl: './edit.component.css',
  imports: [],
})
export class EditComponent implements OnInit {
  titlePage: string = '';
  dataLoaded: boolean = false;

  constructor(private originalRoute: ActivatedRoute) {}
  ngOnInit(): void {
    // on charge le titre de la page (dernier element du path)
    this.titlePage =
      this.originalRoute.snapshot.data['path'].slice(-1)[0]['label'];
  }

  // fonction qui teste si le champ est en erreur
  isErrorFormControl(oControl: AbstractControl): boolean {
    return oControl && oControl.touched && oControl['errors'] !== null;
  }

  hasFormError(editForm: AbstractControl, errorCode: string): boolean {
    return editForm['errors'] && editForm['errors'][errorCode];
  }
}
