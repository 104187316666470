import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Client } from '../../models/client';
import { ProcessList } from '../../models/process-list';
import { ReceptionListParameters } from '../../models/reception-list-parameters';


@Injectable({
    providedIn: 'root',
})
export class ApiReceptionListParametersService {
    constructor(private http: HttpClient) {}

    getReceptionListParameters(cli_id:string, cpr_id:string): Observable<any[]> {
        return this.http
            .get<any[]>(environment.apiURL + 'espace_client/get_reception_list_parameters.php', { params: { cli_id, cpr_id } })
            .pipe(
                map((response: any[]) => {
                    return response.map((dataProcessList) => {
                        return new ReceptionListParameters(
                            dataProcessList.cli_name,
                            parseInt(dataProcessList.rpa_id),
                            parseInt(dataProcessList.rpa_cpr_id),
                            dataProcessList.rpa_type,
                            parseInt(dataProcessList.rpa_length),
                            dataProcessList.rpa_validation,
                            dataProcessList.rpa_default,
                            parseInt(dataProcessList.rpa_default_when),
                            parseInt(dataProcessList.rpa_readonly),
                            dataProcessList.rpa_placeholder,
                            dataProcessList.rpa_input_mode,
                            dataProcessList.rpa_values_list,
                            parseInt(dataProcessList.rpa_required),
                            parseInt(dataProcessList.rpa_order),
                            parseInt(dataProcessList.rpa_from_id),
                            dataProcessList.rpa_script,
                            parseInt(dataProcessList.rpa_is_dlc),
                            dataProcessList.rpa_flag,
                            dataProcessList.rpa_name,
                        )
                    })
                }),
                catchError((error) => {
                    return of([]);
                })
            );
    }

    updateReceptionParameter(formData: FormData): Observable<any> {
        return this.http.post<any>(
            environment.apiURL +
            'espace_client/post_update_reception_parameters.php',
            formData
        );
    }
    deleteReceptionParameter(rpa_id: number): Observable<any> {
        return this.http.get<any>(
            environment.apiURL +
            'espace_client/delete_reception_parameters.php?rpa_id=' +
            rpa_id
        );
    }
}
