<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="cli_id" class="label-required">{{ 'Licence' | transloco }}</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="licences"
        (ngModelChange)="onLicenceChange()"
        class="w-full"
        formControlName="cli_id"
        [filter]="true"
        optionLabel="displayName"
        optionValue="id"
        [placeholder]="'Sélectionnez une licence' | transloco"
        [required]="true"
        [virtualScroll]="true" 
        [virtualScrollItemSize]="50"
      ></p-dropdown>
      @if(isErrorFormControl(editForm.controls['cli_id'])) {
      <small class="text-red-500">{{ 'Licence requise' | transloco }}</small>
      }
    </div>
    <div class="field">
      <label for="sensors">{{ 'Sonde(s)' | transloco }}</label>
      <p-multiSelect
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="filteredSensors"
        class="w-full"
        formControlName="sensors"
        [filter]="true"
        optionLabel="label"
        optionValue="id"
        [disabled]="sensorsDisabled"
        [required]="false"
        [emptyMessage]="'Aucune sonde pour la licence sélectionnée' | transloco"
      ></p-multiSelect>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-3">
        <label for="delay_start" class="label-required">{{ 'Délai déclenchement' | transloco }}</label>
        <br />
        <p-inputNumber
          inputId="delay_start"
          formControlName="delay_start"
          [suffix]="' minutes' | transloco"
          [min]="0"
          [max]="500"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['delay_start'])) {
          <br /><small class="text-red-500">{{ 'Délai déclenchement requis' | transloco }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-3">
        <label for="delay_repeat" class="label-required">{{ 'Délai répétition' | transloco }}</label>
        <br />
        <p-inputNumber
          inputId="delay_repeat"
          formControlName="delay_repeat"
          [suffix]="' minutes' | transloco"
          [min]="0"
          [max]="500"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['delay_repeat'])) {
          <br /><small class="text-red-500">{{ 'Délai répétition requis' | transloco }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-3">
        <label for="nb_repeat" class="label-required">{{ 'Nombre de répétitions' | transloco }}</label>
        <br />
        <p-inputNumber
          inputId="nb_repeat"
          formControlName="nb_repeat"
          [min]="0"
          [max]="30"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['nb_repeat'])) {
          <br /><small class="text-red-500">{{ 'Nombre de répétitions requis' | transloco }}</small>
        }
      </div>
    </div>

    
    <h3><i class="fa fa-envelopes-bulk fa-lg"></i> {{ 'Destinataires des alertes :' | transloco }}</h3>
    
    <div formArrayName="recipients">
      @for (email of emailControls; track email; let idx = $index) {        
        <div class="field">
          <label [for]="idx" class="label-required">{{ 'Email destinataire N°' | transloco }}{{ idx + 1}}</label>
          <div class="flex">
            <input 
              pInputText
              type="email" 
              [id]="idx"
              [formControlName]="idx"
              class="p-inputtext w-full mr-2"
              >
            @if(emailControls.length > 1) {
              <button pButton icon="pi pi-trash" class="p-button-p-button-success" (click)="removeEmail(idx)"></button>
            }
          </div>
          @if(email.invalid && (email.dirty || email.touched)) {
            @if(email['errors'] && email['errors']['required']) {
              <br /><small class="text-red-500">{{ 'L\'email est requis.' | transloco }}</small>
            }
            @if(email['errors'] && email['errors']['email']) {
              <br /><small class="text-red-500">{{ 'L\'email est invalide.' | transloco }}</small>
            }
          }
        </div>
      }
    </div>

    <button 
      pButton 
      [label]="'Ajouter un destinataire supplémentaire' | transloco" 
      icon="pi pi-plus" 
      class="p-button-success mb-2" 
      type="button"
      (click)="addRecipient()" ></button>  
   
    @if(saveError) {
      <div class="text-red-500">
        {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
      </div>
      }

      <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <p-button
            type="submit"
            icon="pi pi-save"
            [disabled]="!editForm.valid"
            [label]="'Enregistrer' | transloco"
          ></p-button>
        </div>
        <div class="flex-none flex align-items-center justify-content-center mr-3">
          <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
        </div>
    </div>  
  </p-panel>
</form>
