import { Client } from "./client";

export class ProcessList {
  public tpr_id: number;
  public tpr_name_clean: string;
  public tpr_stats: boolean;
  public tpr_stats_treatments: boolean;
  public tpr_stats_lots: boolean;
  public tpr_stats_photos: boolean;
  public tpr_auto_close: boolean;
  public tpr_hidden: number;
  public tpr_flag: string;
  public client: Client;

  constructor(
    tpr_id: number,
    tpr_name_clean: string,
    tpr_stats: boolean,
    tpr_stats_treatments: boolean,
    tpr_stats_lots: boolean,
    tpr_stats_photos: boolean,
    tpr_auto_close: boolean,
    tpr_hidden: number,
    tpr_flag: string,
    client: Client,
  ) {
    this.tpr_id = tpr_id;
    this.tpr_name_clean = tpr_name_clean;
    this.tpr_stats = tpr_stats;
    this.tpr_stats_treatments = tpr_stats_treatments;
    this.tpr_stats_lots = tpr_stats_lots;
    this.tpr_stats_photos = tpr_stats_photos;
    this.tpr_auto_close = tpr_auto_close;
    this.tpr_hidden = tpr_hidden;
    this.tpr_flag = tpr_flag;
    this.client = client;
  }
}
