import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';

import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute } from '@angular/router';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { Client } from '../../../../models/client';

import { EditComponent } from '../../../core/edit/edit.component';

import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";

import {ProcessOutputList} from "../../../../models/process-output-list";
import {ApiProcessOutputListService} from "../../../../services/api/api-process-list-outputs";
import {ProcessOutputType} from "../../../../models/process-output-type";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";

@Component({
  selector: 'app-process-list-traceability-outputs',
  templateUrl: './process-list-traceability-outputs.component.html',
  styleUrl: './process-list-traceability-outputs.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    RippleModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ConfirmDialogModule,
  ],
  providers: [MessageService, ConfirmationService],
})
export class ProcessListTraceabilityOutputsComponent extends EditComponent implements OnInit, OnDestroy {
  ProcessListOutputSubscription: Subscription = new Subscription();
  ProcessOutputPrintTypeSubscription: Subscription = new Subscription();
  ProcessOutputPathSubscription: Subscription = new Subscription();

  processOutputList!: ProcessOutputList[];
  outputType: ProcessOutputType[] = [];

  name: string = '';
  licences : Client[] = [];
  outputProcessId: string = '';
  processisSelected = false;
  selectedOutput = [];


  editForm: FormGroup;
  dropdownPrintType: { label: string, value: number }[] = [];
  ouiNon: { label: string, value: number }[];
  selectedOutputProcessType: any;
  path: any[];

  constructor(
    private apiProcessOutputListService: ApiProcessOutputListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_printType: ['', Validators.required],
      param_printName: ['', Validators.required],
      param_required: ['', Validators.required],
      param_outputPath: ['', Validators.required],
      param_order: ['', Validators.required],
    })
    this.dropdownPrintType = [];
    this.path = [];
    this.ouiNon = [];
    this.selectedOutputProcessType = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.outputProcessId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    this.licences = this.authService.getUserLicences();

    const processList$ = this.apiProcessOutputListService.getProcessList(this.outputProcessId);
    const outputProcessType$ = this.apiProcessOutputListService.getOutputProcessType();
    const outputProcessPath$ = this.apiProcessOutputListService.getOutputProcessPath();

    // Utilisation de forkJoin pour attendre que tous les appels soient terminés
    forkJoin([processList$, outputProcessType$, outputProcessPath$])
      .subscribe(([processOutputList, outputProcessTypes, outputProcessPath]) => {
        this.processOutputList = processOutputList;
        this.outputType = outputProcessTypes;
        this.dropdownPrintType = outputProcessTypes.map(outputType => ({
          label: outputType.tot_name,
          value: outputType.tot_id
        }));
        this.path = outputProcessPath;
      });

    this.ouiNon = [
      { label: 'Oui', value : 1 },
      { label: 'Non', value : 0 },
    ];

    this.dataLoaded = true;
  }

  onRowSelect(event: any) {
    console.log('event.data', event.data);
  }

  onCellEdit(rowData: any) {
    const selectedOption = this.dropdownPrintType.find(option => option.value === rowData.tot_id);
    if (selectedOption) {
      rowData.tot_name = selectedOption.label;
    }
    console.log('Updated row data:', rowData);
  }

  newOutput() {
    this.processisSelected = true;
  }
  addOutput(formData: any) {
    const rowData = {...formData};

    rowData['tou_id']= -1;
    rowData['tou_name']= rowData.param_printName;
    rowData['tou_tpr_id']= this.outputProcessId;
    rowData['tot_id']= rowData.param_printType;
    rowData['tou_compulsory']= rowData.param_required;
    rowData['tou_exec_path']= rowData.param_outputPath;
    rowData['tou_order']= rowData.param_order;
    console.log('Données du formulaire:', rowData);

    this.apiProcessOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        window.location.reload();
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });
  }

  updateOutput(event: any) {
    const rowData = {...event};
    rowData['client'] = rowData['client']['cli_id'];
    delete rowData.client;
    if (rowData['tou_id'] < 0) {
      rowData['tou_tpr_id'] = rowData['tpr_id'];
    }
    rowData['tot_id'] = rowData['tou_tot_id'];
    this.apiProcessOutputListService.updateOutput(rowData).subscribe((response) => {
      if (response) {
        window.location.reload();
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });

  }

  deleteOutput(event: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Vous êtes sur le point de supprimer définitivement cet ouput !'),
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiProcessOutputListService.deleteOutput( parseInt(event.tou_id)).subscribe((response) => {
          if (response) {
            window.location.reload();
            console.log('Deletion OK', event.tou_id);
          } else{
            console.log('Deletion KO', event.tou_id);
          }
        });
      },
    });
  }

  cancel() {
    this.processisSelected = false;
  }

  ngOnDestroy() {
    this.ProcessOutputPrintTypeSubscription.unsubscribe();
    this.ProcessOutputPathSubscription.unsubscribe();
    this.ProcessListOutputSubscription.unsubscribe()
  }
}
