export class ReceptionOutputType {

  public rot_id: number;
  public rot_name: string;
  constructor(
    rot_id: number,
    rot_name: string,
  ) {
    this.rot_id = rot_id;
    this.rot_name = rot_name;
  }
}
