@defer (when dataLoaded) {
<div class="grid">
  <div class="col"></div>
  <div class="col text-right">
    <button
      pButton
      [label]="labelExport()"
      icon="fa fa-download"
      class="p-button-success"
      (click)="exportDishes()"
    ></button>
  </div>
</div>

<p-table
  #dt
  [value]="dishes"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="dishes-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
  selectionMode="multiple"
  [(selection)]="selectedDishes"
>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th pSortableColumn="client.name">
        {{ "Licence" | transloco }}
        <p-sortIcon field="client.name"></p-sortIcon>
      </th>
      <th pSortableColumn="name">
        {{ "Nom" | transloco }}<p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="identifiant">
        {{ "Identifiant (EAN)" | transloco }}
        <p-sortIcon field="identifiant"></p-sortIcon>
      </th>
      <th pSortableColumn="internalCode">
        {{ "Code" | transloco }} <p-sortIcon field="internalCode"></p-sortIcon>
      </th>
      <th pSortableColumn="qty">
        {{ "Quantité" | transloco }} <p-sortIcon field="qty"></p-sortIcon>
      </th>
      <th pSortableColumn="unityName">
        {{ "Unité" | transloco }} <p-sortIcon field="unityName"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="client.id"  name="client_id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="licences"
              placeholder="Toutes"
              (onChange)="filter($event.value)"
              optionLabel="displayName"
              optionValue="id"
              [virtualScroll]="true"
              [virtualScrollItemSize]="50"
              class="liste-recherche-table"
            >
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="name"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="identifiant"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="internalCode"
          matchMode="contains"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="qty"
          matchMode="equals"
        ></p-columnFilter>
      </th>
      <th>
        <p-columnFilter
          type="text"
          field="unityName"
          matchMode="contains"
        ></p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-dishe>
    <tr>
      <td>
        <p-tableCheckbox [value]="dishe.rheId"></p-tableCheckbox>
      </td>
      <td>{{ dishe.client.name }}</td>
      <td>{{ dishe.name }}</td>
      <td>{{ dishe.identifiant }}</td>
      <td>{{ dishe.internalCode }}</td>
      <td>{{ dishe.qty }}</td>
      <td>{{ dishe.unityName }}</td>
    </tr>
  </ng-template>
</p-table>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
