import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../../services/api/api-eshop.service';
import { CartService } from '../../../../services/eshop/cart.service';
import { EshopProduct } from '../../../../models/eshop-product';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-eshop-cart',
  templateUrl: './eshop-cart.component.html',
  styleUrl: './eshop-cart.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    FormsModule,
    InputNumberModule,
    TableModule,
    TranslocoModule,
  ]
})

export class EshopCartComponent implements OnDestroy, OnInit {
  
  productSubscription: Subscription = new Subscription();
  cartSubscription: Subscription = new Subscription()
  cartProducts: any[] = [];
  dataLoaded: boolean = false;
  cartTotalPrice: number = 0;
  cartTotalTax: number = 0;

  constructor(
    private apiEshopService: ApiEshopService,
    private cartService: CartService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscribeToCartUpdates();
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
  }
  
  subscribeToCartUpdates() {
    // S'abonner aux changements du panier
    this.cartSubscription = this.cartService.getCartItems().subscribe((itemsInCart) => {
      this.updateCartInfos(itemsInCart);
    });
  }

  updateCartInfos(itemsInCart: any[]) {
    // Recalculer le nombre de produits dans le panier
    const nbProducts: number = this.cartService.getTotalQuantity();

    if (nbProducts > 0) {
      this.productSubscription = this.apiEshopService
        .getProducts()
        .subscribe((products: EshopProduct[]) => {
          this.cartTotalPrice = this.cartService.getTotalPrice(products);
          this.cartTotalTax = this.cartService.getTotalTax(products);
          this.cartProducts = itemsInCart
            .map(item => {
              const product = products.find(p => p.id === item.productId);
              if (product) {
                return { ...item, productObject: product }; // Ajouter l'objet produit à l'item
              }
              return null; // Retourner null si le produit n'est pas trouvé
            })
            .filter(item => item !== null); // Filtrer les items sans produit correspondant

          this.dataLoaded = true;
        });
    } else {
      this.cartProducts = [];
      this.dataLoaded = true;
    }
  }

  onQuantityChange(product: any) {
    if(product.quantity == 0) {
      this.cartService.removeFromCart(product.productId);
    } else {
      this.cartService.updateCartQuantity(product.productId, product.quantity);
    }
  }

  removeProduct(product: any) {
    this.cartService.removeFromCart(product.productId);
  }

  redirectProduct(oneProduct: EshopProduct) {
    this.router.navigate(['infos/eshop-product/', oneProduct.id, oneProduct.titleForUrl]);
  }
  
  redirectOrder() {
    this.router.navigate(['infos/eshop-order/']);
  }
}
