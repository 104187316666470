import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';

import { Subscription } from 'rxjs';

import { ApiEshopService } from '../../../../services/api/api-eshop.service';
import { CartService } from '../../../../services/eshop/cart.service';
import { ContentModalService } from '../../../../services/modal/content-modal.service';

import { EshopProduct } from '../../../../models/eshop-product';
import { EditComponent } from '../../../core/edit/edit.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { OrderService } from '../../../../services/eshop/order.service';
import { PaymentService } from '../../../../services/eshop/payment.service';

@Component({
  selector: 'app-eshop-order',
  templateUrl: './eshop-order.component.html',
  styleUrl: './eshop-order.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    PanelModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TableModule,
    TranslocoModule,
  ],
})
export class EshopOrderComponent
  extends EditComponent
  implements OnDestroy, OnInit
{
  productSubscription: Subscription = new Subscription();
  cartSubscription: Subscription = new Subscription();
  cartProducts: any[] = [];
  cartTotalPrice: number = 0;
  cartTotalTax: number = 0;
  usrData: any = [];

  facturationForm: FormGroup;
  countries: any[] = [
    { name: 'France', value: 'france' },
    { name: 'Espagne', value: 'espagne' },
  ];
  saveError: boolean = false;

  itemsInCart: any[] = [];

  constructor(
    private apiEshopService: ApiEshopService,
    private authService: AuthService,
    private cartService: CartService,
    private contentModalService: ContentModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    super(route);

    this.facturationForm = this.fb.group({
      id: [''],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      nomEntreprise: [''],
      pays: [null, Validators.required],
      rue: ['', Validators.required],
      batiment: [''],
      codePostal: ['', Validators.required],
      ville: ['', Validators.required],
      telephone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      notes: [''],
      accepteCGV: ['', Validators.required],
      modePaiement: ['', Validators.required],
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.usrData = this.authService.getUserData();

    if (this.usrData) {
      this.facturationForm.controls['nom'].setValue(this.usrData.last_name);
      this.facturationForm.controls['prenom'].setValue(this.usrData.first_name);
    }

    let orderInfos = this.orderService.getOrderInfos();

    if (orderInfos) {
      // on recupere les infos sauvegardées en session
      for (const clefOrder in orderInfos) {
        if (orderInfos.hasOwnProperty(clefOrder)) {
          const valInfo = orderInfos[clefOrder];

          if (this.facturationForm.controls[clefOrder]) {
            this.facturationForm.controls[clefOrder].setValue(valInfo);
          }
        }
      }
    }

    this.cartSubscription = this.cartService
      .getCartItems()
      .subscribe((itemsInCart) => {
        const nbProducts: number = this.cartService.getTotalQuantity();

        if (nbProducts > 0) {
          this.productSubscription = this.apiEshopService
            .getProducts()
            .subscribe((products: EshopProduct[]) => {
              this.cartTotalPrice = this.cartService.getTotalPrice(products);
              this.cartTotalTax = this.cartService.getTotalTax(products);
              this.itemsInCart = itemsInCart;
              this.cartProducts = itemsInCart
                .map((item) => {
                  const product = products.find((p) => p.id === item.productId);
                  if (product) {
                    return { ...item, productObject: product }; // Ajouter l'objet produit à l'item
                  }
                  return null; // Retourner null si le produit n'est pas trouvé
                })
                .filter((item) => item !== null); // Filtrer les items sans produit correspondant

              this.dataLoaded = true;
            });
        } else {
          this.cartProducts = [];
          this.dataLoaded = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
  }

  onSubmit() {
    this.orderService.saveOrder(this.facturationForm.value);

    if (this.facturationForm.valid) {
      //on pousse la commande sur le back
      let formData = new FormData();

      formData.append('eor_id', this.facturationForm.controls['id'].value);
      formData.append('eor_name', this.facturationForm.controls['nom'].value);
      formData.append(
        'eor_first_name',
        this.facturationForm.controls['prenom'].value
      );
      formData.append(
        'eor_company',
        this.facturationForm.controls['nomEntreprise'].value
      );
      formData.append(
        'eor_country',
        this.facturationForm.controls['pays'].value
      );
      formData.append(
        'eor_address',
        this.facturationForm.controls['rue'].value
      );
      formData.append(
        'eor_address2',
        this.facturationForm.controls['batiment'].value
      );
      formData.append(
        'eor_zipcode',
        this.facturationForm.controls['codePostal'].value
      );
      formData.append('eor_city', this.facturationForm.controls['ville'].value);
      formData.append(
        'eor_phone_number',
        this.facturationForm.controls['telephone'].value
      );
      formData.append(
        'eor_email',
        this.facturationForm.controls['email'].value
      );
      formData.append(
        'eor_notes',
        this.facturationForm.controls['notes'].value
      );
      formData.append(
        'eor_payment_method',
        this.facturationForm.controls['modePaiement'].value
      );
      formData.append('eor_status', this.orderService.STATUS_WAIT_PAYMENT);

      formData.append('itemsInCart', JSON.stringify(this.itemsInCart));

      this.apiEshopService.postOrder(formData).subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          this.facturationForm.controls['id'].setValue(response.eor_id);
          this.orderService.saveOrder(this.facturationForm.value);

          this.paymentService.savePaymentInfos(response.paymentInfos);

          this.router.navigate(['infos/eshop-payment']);
        }
      });
    } else {
      this.facturationForm.markAllAsTouched(); // Marquer tous les champs comme touchés
    }
  }

  openConfidentialite() {
    this.contentModalService.showFile(
      this.translocoService.translate('Politique de confidentialité'),
      'politique_confidentialite.html'
    );
  }
  openCGV() {
    this.contentModalService.showFile(
      this.translocoService.translate('Conditions générales de vente'),
      'cgv.html'
    );
  }
}
