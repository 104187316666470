@defer (when dataLoaded) {
<div>
<h3 style="text-align: center">{{  "Nouvelle alerte pour la licence " + "\"" + name + "\"" }}</h3>
</div>
<div>
<p-panel [header]="'Configuration du paramètre' | transloco">
  <form [formGroup]="editForm" (ngSubmit)="save()" class="py-5">
    <div class="formgrid grid">
      <div class="field col-6">
      <label for="alertsType" class="label-required">{{
      "Alerte concernant: " | transloco
      }}</label>
      <p-dropdown
      [style]="{ width: '100%' }"
      [options]="dropdownAlertsType"
      class="w-full"
      formControlName="param_alertsType"
      [(ngModel)]="selectedAlertTypeId"
      [filter]="true"
      optionLabel="label"
      optionValue="value"
      [required]="true"
      ></p-dropdown>
      @if(isErrorFormControl(editForm.controls['param_alertType'])) {
      <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
      }
      </div>
      <div class="field col-6"></div>
      <div class="field col-6">
          <label for="alertsFrequencies" class="label-required">{{
            "Quand générer la pop-up ?" | transloco
            }}</label>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="dropdownAlertsFrequencies"
            class="w-full"
            formControlName="param_alertsFrequencies"
            [filter]="true"
            optionLabel="label"
            optionValue="value"
            [required]="true"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['param_alertFrequencies'])) {
          <small class="text-red-500">{{ "Champs obligatoire" | transloco }}</small>
          }
      </div>
      <div class="field col-6"></div>
      <div class="field col-6">
        <!--<label for="alertsDelay">{{ "Fréquence d'apparition (en heures)" | transloco }}</label>
<p-inputNumber
               pInputText
               [(ngModel)]="value"
               [step]="1"
               [min]="1"
               [max]="24"
               formControlName="param_alertsDelay"
               class="slider w-full mb-3"
               oninput="this.value = this.value.replace(/[^0-9]/g, '');"
></p-inputNumber>
        <p-slider class="w-full"
                  formControlName="param_alertsDelay"
                  [(ngModel)]="value"
                  [min]="1"
                  [max]="24"
        ></p-slider>-->
      <label for="alertsDelay" class="label-required"
      >{{ "Fréquence d'apparition" | transloco }}
      <i class="fa fa-clock fa-lg text-red-500"></i
      ></label>
      <br />
      <p-inputNumber
        inputId="alertsDelay"
        formControlName="param_alertsDelay"
        suffix=" heure(s)"
        [min]="1"
        [max]="24"
        [showButtons]="true"
        [required]="true"
      ></p-inputNumber>
      </div>
    </div>
    <div class="field col-6"></div>
    <div class="formgrid grid">
      <div class="field col-6">
        <label for="messagePerso">{{ "Message" | transloco }}</label>
        <br />
        <textarea
          pInputTextarea
          formControlName="param_messagePerso"
          class="w-full mr-2"
          rows="4"
        ></textarea>
      </div>
    </div>

    <div class="flex align-items-center">
      <label class=" mr-4">{{ "Message de type:" | transloco }}</label>
      <div class="flex align-items-center mr-6">
        <p-radioButton
          name="alertGroup"
          value="Information"
          formControlName="alertGroup"
          class="mr-2"
          [pTooltip]="'Informatif' | transloco"
          [(ngModel)]="selectedBouttonRadio"
          tooltipPosition="left"
          inputId="Informatif"
        ></p-radioButton>
        <label for="circle-exclamation" class="ml-2 flex align-items-center alert-info">
          <i class="fa fa-circle-exclamation mr-2"></i>
          Information
        </label>
      </div>

      <div class="flex align-items-center mr-6">
        <p-radioButton
          name="alertGroup"
          value="Important"
          formControlName="alertGroup"
          class="mr-2"
          [pTooltip]="'Important' | transloco"
          [(ngModel)]="selectedBouttonRadio"
          tooltipPosition="left"
          inputId="Important"
        ></p-radioButton>
        <label for="triangle-exclamation" class="ml-2 flex align-items-center alert-warning">
          <i class="fa fa-triangle-exclamation mr-2"></i>
          Important
        </label>
      </div>

      <div class="flex align-items-center mr-6">
        <p-radioButton
          name="alertGroup"
          value="Trés important"
          formControlName="alertGroup"
          class="mr-2"
          [pTooltip]="'Trés important' | transloco"
          [(ngModel)]="selectedBouttonRadio"
          tooltipPosition="left"
          inputId="Tres_Important"
        ></p-radioButton>
        <label for="exclamation-triangle" class="ml-2 flex align-items-center alert-danger">
          <i class="fa fa-triangle-exclamation mr-2"></i>
          Très Important
        </label>
      </div>
    </div>
  </form>
</p-panel>
    <div class="grid py-1">
      <div class="col"></div>
        <div class="col text-right">
        <button
        pButton
        type="submit"
        [label]="'Enregistrer' | transloco"
        icon="pi pi-save"
        class="p-button-success"
        [disabled]="!editForm.valid"
        (click)="save()"
        ></button>
        </div>
    </div>

</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-toast position="top-center"></p-toast>
