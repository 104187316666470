import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../../services/auth/auth.service';
import { ApiUsersService } from '../../../../services/api/api-users.service';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import { Client } from '../../../../models/client';
import { ClientGroup } from '../../../../models/client-group';
import { User } from '../../../../models/user';

import moment from 'moment';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    ConfirmDialogModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
  providers: [ConfirmationService],
})
export class UsersListComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private apiUsersService: ApiUsersService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private translocoService: TranslocoService
  ) {}

  userList: User[] = [];
  licences: Client[] = [];
  groups: ClientGroup[] = [];
  usersSubscription: Subscription = new Subscription();
  dataLoaded: boolean = false;

  ngOnInit(): void {
      this.usersSubscription = this.apiUsersService
        .getUsers()
        .subscribe((data: User[]) => {
          this.userList = data;
          this.dataLoaded = true;
        });
      this.licences = this.authService.getUserLicences();
      this.groups = this.authService.getUserGroups();
      
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }
  
  isRoot(): boolean {
    return this.authService.isRoot();
  }

  addUser() {
    this.router.navigate(['settings/users-add']);
  }

  editUser(userData: User) {
    this.router.navigate(['settings/users-edit', userData.id]);
  }

  activateUser(userData: User, userActivate: number) {
    const actionTitle = (userActivate == 1 ? 'réactivation': 'désactivation');

    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la ' + actionTitle + ' de l\'utilisateur ?'
        ) + '<br><br>' + userData.firstName + ' ' + userData.lastName + ' ( ' + userData.login + ' )',
        header: this.translocoService.translate(actionTitle + ' utilisateur'),
        acceptLabel: this.translocoService.translate('Oui'),
        rejectLabel: this.translocoService.translate('Non'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.apiUsersService.activateUser(userData.id, userActivate).subscribe((response: any) => {
            if (response) {
              this.userList = this.userList.map((user: User) => {
                if (user.id === userData.id) {
                    return { ...user, outDate: (userActivate == 1 ? null: moment()) };
                }
                return user;
            });
            }
          });
        },
    });
  }
  userLicences(userData: User) {
    this.router.navigate(['settings/users-licences', userData.id]);
  }

  connectV3(userData: User) {
    this.apiUsersService
    .getUserToken(userData.id)
    .subscribe((response: any) => {
      if (response) {
        window.open(environment.v3URL+'token?appToken='+ response);
      }

    });
  }

  ngOnDestroy(): void {
    this.usersSubscription.unsubscribe();
  }
}
