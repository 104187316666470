import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, AbstractControlOptions, ReactiveFormsModule  } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';

import { ApiService } from '../../../../services/api/api.service';
import { ApiSensorsService } from '../../../../services/api/api-sensors.service';
import { AuthService } from '../../../../services/auth/auth.service';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco'; 
import { Client } from '../../../../models/client';
import { Workspace } from '../../../../models/workspace';
import { Sensor } from '../../../../models/sensor';
import { EditComponent } from '../../../core/edit/edit.component';

interface GroupedItem {
  name: string;
  id: number;
  items: { name: string; id: number }[];
}
@Component({
  selector: 'app-sensors-edit',
  templateUrl: './sensors-edit.component.html',
  styleUrl: './sensors-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    PanelModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
})
export class SensorsEditComponent extends EditComponent implements OnInit, OnDestroy {
  sensorSubscription: Subscription = new Subscription();
  workspaces: Workspace[] = [];
  workspaceSubscription: Subscription = new Subscription();
  
  editForm: FormGroup;

  sensorId: string = '';

  filteredWorkspaces: GroupedItem[] = [];
  workspaceDisabled:boolean = true;
  
  licences : Client[] = [];

  saveError:boolean = false;
  
  constructor(
    private apiService: ApiService,
    private apiSensorsService: ApiSensorsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
      super(route);

      this.editForm = this.formBuilder.group({
        sen_label: ['', Validators.required],
        sen_external_code: ['', Validators.required],
        sen_cli_id: ['', Validators.required],
        sen_wrk_id: ['', Validators.required],
        sen_min_temp: [-10, Validators.required],
        sen_max_temp: [10, Validators.required],
        
        sen_display_before_morning: [false],
        sen_display_after_morning: [false],
        sen_display_before_lunch: [false],
        sen_display_after_lunch: [false],
        sen_display_before_evening: [false],
        sen_display_after_evening: [false],
      }, { validators: this.temperatureValidator } as AbstractControlOptions); 
   }

  temperatureValidator(group: FormGroup) {
    const tempMin = group.controls['sen_min_temp'].value;
    const tempMax = group.controls['sen_max_temp'].value;

    if (tempMax !== null && tempMin !== null && tempMax < tempMin) {
      return { invalidRange: true };
    } else {
      return null;
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    
    this.sensorId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    this.licences = this.authService.getUserLicences();    
    
    this.workspaceSubscription = this.apiService.getWorkspaces().subscribe((data: Workspace[]) => {
        this.workspaces = data;

        if(this.sensorId != '') {
          // recuperer les données de la sonde   
          this.sensorSubscription = this.apiSensorsService.getSensor(this.sensorId).subscribe((sensor: Sensor) => {

              this.editForm.controls['sen_label'].setValue(sensor.label);
              this.editForm.controls['sen_external_code'].setValue(sensor.externalCode);
              this.editForm.controls['sen_cli_id'].setValue(sensor.client?.id);
              this.editForm.controls['sen_min_temp'].setValue(sensor.minTemp);
              this.editForm.controls['sen_max_temp'].setValue(sensor.maxTemp);
              
              this.editForm.controls['sen_display_before_morning'].setValue(sensor.displayBeforeMorning == 1);
              this.editForm.controls['sen_display_after_morning'].setValue(sensor.displayAfterMorning == 1);

              this.editForm.controls['sen_display_before_lunch'].setValue(sensor.displayBeforeLunch == 1);
              this.editForm.controls['sen_display_after_lunch'].setValue(sensor.displayAfterLunch == 1);

              this.editForm.controls['sen_display_before_evening'].setValue(sensor.displayBeforeEvening == 1);
              this.editForm.controls['sen_display_after_evening'].setValue(sensor.displayAfterEvening == 1);


              //on charge la liste des zones
              this.onLicenceChange();

              //on selectionne la zone
              this.editForm.controls['sen_wrk_id'].setValue(sensor.workspace?.id);    
          });    
        }
    });    
  }
  ngOnDestroy() {
      this.sensorSubscription.unsubscribe();
      this.workspaceSubscription.unsubscribe();
  }


  groupByParentId(items: Workspace[]): GroupedItem[] {
    /* cette fonction regroupe les zones en fonction du parent_id */
    const groupedItems: { [key: number]: GroupedItem } = {};

    items.forEach(item => {
        if (item.parentId === null) {
            groupedItems[item.id] = {
                name: item.name,
                id: item.id,
                items: []
            };
        }
    });

    items.forEach(item => {
        if (item.parentId !== null) {
            if (groupedItems[item.parentId]) {
                groupedItems[item.parentId].items.push({
                    name: item.name,
                    id: item.id
                });
            }
        }
    });

    return Object.values(groupedItems);
  }
  
  onLicenceChange() {
    // Filtrer les zones en fonction de la licence sélectionnée
    if (this.editForm.controls['sen_cli_id'].value) {
      this.workspaceDisabled = false;
      const tmpWorkspaces = this.workspaces.filter((workspace : Workspace) => workspace.cliId === this.editForm.controls['sen_cli_id'].value);
      this.filteredWorkspaces = this.groupByParentId(tmpWorkspaces);
    } else {
      this.workspaceDisabled = true;
      this.filteredWorkspaces = [];
    }
  }

  save() {    

    let formData = new FormData();
    formData.append('sen_id', this.sensorId);
    formData.append('sen_cli_id', this.editForm.controls['sen_cli_id'].value);
    formData.append('sen_external_code', this.editForm.controls['sen_external_code'].value);
    formData.append('sen_label', this.editForm.controls['sen_label'].value);
    formData.append('sen_wrk_id', this.editForm.controls['sen_wrk_id'].value);
    formData.append('sen_min_temp', this.editForm.controls['sen_min_temp'].value);
    formData.append('sen_max_temp', this.editForm.controls['sen_max_temp'].value);

    formData.append('sen_display_before_morning', (this.editForm.controls['sen_display_before_morning'].value ? '1' : '0'));
    formData.append('sen_display_after_morning', (this.editForm.controls['sen_display_after_morning'].value ? '1' : '0'));
    formData.append('sen_display_before_lunch', (this.editForm.controls['sen_display_before_lunch'].value ? '1' : '0'));
    formData.append('sen_display_after_lunch', (this.editForm.controls['sen_display_after_lunch'].value ? '1' : '0'));
    formData.append('sen_display_before_evening', (this.editForm.controls['sen_display_before_evening'].value ? '1' : '0'));
    formData.append('sen_display_after_evening', (this.editForm.controls['sen_display_after_evening'].value ? '1' : '0'));

    this.apiSensorsService.postSensor(formData)
    .subscribe(response => {
      this.saveError = !response;

      if(response) {
        this.router.navigate(['settings/sensors']);
      }
    });
  }
}
