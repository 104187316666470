import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import { ApiIndicatorsService } from '../../../../services/api/api-indicators.service';
import { Indicator } from '../../../../models/indicator';
import { TreeNode } from 'primeng/api';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-indicators-stats',
  templateUrl: './indicators-stats.component.html',
  styleUrl: './indicators-stats.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})
export class IndicatorsStatsComponent implements OnInit, OnDestroy {
  TYPE_CHAMP_RUBRIQUE: string = 'RUBRIQUE';

  responses: any[] = [];
  cols: Column[] = [];
  
  constructor(
    private apiIndicatorsService: ApiIndicatorsService,
    private route: ActivatedRoute
  ) {}

  dataLoaded: boolean = false;
  indicSubscription: Subscription = new Subscription();
  indicUsersSubscription: Subscription = new Subscription();
  indicatorId: string = '';
  indicatorFields: TreeNode[] = [];

  ngOnInit(): void {
    this.indicatorId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.indicatorId != '') {
      // recuperer les données de l'indicateur'
      this.indicSubscription = this.apiIndicatorsService
        .getIndicator(this.indicatorId)
        .subscribe((indicator: Indicator) => {
          this.indicatorFields = this.apiIndicatorsService.convertToTreeNodes(
            indicator.fields
          );

          this.addFieldsToColumns(this.indicatorFields);
          
          //recupère les reponses
          this.indicUsersSubscription = this.apiIndicatorsService
            .getIndicatorResponses(this.indicatorId)
            .subscribe((lstResponses: any) => {
              this.responses = lstResponses;

              this.dataLoaded = true;
            });
        });
    }
  }

  addFieldsToColumns(fields: any[]) {
    fields.forEach((oneField : any) => {
      if(oneField.data.type_champ_code != this.TYPE_CHAMP_RUBRIQUE) {
        this.cols.push({ field: 'field_' + oneField.data.id, header: oneField.label });
      }
      
      if(oneField.children?.length > 0) {
        this.addFieldsToColumns(oneField.children);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.indicUsersSubscription) {
      this.indicUsersSubscription.unsubscribe();
    }
    if (this.indicUsersSubscription) {
      this.indicUsersSubscription.unsubscribe();
    }
  }
}
