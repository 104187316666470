import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  readonly STATUS_WAIT_PAYMENT = 'WAIT_PAYMENT';
  readonly MODE_VIREMENT = 'virement';
  readonly MODE_CARTE_BANCAIRE = 'cb';

  private orderInfos: any[] = [];

  constructor() {}

  saveOrder(orderInfos:any): void {
    const { accepteCGV, ...filteredData } = orderInfos;
    this.orderInfos = filteredData;
    localStorage.setItem('order', JSON.stringify(this.orderInfos));
  }

  getOrderInfos(): any[] {
    const savedOrder = localStorage.getItem('order');
    if (savedOrder) {
      this.orderInfos = JSON.parse(savedOrder);
    }

    return this.orderInfos;
  }

  clear() {
    //on conserve les infos de facturation pour les commandes suivantes
    const savedOrder = localStorage.getItem('order');
    if (savedOrder) {
      let tmpOrderInfos : any = JSON.parse(savedOrder);
      tmpOrderInfos.id = '';
      tmpOrderInfos.modePaiement = '';
      tmpOrderInfos.itemsInCart = [];

      this.saveOrder(tmpOrderInfos);
    }
  }
}
