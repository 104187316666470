import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { EditComponent } from '../../../../core/edit/edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelModule } from 'primeng/panel';
import { Subscription } from 'rxjs';
import { Indicator } from '../../../../../models/indicator';
import { ApiIndicatorsService } from '../../../../../services/api/api-indicators.service';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';
import { TreeService } from '../../../../../services/tree/tree.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-indicators-edit',
  templateUrl: './indicators-edit.component.html',
  styleUrl: './indicators-edit.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    PanelModule,
    TranslocoModule,
    TreeTableModule,
  ],
  providers: [ConfirmationService],
})
export class IndicatorsEditComponent
  extends EditComponent
  implements OnInit, OnDestroy
{
  editForm: FormGroup;
  saveError: boolean = false;
  indicSubscription: Subscription = new Subscription();
  indicatorId: string = '';
  indicatorFields: TreeNode[] = [];
  maxOrdres: { [key: string]: number } = {};
  allNodesExpanded = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiIndicatorService: ApiIndicatorsService,
    private router: Router,
    private treeService: TreeService,
    private translocoService: TranslocoService,
    private confirmationService: ConfirmationService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      ind_label: ['', Validators.required],
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.indicatorId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];

    if (this.indicatorId != '') {
      // recuperer les données de l'indicateur'
      this.indicSubscription = this.apiIndicatorService
        .getIndicator(this.indicatorId)
        .subscribe((indicator: Indicator) => {

          //on calcule l'ordre max pour chaque parent
          indicator.fields.forEach(item => {
            const parentId = item.ifi_parent_id ?? 0;
            const ordre = parseInt(item.ifi_ordre);

            if (!this.maxOrdres[parentId] || this.maxOrdres[parentId] < ordre) {
              this.maxOrdres[parentId] = ordre;
            }
          });

          this.indicatorFields = this.apiIndicatorService.convertToTreeNodes(indicator.fields);

          this.editForm.controls['ind_label'].setValue(indicator.label);
        });
    }
  }

  ngOnDestroy() {
    this.indicSubscription.unsubscribe();
  }

  save() {
    let formData = new FormData();
    formData.append('ind_id', this.indicatorId);
    formData.append('ind_label', this.editForm.controls['ind_label'].value);

    this.apiIndicatorService
      .postIndicator(formData)
      .subscribe((response: any) => {
        this.saveError = !response;

        if (response) {
          if(this.indicatorId == '') {
            //création on reste sur le formulaire pour ajouter les champs
            this.router.navigate(['settings/indicators-edit', response.ind_id])
          } else {
            this.router.navigate(['settings/indicators']);
          }
          
        }
      });
  }

  addField(parentId:number) {
    const nextOrder = (this.maxOrdres[parentId] ?? 0) + 1;

    this.router.navigate(['settings/indicators-fields-add', this.indicatorId, parentId, nextOrder]);
  }
  editField(dataField:any) {
    this.router.navigate(['settings/indicators-fields-edit', this.indicatorId, dataField.id]);
  }

  toggleExpand() {
    this.allNodesExpanded = !this.allNodesExpanded;
    this.indicatorFields = this.treeService.updateNodeExpansion(this.indicatorFields, this.allNodesExpanded);
  }

  deleteField(indicData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression du champ'
        ) +
        ' "' +
        indicData.label +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiIndicatorService
          .deleteIndicatorField(indicData.id)
          .subscribe((response: any) => {
            console.log('response??',response)
            if (response) {
              console.log('recherche', indicData.id);
              console.log('avant', this.indicatorFields);
              this.indicatorFields = this.treeService.removeNodeAndChildren(this.indicatorFields, indicData.id);
              console.log('apres', this.indicatorFields);
            }
          });
      },
    });
  }

}
