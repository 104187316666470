import {Client} from "./client";

export class ProcessOutputList {
  public tou_id: number;
  public tou_tpr_id: number;
  public tou_name: string;
  public tou_tot_id: number;
  public tot_name: string;
  public tou_compulsory: number;
  public tou_order: number;
  public tou_exec_path: string;
  public tou_from_id: number;
  public client: Client;

  constructor(
    tou_id: number,
    tou_tpr_id: number,
    tou_name: string,
    tou_tot_id: number,
    tot_name: string,
    tou_compulsory: number,
    tou_order: number,
    tou_exec_path: string,
    tou_from_id: number,
    client: Client,
  ) {
    this.tou_id = tou_id;
    this.tou_tpr_id = tou_tpr_id;
    this.tou_name = tou_name;
    this.tou_tot_id = tou_tot_id;
    this.tot_name = tot_name;
    this.tou_compulsory = tou_compulsory;
    this.tou_order = tou_order;
    this.tou_exec_path = tou_exec_path;
    this.tou_from_id = tou_from_id
    this.client = client;
  }
}
