import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EshopProduct } from '../../models/eshop-product';

interface CartItem {
  productId: number;
  quantity: number;
  productObject?: EshopProduct;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartItemsSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject<CartItem[]>([]);
  private cartItems: CartItem[] = [];

  constructor() {
    // Initialiser le panier depuis le localStorage
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.cartItems = JSON.parse(savedCart);
      this.cartItemsSubject.next(this.cartItems);
    }
  }

  getCartItems(): Observable<CartItem[]> {
    return this.cartItemsSubject.asObservable();
  }

  addToCart(productId: number, quantity: number): void {
    const existingItem = this.cartItems.find(item => item.productId === productId);
    if (existingItem) {
      // Mettre à jour la quantité si le produit existe déjà
      existingItem.quantity += quantity;
    } else {
      // Ajouter un nouveau produit avec la quantité
      const cartItem: CartItem = {
        productId: productId,
        quantity: quantity,
      };

      this.cartItems.push(cartItem);
    }
    this.updateCart();
  }

  updateCartQuantity(productId:number, quantity:number): void{
    const existingItem = this.cartItems.find(item => item.productId === productId);
    if (existingItem) {
      existingItem.quantity = quantity;
      this.updateCart();
    } else {
      this.addToCart(productId, quantity);
    }
  }

  removeFromCart(productId: number): void {
    this.cartItems = this.cartItems.filter(item => item.productId !== productId);
    this.updateCart();
  }

  clearCart(): void {
    this.cartItems = [];
    this.updateCart();
  }

  private updateCart(): void {
    localStorage.setItem('cart', JSON.stringify(this.cartItems));
    this.cartItemsSubject.next(this.cartItems);
  }

  getTotalPrice(products: EshopProduct[]): number {
    return this.cartItems.reduce((acc, item) => {
      const product = products.find(p => p.id === item.productId);
      // Si le produit est trouvé, ajouter son prix multiplié par la quantité
      if (product) {
        return acc + product.htPrice * item.quantity;
      }
      return acc;
    }, 0);
  }

  getTotalTax(products: EshopProduct[]): number {
    return this.cartItems.reduce((acc, item) => {
      const product = products.find(p => p.id === item.productId);
      // Si le produit est trouvé, ajouter son prix multiplié par la quantité
      if (product) {
        return acc + Math.round(((product.htPrice * item.quantity) *  (product.tva / 100)) * 100) / 100;
      }
      return acc;
    }, 0);
  }

  getTotalQuantity(): number {
    return this.cartItems.reduce((acc, item) => acc + item.quantity, 0);
  }

}
