@defer (when dataLoaded) {
<div class="grid">
  <div class="col-12 md:col-6 xl:col-6">
    <h3 class="pt-1 mt-0">
      <i class="fa fa-square-plus"></i>
      {{ "Ajout d'utilisateurs" | transloco }}
    </h3>
    <p-table
      #tableAvailable
      [value]="availableUsers"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [paginator]="true"
      [rows]="15"
      [showCurrentPageReport]="false"
      [currentPageReportTemplate]="
        'Lignes {first} à {last} sur un total de {totalRecords}' | transloco
      "
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="clientGroup.name">
            {{ "Groupe" | transloco }}
            <p-sortIcon field="clientGroup.name"></p-sortIcon>
          </th>
          <th pSortableColumn="client.name">
            {{ "Licence" | transloco }}
            <p-sortIcon field="client.name"></p-sortIcon>
          </th>
          <th pSortableColumn="fullName">
            {{ "Nom et prénom" | transloco }}
            <p-sortIcon field="fullName"></p-sortIcon>
          </th>
          @if(canWrite()) {
          <th></th>
          }
        </tr>
        <tr>
          <th>
            <p-columnFilter
              field="clientGroup.id"
              matchMode="in"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="groups"
                  [placeholder]="'Toutes' | transloco"
                  (onChange)="filter($event.value)"
                  optionLabel="name"
                  optionValue="id"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="50"
                  class="liste-recherche-table"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="licences"
                  [placeholder]="'Toutes' | transloco"
                  (onChange)="filter($event.value)"
                  optionLabel="displayName"
                  optionValue="id"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="50"
                  class="liste-recherche-table"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="fullName"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          @if(canWrite()) {
          <th></th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-oneUser>
        <tr>
          <td>{{ oneUser.clientGroup.name }}</td>
          <td>{{ oneUser.client.name }} - {{ oneUser.client.id }}</td>
          <td>{{ oneUser.fullName }}</td>
          @if(canWrite()) {
          <td class="center-text pt-1 pb-1">
            <button
              pButton
              icon="fa fa-angle-right"
              class="p-button-rounded p-button-activate"
              (click)="addUser(oneUser)"
            ></button>
          </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 md:col-6 xl:col-6">
    <h3 class="pt-1 mt-0">
      <i class="fa fa-plug-circle-check mr-2"></i>
      {{ "Utilisateurs affectés" | transloco }}
    </h3>
    <p-table
      #tableUsers
      [value]="indicatorsUser"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [paginator]="true"
      [rows]="15"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="
        'Lignes {first} à {last} sur un total de {totalRecords}' | transloco
      "
    >
      <ng-template pTemplate="header">
        <tr>
          @if(canWrite()) {
          <th></th>
          }
          <th pSortableColumn="user.clientGroup.name">
            {{ "Groupe" | transloco }}
            <p-sortIcon field="user.clientGroup.name"></p-sortIcon>
          </th>
          <th pSortableColumn="user.client.name">
            {{ "Licence" | transloco }}
            <p-sortIcon field="user.client.name"></p-sortIcon>
          </th>
          <th pSortableColumn="user.fullName">
            {{ "Nom et prénom" | transloco }}
            <p-sortIcon field="user.fullName"></p-sortIcon>
          </th>
          <th pSortableColumn="dateAdd">
            {{ "Date ajout" | transloco }}
            <p-sortIcon field="dateAdd"></p-sortIcon>
          </th>
          <th pSortableColumn="dateResponse">
            {{ "Date réponse" | transloco }}
            <p-sortIcon field="dateResponse"></p-sortIcon>
          </th>
        </tr>
        <tr>
          @if(canWrite()) {
          <th></th>
          }
          <th>
            <p-columnFilter
              field="user.clientGroup.id"
              matchMode="in"
              [showMenu]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="groups"
                  [placeholder]="'Toutes' | transloco"
                  (onChange)="filter($event.value)"
                  optionLabel="name"
                  optionValue="id"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="50"
                  class="liste-recherche-table"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="user.client.id" matchMode="in" [showMenu]="false">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="licences"
                  [placeholder]="'Toutes' | transloco"
                  (onChange)="filter($event.value)"
                  optionLabel="displayName"
                  optionValue="id"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="50"
                  class="liste-recherche-table"
                >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter
              type="text"
              field="user.fullName"
              matchMode="contains"
            ></p-columnFilter>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-oneIndicatorUser>
        <tr>
          @if(canWrite()) {
          <td class="center-text pt-1 pb-1">
            @if(!oneIndicatorUser.dateResponse) {
              <button
                pButton
                icon="fa fa-angle-left"
                class="p-button-rounded p-button-unactivate"
                (click)="removeUser(oneIndicatorUser)"
              ></button>
            }
          </td>
          }
          <td>{{ oneIndicatorUser.user.clientGroup?.name }}</td>
          <td>{{ oneIndicatorUser.user.client?.name }} - {{ oneIndicatorUser.user.client.id }}</td>
          <td>{{ oneIndicatorUser.user.fullName }}</td>
          <td>{{ oneIndicatorUser.dateAdd | date : "dd/MM/yyyy"  }}</td>
          <td>{{ oneIndicatorUser.dateResponse | date : "dd/MM/yyyy"  }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
