
@defer (when dataLoaded) {
    @if(canWrite()) {
    <div class="grid">
        <div class="col">
        </div>
        <div class="col text-right">
            <button pButton  [label]="'Ajouter une alerte' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addAlert()" ></button>  
        </div>
    </div>
}

<p-table 
    [value]="sensorsAlerts" 
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="sensors-alert-list-session"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    >
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="client.name">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>
            <th pSortableColumn="delayStart">{{ 'Délai déclenchement' | transloco }}<p-sortIcon field="delayStart"></p-sortIcon></th>
            <th pSortableColumn="delayRepeat">{{ 'Délai répétition' | transloco }} <p-sortIcon field="delayRepeat"></p-sortIcon></th>
            <th pSortableColumn="nbRepeat">{{ 'Nombre de répétitions' | transloco }} <p-sortIcon field="nbRepeat"></p-sortIcon></th>
            <th pSortableColumn="sensorsCount">{{ 'Sondes' | transloco }} <p-sortIcon field="sensorsCount"></p-sortIcon></th>
            @if(canWrite()) {
                <th></th>
            }
        </tr>
        <tr>
            <th>
                <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect 
                            [ngModel]="value" 
                            [options]="licences" 
                            [placeholder]="'Toutes' | transloco" 
                            (onChange)="filter($event.value)" 
                            optionLabel="displayName" 
                            optionValue="id"
                            [virtualScroll]="true"
                            [virtualScrollItemSize]="50"
                            class="liste-recherche-table"
                            >
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="delayStart" matchMode="equals"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="delayRepeat" matchMode="equals"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="nbRepeat" matchMode="equals"></p-columnFilter>
            </th>            
            <th></th>
            @if(canWrite()) {
                <th></th>
            }
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sensorAlert>
        <tr>
            <td>{{ sensorAlert.client.name }}</td>
            <td>{{ sensorAlert.delayStart }}</td>
            <td>{{ sensorAlert.delayRepeat }}</td>
            <td>{{ sensorAlert.nbRepeat }}</td>
            <td>
                @if(sensorAlert.sensorsCount == 0) {
                   {{ 'Toutes' | transloco }}
                } @else {
                    {{ sensorAlert.sensorsCount }}
                }
            </td>
            @if(canWrite()) {
            <td class="flex align-items-center justify-content-center">
                <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2" (click)="editAlert(sensorAlert)"></button>
                <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning" (click)="deleteAlert(sensorAlert)"></button>
            </td>
            }
        </tr>
    </ng-template>
</p-table>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>