@defer (when dataLoaded) {
  @if(oneProduct) {
    <div class="grid flex">
      <div class="col-12 md:col-6 lg:col-5">
        @if(photos.length == 1) 
        {
        <img
          [alt]="oneProduct.title"
          [src]="oneProduct.photo1"
          class="productPhoto"
        />
        } 
        @else {
          <p-galleria 
              [value]="photos" 
              [containerStyle]="{ 'max-width': '640px' }" 
              [numVisible]="5">
                  <ng-template pTemplate="item" let-item>
                      <img [src]="item" style="width: 100%;" />
                  </ng-template>
                  <ng-template pTemplate="thumbnail" let-item>
                      <div class="grid grid-nogutter justify-content-center">
                          <img [src]="item" class="miniature" />
                      </div>
                  </ng-template>
          </p-galleria>
        }
        
        <p-dataView #dv [value]="otherProducts">
          <ng-template pTemplate="header">
            <h3 class="productTitle pl-2">{{ 'Produits complémentaires' | transloco }}</h3>
        </ng-template>
          <ng-template pTemplate="list" let-products>
              <div class="grid grid-nogutter">
                  <div class="col-12" *ngFor="let item of products; let first = first" class="col-12">
                      <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3" [ngClass]="{ 'border-top-1 surface-border': !first }">
                          <div class="md:w-10rem relative col-photo">
                              <a (click)="redirectProduct(item)">
                                <img class="block xl:block mx-auto border-round w-full" [src]="item.photo1" [alt]="item.title" />
                              </a>
                          </div>
                          <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                              <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                                  <div>
                                      <div class="text-lg font-medium text-900 mt-2">
                                        <a (click)="redirectProduct(item)">{{ item.title }}</a>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex flex-column md:align-items-end gap-2">
                                  <span class="htPrice">{{ item.htPrice }}&euro; HT</span>
                                  <div class="flex flex-row-reverse md:flex-row">
                                      <p-button icon="fa-solid fa-cart-plus" 
                                        class="flex-auto md:flex-initial white-space-nowrap" 
                                        (click)="addProductToCart(item.id, 1)"
                                        [label]="'Commander' | transloco" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-template>
      </p-dataView>
      </div>
      <div class="col-12 md:col-6 lg:col-7">
        <div class="flex flex-column">
          <h1>{{ oneProduct.title }}</h1>
          <span class="htPrice">{{ oneProduct.htPrice }}&euro;</span>
          <span class="tax">HT</span>
          <p>
            {{ oneProduct.shortDescription }}
          </p>

          <p-fieldset class="no-header mb-3 flex-1">
            <div class="flex flex-row gap-3 align-items-center justify-content-center">

              <label for="integeronly">{{ 'Quantité: ' | transloco }}</label>

              <p-inputNumber 
                inputId="integeronly" 
                [(ngModel)]="qtyOrder"
                class="qtyOrder" /> 

                <button
                  pButton
                  [label]="'Ajouter au panier' | transloco"
                  icon="fa-solid fa-cart-plus"
                  (click)="addToCart()"
                  class="p-button-success flex-1"
                ></button>
            </div>
          </p-fieldset>

          <p-fieldset [toggleable]="true">
              <ng-template pTemplate="header">
                  <div class="flex align-items-center gap-2 px-2">
                      {{ 'Description détaillée' | transloco }}
                  </div>
              </ng-template>
              <p class="m-0" [innerHTML]="sanitizedDescription">
              </p>
          </p-fieldset>
        </div>
      </div>
    </div>
  }
  <p-toast />
} @placeholder {
<div class="text-center">Chargement en cours...</div>
}
