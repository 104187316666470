import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  private paymentInfo: any;

  constructor() {}

  savePaymentInfos(orderInfos:any): void {
    this.paymentInfo = orderInfos;
    localStorage.setItem('payment', JSON.stringify(this.paymentInfo));
  }

  getPaymentInfos(): any[] {
    const savedInfos = localStorage.getItem('payment');
    if (savedInfos) {
      this.paymentInfo = JSON.parse(savedInfos);
    }

    return this.paymentInfo;
  }

  getCountryCode(libellePays:string): string {
    return (libellePays.toLowerCase() == 'france' ? '250' : '724')
  }

  clear() {
    localStorage.removeItem('payment');
  }
}
