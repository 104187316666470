import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';
import { ApiAlertsListService } from "../../../services/api/api-alerts-users";

import { Client } from '../../../models/client';
import { Alerts } from "../../../models/alerts-users";
import { AlertsType } from "../../../models/alerts-users-type-list";
import { AlertsFrequencies } from "../../../models/alerts-users-frequencies-list";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule, Table } from 'primeng/table';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@Component({
  selector: 'app-alerts-for-users',
  templateUrl: './alerts-for-users.component.html',
  styleUrl: './alerts-for-users.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    TriStateCheckboxModule
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AlertsForUsersComponent implements OnDestroy, OnInit  {

  licences: Client[] = [];

  AlertsListSubscription: Subscription = new Subscription();
  AlertsTypeSubscription: Subscription = new Subscription();
  AlertsFrequenciesSubscription: Subscription = new Subscription();

  selectedAlerts = [];
  alertList: Alerts[] = [];
  alertsType: AlertsType[] = [];
  alertsFrequencies: AlertsFrequencies[] = [];

  dropdownAlertsType: { label: string, value: number }[] = [];
  dropdownAlertsFrequencies: { label: string, value: number }[] = [];

  dataLoaded: boolean = false;
  selectedRow: any = null;

  addButtonVisible = false;
  idClient = 0;
  newAlert: any = {};
  selectedLabels: string[] = [];

  @ViewChild('myTable') myTable: Table | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private apiAlertsListService: ApiAlertsListService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.addButtonVisible = false;
    // chargement des process de chaque licences
    this.AlertsListSubscription = this.apiAlertsListService
      .getAlertsList()
      .subscribe((data: Alerts[]) => {
        this.alertList = data;
        this.onImportanceChange(this.alertList);
        this.dataLoaded = true;
      });

    this.AlertsTypeSubscription = this.apiAlertsListService
      .getAlertsType()
      .subscribe((dataAlertsType: AlertsType[]) => {
        this.alertsType = dataAlertsType;
        this.dropdownAlertsType = dataAlertsType.map(alertsType => ({
          label: alertsType.ale_name,
          value: alertsType.ale_id
        }));
      })

    this.AlertsFrequenciesSubscription = this.apiAlertsListService
      .getAlertsFrequencies()
      .subscribe((dataAlertsFrequencies: AlertsFrequencies[]) => {
        this.alertsFrequencies = dataAlertsFrequencies;
        this.dropdownAlertsFrequencies = dataAlertsFrequencies.map(alertsFrequencies => ({
          label: alertsFrequencies.afr_name,
          value: alertsFrequencies.afr_id
        }));
      })

    this.licences = this.authService.getUserLicences();
  }
  canWrite(): boolean {
    return this.authService.canWrite();
  }
  onRowSelect(rowData: any) {
    this.selectedRow = rowData;
  }

  onImportanceChange(alerts: any) {
    if (alerts.cal_importance === 'Information') {
      alerts.cal_importance = 'Important'; // Passer de "info" à "warning"
    } else if (alerts.cal_importance === 'Important') {
      alerts.cal_importance = 'Trés important'; // Passer de "warning" à "danger"
    } else {
      alerts.cal_importance = 'Information'; // Revenir à "info"
    }
  }

  onCellEdit(rowData: any) {
    const selectedAlertsType = this.dropdownAlertsType.find(option => option.value === rowData.alertsType.ale_id);
    if (selectedAlertsType) {
      rowData.alertsType.ale_name = selectedAlertsType.label;
    }
    const selectedAlertsFrequencies = this.dropdownAlertsFrequencies.find(option => option.value === rowData.alertsFrequencies.afr_id);
    if (selectedAlertsFrequencies) {
      rowData.alertsFrequencies.afr_name = selectedAlertsFrequencies.label;
    }
  }

  addAlert(rowData: any) {
    rowData.cal_id = -1;
    this.router.navigate(['settings/alerts-for-users-add/alerts-for-users-add', rowData.client.id],
      { queryParams: { name: rowData.client?.name } });
  }

  deleteAlerte(rowData: any) {
    this.confirmationService.confirm({
      message:
        this.translocoService.translate(
          'Confirmez-vous la suppression DEFINITIVE de l\'alerte'
        ) +
        ' "' +
        rowData.ale_name.substring(2) +
        '" ?',
      header: 'Suppression',
      acceptLabel: this.translocoService.translate('Oui'),
      rejectLabel: this.translocoService.translate('Non'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiAlertsListService.deleteAlerts(rowData.cal_id).subscribe((response) => {
          if (response) {
            window.location.reload();
            console.log('DEL OK');
          } else {
            console.log('DEL KO');
          }
        });
      },
    });
  }

  onFilterApplied(event: any) {
    const first = event.filteredValue.length;  // La valeur de 'first'
    if (event.filters['client.id'] && event.filters['client.id'].value && event.filters['client.id'].value[0] !== null) {
      this.idClient = event.filters['client.id'].value[0];
    }

    this.newAlert.client = {};
    this.newAlert.client.id = this.idClient;
    this.newAlert.client.name = this.selectedLabels[0];
    this.newAlert.cal_id = 0;

    if (first == 0) {
      this.addButtonVisible = true;
    } else {
      this.addButtonVisible = false;
    }
  }
  onFilterChange(event: any, filter: Function): void {
    const selectedIds = event.value;
    this.selectedLabels = this.licences
      .filter(licence => selectedIds.includes(licence.id))
      .map(licence => licence.name);

    filter(selectedIds);
  }

  updateAlerte(event: any) {
    const rowData = {...event};

    let ico = "";
    let refHtml = "";
    let doubleIco = "";

    const TempMessage = rowData['cal_message_perso'];
    rowData['client'] = rowData['client']['id'];
    rowData['cal_afr_id'] = rowData['alertsFrequencies']['afr_id']
    rowData['cal_ale_id'] = rowData['alertsType']['ale_id'];
    switch (rowData.alertsType.ale_id) {
      case 1:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 17:
      case 23:
        refHtml = "launcher";
        break;
      case 2:
      case 18:
        refHtml = "anomalies";
        break;
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        refHtml = "ls_list";
        break;
      case 19:
        refHtml = "traceability";
        break;
      case 20:
        refHtml = "temperatures";
        break;
      case 21:
        refHtml = "tasks";
        break;
      default: break;
    }
    switch (rowData.cal_importance) {
      case "Information":
        ico = "info";
        break;
      case "Important":
        ico = "warning";
        break;
      case "Trés important":
        ico = "exclamation-triangle";
        break;
      default: break;
    }

    if (rowData.cal_importance == "danger") {
      doubleIco = "<i class='fa fa-" + ico + "'><i class='fa fa-" + ico + "'>\n" +
        "</i></i>&nbsp;"
    } else{
      doubleIco = "<i class='fa fa-" + ico + "'>\n" + "/i>&nbsp;"
    }

    rowData['cal_message'] = "<div class='alert alert-block alert-" + ico + " fade in'>" + doubleIco + "Vous avez des " + rowData['ale_name'].substring(2) + " !" + "<div class='pull-right'>\n" +
      "<a href=\"" + refHtml + ".html\" class='btn my_button'>" + rowData['ale_name'].substring(2) +  "</a></div><br>" + TempMessage + "</div>"
    rowData['cal_message_perso'] = TempMessage;
    delete rowData.alertsFrequencies;
    delete rowData.alertsType;
    this.apiAlertsListService.updateAlert(rowData).subscribe((response) => {
      if (response) {
        window.location.reload();
        console.log('Update OK', rowData);
      } else {
        console.log('Update KO', response);
      }
    });
  }

  ngOnDestroy(): void {
    this.AlertsListSubscription.unsubscribe();
    this.AlertsTypeSubscription.unsubscribe();
    this.AlertsFrequenciesSubscription.unsubscribe();
  }
}
