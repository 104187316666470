@defer (when dataLoaded) {
  <div>
    <h3 style="text-align: center">{{  "Process " + "\"" + name + "\"" }}</h3>
  </div>
    <div class="grid">
      <div class="col text-left">
        <button
        pButton
        [label]="'Ajouter un paramètre' | transloco"
        icon="pi pi-plus"
        class="p-button-success"
        (click)="addParameter()"
        [disabled]="areButtonsDisabled"
        ></button>
        <button
        pButton
        [label]="'Annuler' | transloco"
        icon="pi pi-minus"
        class="p-button-unactivate"
        style = "margin-left: 15px"
        (click)="cancel()"
        [disabled]="cancelButtonDisabled"
        ></button>
    </div>
  </div>
  <div>
  <p-table
    [value]="parameters"
    [resizableColumns]="true"
    columnResizeMode="expand"
    selectionMode="single"
    [tableStyle]="{'align-item': 'center', 'min-width': '50px', 'max-width': '370px'}"
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="processListParameters-session"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
    dataKey="id"
    [(selection)]="processSelected"
    sortMode="multiple"
  >
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="tpa_order" style="width:20%">
        {{ 'Ordre' | transloco }} <p-sortIcon field="tpa_order" />
      </th>
      <th pResizableColumn>{{ 'Nom' | transloco }}</th>
      <th pResizableColumn>{{ 'Type' | transloco }}</th>
      <th pResizableColumn>{{ 'Longueur' | transloco }}</th>
      <th pResizableColumn>{{ 'Mode' | transloco }}</th>
      <th pResizableColumn>{{ 'Liste' | transloco }}</th>
      <th pResizableColumn>{{ 'Defaut' | transloco }}</th>
      <th pResizableColumn>{{ 'Default time' | transloco }}</th>
      <th pResizableColumn>{{ 'Validation' | transloco }}</th>
      <th pResizableColumn>{{ 'Placeholder' | transloco }}</th>
      <th pResizableColumn>{{ 'Readonly' | transloco }}</th>
      <th pResizableColumn>{{ 'Requis' | transloco }}</th>
      <th pResizableColumn>{{ 'DLC' | transloco }}</th>
      <th pResizableColumn>{{ 'Date future' | transloco }}</th>
      <th pResizableColumn>{{ 'Dans tableau Histo ?' | transloco }}</th>
      <th style="min-width:10%">{{ 'Action' | transloco}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-processSelected let-ri="rowIndex"
               style="{'align-item': 'center'}">
    <tr>
      <td>{{processSelected.tpa_order}}</td>
      <td>{{processSelected.tpa_name_clean}}</td>
      <td>{{processSelected.tpa_type}}</td>
      <td>{{processSelected.tpa_length}}</td>
      <td>{{processSelected.tpa_input_mode}}</td>
      <td style="max-width: 100px">{{processSelected.tpa_values_list}}</td>
      <td style="max-width: 100px">{{processSelected.tpa_default}}</td>
      <td>{{processSelected.tpa_default_when}}</td>
      <td>{{processSelected.tpa_validation}}</td>
      <td>{{processSelected.tpa_placeholder}}</td>
      <td>{{processSelected.tpa_readonly}}</td>
      <td>{{processSelected.tpa_required}}</td>
      <td>{{processSelected.tpa_is_dlc}}</td>
      <td>{{processSelected.tpa_future_date}}</td>
      <td>{{processSelected.tpa_display_in_table}}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded-edit p-button-text"
            [pTooltip]="'Modifier ce paramètre' | transloco"
            tooltipPosition="left"
            (click)="onRowSelect(processSelected, processSelected.tpa_id)"
            [disabled]="areButtonsDisabled"
            >
          </button>
          <button
            pButton
            icon="pi pi-ban"
            class="flex p-button-rounded p-button-unactivate mr-2"
            (click)="deleteProcessParameter(processSelected)"
            [pTooltip]="'Supprimer ce paramètre' | transloco"
            tooltipPosition="left"
            [disabled]="areButtonsDisabled"
          ></button>
        </div>
      </td>
    </tr>
    <tr></tr>
  </ng-template>
</p-table>
</div>
  <div *ngIf="processisSelected">
    <form [formGroup]="editForm" (ngSubmit)="save()" class="py-5">
      <div class="formgrid grid">
        <div class="field col-2 md:col-1 xl:col-1">
          <label for="param_order" class="label-required">{{ "Ordre" | transloco }}</label>
            <br />
              <input
                type="number"
                pInputText
                formControlName="param_order"
                [required]="true"
                [min] = 1
                [step] = 1
                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                class="p-inputtext w-full mr-2"
              />
            @if(isErrorFormControl(editForm.controls['param_order'])) {
            <br /><small class="text-red-500">{{
            "Ordre requis" | transloco
            }}</small>
            }
      </div>
      <div class="field col-2 md:col-6 xl:col-3">
        <label for="param_name" class="label-required">{{ "Nom" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="param_name"
          [required]="true"
          class="p-inputtext w-full mr-2"
        />
        @if(isErrorFormControl(editForm.controls['param_name'])) {
        <br /><small class="text-red-500">{{ "Nom requis" | transloco }}</small>
        }
      </div>
      <div class="field col-2 md:col-3 xl:col-3">
        <label for="type" class="label-required">{{
          "Type" | transloco
          }}</label>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="filteredTypes"
            class="w-full"
            formControlName="param_type"
            [filter]="true"
            optionLabel="label"
            optionValue="value"
            [required]="true"
            (onChange)= "typeChange()"
          ></p-dropdown>
            @if(isErrorFormControl(editForm.controls['param_type'])) {
            <small class="text-red-500">{{ "Type requis" | transloco }}</small>
            }
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'date'" class="field col-2 md:col-2 xl:col-2">
        <label for="param_dlc" class="label-required">{{
          "DLC ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_dlc"
          [filter]="true"
          [required]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_dlc'])) {
        <br /><small class="text-red-500">{{
        "Mention obligatoire" | transloco
        }}</small>
        }
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value == 'int'
            || editForm.controls['param_type'].value == 'varchar'" class="field col-1 md:col-1 xl:col-2">
        <label for="longueur">{{
          "Longueur" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="longueurText"
          class="w-full"
          formControlName="param_length"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'varchar'
            || editForm.controls['param_type'].value == 'int'" class="field col-2 md:col-2 xl:col-3">
        <label for="mode">{{
          "Mode" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="modeText"
          class="w-full"
          formControlName="param_mode"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['param_mode'])) {
        <small class="text-red-500">{{ "Mode requis" | transloco }}</small>
        }
      </div>
      <div *ngIf="editForm.controls['param_mode'].value === 'list'" class="field col-8 md:col-8 xl:col-8">
        <label for="param_list">{{ "Liste" | transloco }}</label>
        <br />
        <p-chips [(ngModel)]="listValues" separator="," [ngModelOptions]="{standalone: true}"
                 (onBlur)="getTheList()"></p-chips>
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_dlc'].value === 1" class="field col-2 md:col-2 xl:col-2">
        <label for="param_default">{{
          "Nb de jours de DLC par défaut" | transloco
          }}</label>
        <input
          type="number"
          [min]= 1
          [max]= 30
          [step] = 1
          pInputText
          formControlName="param_default"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          class="p-inputtext w-full mr-2"
        />
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'varchar'
            && editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-2">
        <label for="param_default_when">{{
          "Temps par defaut" | transloco
          }}</label>
        <input
          type="number"
          pInputText
          formControlName="param_default_when"
          [min]= 0
          [max]= 30
          [step] = 1
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          class="p-inputtext w-full mr-2"
        />
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'varchar'
            && editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-2">
        <label for="validation">{{
          "Critère de Validation" | transloco
          }}</label>
        <input
          type="text"
          pInputText
          formControlName="param_validation"
          class="p-inputtext w-full mr-2"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-2">
        <label for="param_readOnly">{{
          "Lecture seule ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_readOnly"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-2">
        <label for="param_requis">{{
          "Requis ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_requis"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value == 'date'" class="field col-2 md:col-2 xl:col-2">
        <label for="param_dateFuture">{{
          "Date future ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNon"
          class="w-full"
          formControlName="param_dateFuture"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-3">
        <label for="param_displayInTable">{{
          "Afficher dans le tableau Histo ?" | transloco
          }}</label>
        <p-dropdown
          [style]="{ width: '100%' }"
          [options]="ouiNonHisto"
          class="w-full"
          formControlName="param_displayInTable"
          [filter]="true"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
    </div>
    <div class="formgrid grid">
      <div *ngIf="editForm.controls['param_type'].value !== 'separator'
            && editForm.controls['param_type'].value !== 'comment'" class="field col-2 md:col-2 xl:col-2">
        <label for="placeHolder">{{
          "Valeur exemple" | transloco
          }}</label>
        <input
          type="text"
          pInputText
          formControlName="param_placeHolder"
          class="p-inputtext w-full mr-2"
        />
      </div>
    </div>
  </form>
</div>

<div class="grid py-5">
  <div class="col"></div>
  <div class="col text-right">
    <button
      pButton
      [label]="'Enregistrer' | transloco"
      icon="fa-solid fa-gear"
      class="p-button-success"
      [disabled]="!editForm.valid"
      (click)="save()"
    ></button>
  </div>
</div>

}@placeholder {
<div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
