import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { Subscription } from 'rxjs';

import { ApiClientsService } from '../../../../../services/api/api-clients.service';
import { ClientStatsTarget } from '../../../../../models/client-stats-target';
import { Client } from '../../../../../models/client';
import { AuthService } from '../../../../../services/auth/auth.service';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-stats-targets-list',
  templateUrl: './stats-targets-list.component.html',
  styleUrl: './stats-targets-list.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    InputNumberModule,
    MultiSelectModule,
    PanelModule,
    TableModule,
    ToastModule,
    TranslocoModule    
  ],
  providers: [MessageService],
})
export class StatsTargetsListComponent implements OnInit, OnDestroy {
  statTargets: ClientStatsTarget[] = [];
  dataLoaded: boolean = false;
  targetSubscription: Subscription = new Subscription();
  licences : Client[] = [];

  constructor(
    private apiClientsService: ApiClientsService,
    private authService: AuthService,
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.targetSubscription = this.apiClientsService
      .getStatTargets()
      .subscribe((data: ClientStatsTarget[]) => {
        this.statTargets = data;
        this.dataLoaded = true;
      });
    this.licences = this.authService.getUserLicences();
  }

  ngOnDestroy() {
    this.targetSubscription.unsubscribe();
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  saveStatTarget(statTarget: ClientStatsTarget) {
    if(this.canWrite()) {
      const formData = {
        cli_id: statTarget.client.id,
        tasks: statTarget.tasks,
        temperatures: statTarget.temperatures,
        ncs: statTarget.ncs,
        treatments: statTarget.treatments,
        lots: statTarget.lots,
        photos: statTarget.photos,
      };

      this.apiClientsService.postStatTarget(formData)
      .subscribe(response => {
        if(response) {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translocoService.translate("Succès"), 
            detail: this.translocoService.translate("Le paramètrage a bien été enregistré") 
          });
        } else {
          this.messageService.add({ 
            severity: 'error', 
            summary: this.translocoService.translate("Erreur"), 
            detail: this.translocoService.translate("Une erreur est survenue lors de l'enregistrement") 
          });
        }
      });
    }
  }
}
