import { Client } from "./client";

export class ReceptionOutputList {

  public cli_id: number;
  public cpr_name: string;
  public cpr_id: number;
  public rou_id: number;
  public rou_name: string;
  public rou_rot_id: number;
  public rot_name: string;
  public rou_compulsory: number;
  public rou_order: number;
  public rou_exec_path: string;
  public rou_flag: string;
  public client: Client;

  constructor(
    cli_id: number,
    cpr_name: string,
    cpr_id: number,
    rou_id: number,
    rou_name: string,
    rou_rot_id: number,
    rot_name: string,
    rou_compulsory: number,
    rou_order: number,
    rou_exec_path: string,
    rou_flag: string,
    client: Client,
  ) {
    this.cli_id = cli_id;
    this.cpr_name = cpr_name;
    this.cpr_id = cpr_id;
    this.rou_id = rou_id;
    this.rou_name = rou_name;
    this.rou_rot_id = rou_rot_id;
    this.rot_name = rot_name;
    this.rou_compulsory = rou_compulsory;
    this.rou_order = rou_order;
    this.rou_exec_path = rou_exec_path;
    this.rou_flag = rou_flag;
    this.client = client;
  }
}
