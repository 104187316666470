import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Workspace } from '../../models/workspace';
import { GlobalDatas } from '../../models/global-datas';
import { ApiIndicatorsService } from './api-indicators.service';
import { ApiEshopService } from './api-eshop.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiIndicatorsService: ApiIndicatorsService,
    private apiEshopService: ApiEshopService,
  ) {}

  login(username: string, passwd: string): Observable<any> {
    let formData = new FormData();
    formData.append('login', username);
    formData.append('password', passwd);

    return this.http.post<any>(
      environment.apiURL + 'espace_client/auth.php',
      formData
    );
  }

  loginWithToken(): Observable<any> {
    let formData = new FormData();

    return this.http.post<any>(
      environment.apiURL + 'espace_client/auth.php',
      formData
    );
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_workspaces.php')
      .pipe(
        map((response: any[]) => {
          return response.map((dataWorkspace) => {
            return new Workspace(
              parseInt(dataWorkspace.wrk_id),
              dataWorkspace.wrk_name,
              parseInt(dataWorkspace.wrk_cli_id),
              (dataWorkspace.wrk_parent_id ? parseInt(dataWorkspace.wrk_parent_id) : null)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getGlobalDatas(): Observable<GlobalDatas | null> {
    return this.http
      .get<any>(environment.apiURL + 'espace_client/get_global_datas.php')
      .pipe(
        map((response: any) => {
          return new GlobalDatas(
            response.languages,
            response.userProfiles,
            this.apiIndicatorsService.mapFieldTypes(response.indicatorsFieldsTypes),
            this.apiEshopService.mapCategories(response.eshopCategories)
          );
        }),
        catchError((error) => {
          return of(null);
        })
      );
  }
}