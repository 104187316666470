import { IndicatorFieldType } from "./indicator-field-type";

export class IndicatorField {
    public id: number;
    public parentId: number | null;
    public label: string;
    public ordre: number;
    public fiedType: IndicatorFieldType;
    
    constructor(
        id: number,
        parentId: number | null,
        label: string,
        ordre: number,
        fiedType: IndicatorFieldType,
    ) {
        this.id = id;
        this.parentId = parentId;
        this.label = label;
        this.ordre = ordre;
        this.fiedType = fiedType;
    }
}