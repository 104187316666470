import { ClientGroup } from "./client-group";

export class Client {
    public id: number;
    public name: string;
    public city: string;
    public displayName: string;
    public group: ClientGroup | null;
    public groupSecondary: ClientGroup | null;
    
    constructor(
        id: number,
        name: string,
        city: string = '',
        group: ClientGroup | null = null,
        groupSecondary: ClientGroup | null = null
        ) {
        this.id = id;
        this.name = name;
        this.city = city;
        this.displayName = name + ' - ' + id;

        this.group = group;
        this.groupSecondary = groupSecondary;
    }
}