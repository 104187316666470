import moment from "moment";
import { User } from "./user";

export class IndicatorUser {
    public id: number;
    public dateAdd: moment.Moment;
    public dateResponse: moment.Moment | null;
    public userId: number;
    public user: User | null;
    
    constructor(
        id: number,
        dateAdd: moment.Moment,
        dateResponse: moment.Moment | null,
        userId: number,
        user: User | null
    ) {
        this.id = id;
        this.dateAdd = dateAdd;
        this.dateResponse = dateResponse;
        this.userId = userId;
        this.user = user;
    }
}