import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EshopProduct } from '../../models/eshop-product';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiEshopService {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) {}

  getProducts(): Observable<EshopProduct[]> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_products.php'
      )
      .pipe(
        map((response: any[]) => {
          return response.map((dataProduct) => {
            return new EshopProduct(
              parseInt(dataProduct.epr_id),
              dataProduct.epr_title.trim(),
              this.utilsService.transformStringToUrlFormat(dataProduct.epr_title.trim()),
              dataProduct.epr_description,
              dataProduct.epr_short_description,
              environment.eshopImagesURL + (dataProduct.epr_photo_1 ? dataProduct.epr_photo_1 : 'no_photo.png'),
              (dataProduct.epr_photo_2 ? environment.eshopImagesURL + dataProduct.epr_photo_2 : ''),
              (dataProduct.epr_photo_3 ? environment.eshopImagesURL + dataProduct.epr_photo_3 : ''),
              parseFloat(dataProduct.epr_ht_price),
              parseFloat(dataProduct.epr_tva)
            );
          });
        }),
        catchError((error) => {
          return of([]);
        })
      );
  }

  getOneProduct(productId: string): Observable<EshopProduct> {
    return this.http
      .get<any>(
        environment.apiURL +
          'espace_client/get_eshop_products.php?productId=' +
          productId
      )
      .pipe(
        map((dataProduct: any) => {
            return new EshopProduct(
              parseInt(dataProduct.epr_id),
              dataProduct.epr_title.trim(),
              this.utilsService.transformStringToUrlFormat(dataProduct.epr_title.trim()),
              dataProduct.epr_description,
              dataProduct.epr_short_description,
              (dataProduct.epr_photo_1 ? environment.eshopImagesURL + dataProduct.epr_photo_1 : ''),
              (dataProduct.epr_photo_2 ? environment.eshopImagesURL + dataProduct.epr_photo_2 : ''),
              (dataProduct.epr_photo_3 ? environment.eshopImagesURL + dataProduct.epr_photo_3 : ''),
              parseFloat(dataProduct.epr_ht_price),
              parseFloat(dataProduct.epr_tva)
            );
        })
      );
  }

  postOrder(formData: FormData): Observable<any> {
    return this.http.post<any>(
      environment.apiURL + 'espace_client/post_eshop_order.php',
      formData
    );
  }
}
