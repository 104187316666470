
@defer (when dataLoaded) {
@if(canWrite()) {
    <div class="grid">
        <div class="col">
            <button pButton [label]="'Acheter une sonde' | transloco" icon="fa fa-cart-shopping" class="p-button-cart " (click)="acheterSonde()" ></button>  
        </div>
        <div class="col text-right">
            <button pButton  [label]="'Ajouter une sonde' | transloco" icon="pi pi-plus" class="p-button-success" (click)="addSensor()" ></button>  
        </div>
    </div>
}
<p-table 
    [value]="sensors" 
    styleClass="p-datatable-gridlines p-datatable-striped"
    stateStorage="session"
    stateKey="sensors-list-session"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}" 
    >
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="client.name">{{ 'Licence' | transloco }} <p-sortIcon field="client.name"></p-sortIcon></th>
            <th pSortableColumn="label">
                {{ 'Libellé' | transloco }}
                <p-sortIcon field="label"></p-sortIcon>
            </th>
            <th pSortableColumn="externalCode">{{ 'Code' | transloco }} <p-sortIcon field="externalCode"></p-sortIcon></th>
            <th pSortableColumn="workspace.name">{{ 'Zone' | transloco }} <p-sortIcon field="workspace.name"></p-sortIcon></th>
            <th style="min-width: 100px;"><i class="fa fa-temperature-half"></i></th>
            <th></th>
        </tr>
        <tr>
            <th>
                <p-columnFilter field="client.id" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect 
                            [ngModel]="value" 
                            [options]="licences" 
                            placeholder="Toutes" 
                            (onChange)="filter($event.value)" 
                            optionLabel="displayName" 
                            optionValue="id"
                            [virtualScroll]="true"
                            [virtualScrollItemSize]="50"
                            class="liste-recherche-table"
                            >
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="label" matchMode="contains"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="externalCode" matchMode="contains"></p-columnFilter>
            </th>
            <th>
                <p-columnFilter field="cliId" matchMode="in" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect 
                            [ngModel]="value" 
                            [options]="licences" 
                            placeholder="Toutes" 
                            (onChange)="filter($event.value)" 
                            optionLabel="displayName" 
                            optionValue="id"
                            [virtualScroll]="true"
                            [virtualScrollItemSize]="50"
                            >
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter type="text" field="workspace.name" matchMode="contains"></p-columnFilter>
            </th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sensor>
        <tr>
            <td>{{ sensor.client.name }}</td>
            <td>{{ sensor.label }}</td>
            <td>{{ sensor.externalCode }}</td>
            <td>{{ sensor.workspace.name }}</td>
            <td>
                <div class="grid grid-nogutter align-items-center ">
                    @if(sensor.lastMeasure) {
                        <div class="col text-right last-temperature" [ngClass]="valueInRanges(sensor)">
                            {{ sensor.lastMeasure.toFixed(1) }}°C
                        </div>
                    } 
                    @else {
                        <div class="col text-center">--</div>
                    }
                    @if(sensor.minTemp !== null && sensor.maxTemp !== null) {
                        <div class="col text-right">
                            <div class="borneTemp">{{ sensor.minTemp }}°C</div>
                            <div class="borneTemp">{{ sensor.maxTemp }}°C</div>
                        </div>
                    }
                </div>
            </td>
            <td class="flex align-items-center justify-content-center">
                <button pButton icon="pi pi-chart-line" class="flex p-button-rounded p-button-success mr-2 p-button-gray" (click)="graphSensor(sensor)"></button>
                @if(canWrite()) {
                    <button pButton icon="pi pi-pencil" class="flex p-button-rounded p-button-success mr-2" (click)="editSensor(sensor)"></button>
                    <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning" (click)="deleteSensor(sensor)"></button>
                }
            </td>
        </tr>
    </ng-template>
</p-table>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>