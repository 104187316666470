@defer (when dataLoaded) {
<div class="grid">
  <div class="col text-right">
    <button
      pButton
      [label]="'Ajouter un indicateur de satisfaction' | transloco"
      icon="pi pi-plus"
      class="p-button-success"
      (click)="addIndicator()"
    ></button>
  </div>
</div>
<p-table
  #dt
  [value]="indicators"
  styleClass="p-datatable-gridlines p-datatable-striped"
  stateStorage="session"
  stateKey="indicators-list-session"
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Lignes {first} à {last} sur un total de {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="label">
        {{ "Titre" | transloco }}
        <p-sortIcon field="label"></p-sortIcon>
      </th>
      <th pSortableColumn="nbUsers">
        {{ "Utilisateurs" | transloco }}
        <p-sortIcon field="nbUsers"></p-sortIcon>
      </th>
      <th pSortableColumn="nbResponses">
        {{ "Réponses" | transloco }}
        <p-sortIcon field="nbResponses"></p-sortIcon>
      </th>
      <th pSortableColumn="noteAverage">
        {{ "Note moyenne" | transloco }}
        <p-sortIcon field="noteAverage"></p-sortIcon>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-indic>
    <tr>
      <td>{{ indic.label }}</td>
      <td>{{ indic.nbUsers }}</td>
      <td>{{ indic.nbResponses }}</td>
      <td>{{ indic.noteAverage }}</td>
      <td class="flex align-items-center justify-content-center">
        <button
          pButton
          icon="pi pi-pencil"
          class="flex p-button-rounded p-button-success mr-2"
          (click)="editIndicator(indic)"
        ></button>
        <button
          pButton
          icon="pi pi-users"
          class="flex p-button-rounded p-button-gray mr-2"
          (click)="usersIndicator(indic)"
        ></button>
        <button
          pButton
          icon="fa fa-ranking-star"
          class="flex p-button-rounded p-button-gold mr-2"
          (click)="statsIndicator(indic)"
        ></button>
        <button pButton icon="pi pi-trash" class="flex p-button-rounded p-button-warning" (click)="deleteIndicator(indic)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
