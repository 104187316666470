@defer (when dataLoaded) {
<form [formGroup]="editForm" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <h3 class="pt-0 mt-0">
      <i class="fa fa-address-card mr-2"></i>
      {{ "Informations générales" | transloco }}
    </h3>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="last_name" class="label-required">{{ "Nom" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="last_name"
          [required]="true"
          class="p-inputtext w-full mr-2"
          (blur)="changeNames()"
        />

        @if(isErrorFormControl(editForm.controls['last_name'])) {
        <br /><small class="text-red-500">{{ "Nom requis" | transloco }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="first_name" class="label-required">{{ "Prénom" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="first_name"
          [required]="true"
          class="p-inputtext w-full mr-2"
          (blur)="changeNames()"
        />

        @if(isErrorFormControl(editForm.controls['first_name'])) {
        <br /><small class="text-red-500">{{
          "Prénom requis" | transloco
        }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="login" class="label-required">{{ "Identifiant de connexion" | transloco }}</label>
        <br />
        <input
          type="text"
          pInputText
          formControlName="login"
          [required]="true"
          class="p-inputtext w-full mr-2"
        />

        @if(isErrorFormControl(editForm.controls['login'])) {
        <br /><small class="text-red-500">{{
          "Identifiant de connexion requis" | transloco
        }}</small>
        }
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="password" [class.label-required]="isPasswordRequired">{{ "Mot de passe" | transloco }}</label>
        <br />
        <p-password
          formControlName="password"
          class="w-full mr-2 display-flex"
          autocomplete="new-password"
          [required]="isPasswordRequired"
        />

        @if(isErrorFormControl(editForm.controls['password'])) {
        <br /><small class="text-red-500">{{
          "Mot de passe requis" | transloco
        }}</small>
        } @if(hasFormError(editForm, 'badLengthPassword')) {
        <br /><small class="text-red-500">{{
          "Veuillez fournir un mot de passe entre 8 et 15 caractères"
            | transloco
        }}</small>
        } @if(hasFormError(editForm, 'badFormatPassword')) {
        <br /><small class="text-red-500">{{
          "Votre mot de passe doit contenir au moins une lettre et au moins un chiffre"
            | transloco
        }}</small>
        } @if(hasFormError(editForm, 'nonIdenticalPassword')) {
        <br /><small class="text-red-500">{{
          "Les mots de passe doivent être identiques" | transloco
        }}</small>
        }
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <label for="password_confirm" [class.label-required]="isPasswordRequired">{{
          "Vérification du mot de passe" | transloco
        }}</label>
        <br />
        <p-password
          formControlName="password_confirm"
          class="w-full mr-2"
          autocomplete="new-password"
          [required]="isPasswordRequired"
        />

        @if(isErrorFormControl(editForm.controls['password_confirm'])) {
        <br /><small class="text-red-500">{{
          "Vérification du mot de passe requise" | transloco
        }}</small>
        }
      </div>

      <div class="field col-12 md:col-6 xl:col-6">
        <label for="email">{{ "Email" | transloco }}</label>
        <br />
        <input
          pInputText
          type="email"
          formControlName="email"
          class="p-inputtext w-full mr-2"
        />
        @if(isErrorFormControl(editForm.controls['email'])) {
        <br /><small class="text-red-500">{{
          "L'email est invalide." | transloco
        }}</small>
        }
      </div>

      <div class="field col-12 md:col-6 xl:col-6">
        <label for="telephone_portable">{{
          "Téléphone portable" | transloco
        }}</label>
        <br />
        <input
          pInputText
          type="telephone_portable"
          formControlName="telephone_portable"
          class="p-inputtext w-full mr-2"
        />
      </div>

      <div class="field col-12 md:col-6 xl:col-6">
        <div class="field">
          <label for="profil" class="label-required">{{ "Profil" | transloco }}</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="globalDatas?.userProfiles"
            class="w-full"
            formControlName="profil"
            optionLabel="pro_name"
            optionValue="pro_id"
            [placeholder]="'Sélectionnez un profil' | transloco"
            (ngModelChange)="onChangeProfile($event)"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['profil'])) {
          <small class="text-red-500">{{ "Profil requis" | transloco }}</small>
          }
        </div>
      </div>
      <div class="field col-12 md:col-6 xl:col-6">
        <div class="field">
          <label for="language" class="label-required">{{ "Langue" | transloco }}</label>
          <p-dropdown
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="globalDatas?.languages"
            class="w-full"
            formControlName="language"
            optionLabel="lng_lib"
            optionValue="lng_code"
          ></p-dropdown>
          @if(isErrorFormControl(editForm.controls['language'])) {
          <small class="text-red-500">{{ "Langue requise" | transloco }}</small>
          }
        </div>
      </div>
    </div>

    <h3>
      <i class="fa fa-settings fa-list-check mr-2"></i>
      {{ "Options" | transloco }}
    </h3>
    <div class="field-checkbox flex flex-column align-items-start">
      <p-checkbox
        class="mb-1"
        [binary]="true"
        formControlName="survey_form"
        value="1"
        [label]="'Afficher le questionnaire de formation' | transloco"
      ></p-checkbox>
      <p-checkbox
        class="mb-1"
        [binary]="true"
        formControlName="writer_espace_client"
        [disabled]="writerDisabled"
        [label]="'Autoriser modifications dans l\'espace client' | transloco"
      ></p-checkbox>

      
    </div>
    @if(saveError) {
      <div class="text-red-500 mb-3">
        {{ 'Une erreur est survenue lors de l\'enregistrement. Veuillez changer l\'identifiant de connexion.' | transloco }}
      </div>
      }

      <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <p-button
            type="submit"
            icon="pi pi-save"
            [disabled]="!editForm.valid"
            [label]="'Enregistrer' | transloco"
          ></p-button>
        </div>
        <div class="flex-none flex align-items-center justify-content-center mr-3">
          <div class="label-required">{{ "Champs obligatoires" | transloco }}</div>
        </div>
    </div>  
  </p-panel>
</form>
} @placeholder {
<div class="text-center">{{ "Chargement en cours..." | transloco }}</div>
}
