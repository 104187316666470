export class IndicatorFieldType {
    public id: number;
    public code: string;
    public label: string;
    
    constructor(
        id: number,
        code: string,
        label: string
    ) {
        this.id = id;
        this.code = code;
        this.label = label;
    }
}