<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="field">
      <label for="ind_label" class="label-required">{{
        "Titre" | transloco
      }}</label>
      <input
        type="text"
        pInputText
        id="ind_label"
        formControlName="ind_label"
        required="true"
        autofocus
        class="p-inputtext w-full"
      />
      @if(isErrorFormControl(editForm.controls['ind_label'])) {
      <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
      }
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button
          type="submit"
          icon="pi pi-save"
          [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"
        ></p-button>
      </div>
      <div
        class="flex-none flex align-items-center justify-content-center mr-3"
      >
        <div class="label-required">
          {{ "Champs obligatoires" | transloco }}
        </div>
      </div>
    </div>
  </p-panel>
</form>

@if(indicatorId != '') {
<div class="mt-3">
  <p-panel [header]="'Champs' | transloco">
    <div class="grid">
      <div class="col text-right">
        <button
          pButton
          [label]="'Tout ouvrir / Tout fermer' | transloco"
          icon="fa fa-arrows-up-down"
          class="p-button-gray mr-2"
          (click)="toggleExpand()"
        ></button>
        <button
          pButton
          [label]="'Ajouter un champ' | transloco"
          icon="pi pi-plus"
          class="p-button-success"
          (click)="addField(0)"
        ></button>
      </div>
    </div>
    <p-treeTable
      [value]="indicatorFields"
      styleClass="p-datatable-gridlines p-datatable-striped"
      [scrollable]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "Titre" | transloco }}</th>
          <th>{{ "Type de champ" | transloco }}</th>
          <th>{{ "Ordre d'affichage" | transloco }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{ rowData.label }}
          </td>
          <td>{{ rowData.type_champ_label }}</td>
          <td>{{ rowData.ordre }}</td>
          <td class="flex align-items-center justify-content-center">
            <button
              pButton
              icon="pi pi-pencil"
              class="flex p-button-rounded p-button-success mr-2"
              (click)="editField(rowData)"
            ></button>
            <button
              pButton
              icon="pi pi-plus-circle"
              class="flex p-button-rounded p-button-green mr-2"
              (click)="addField(rowData.id)"
            ></button>
            <button
              pButton
              icon="pi pi-trash"
              class="flex p-button-rounded p-button-warning"
              (click)="deleteField(rowData)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </p-panel>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
}
