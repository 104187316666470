import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../storage/session-storage.service';
import { HttpContextToken } from "@angular/common/http";

const NOT_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService {
    label_error_login = '';
    label_error_password = '';

    constructor(
        private sessionStorage: SessionStorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const appToken = this.sessionStorage.get('appToken');
        if(
            appToken &&
            appToken != null &&
            appToken != undefined &&
            appToken != ''
        ) {
            if (!request.context.get(NOT_TOKEN)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${appToken}`,
                    },
                });
            }
        }

        return next.handle(request);
    }
}
