import { EshopCategory } from "./eshop-category";
import { IndicatorFieldType } from "./indicator-field-type";

export class GlobalDatas {
    public languages: Array<any>;
    public userProfiles: Array<any>;
    public indicatorsFieldsTypes: Array<IndicatorFieldType>;
    public eshopCategories: Array<EshopCategory>;

    constructor(
        languages: Array<any>,
        userProfiles: Array<any>,
        indicatorsFieldsTypes: Array<IndicatorFieldType>,
        eshopCategories: Array<EshopCategory>,
        ) {
        this.languages = languages;
        this.userProfiles = userProfiles;
        this.indicatorsFieldsTypes = indicatorsFieldsTypes;
        this.eshopCategories = eshopCategories;
    }
}