import { Component } from '@angular/core';
import { ContentModalService } from '../../../services/modal/content-modal.service';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
})
export class ContentModalComponent {
  constructor(public contentModalService: ContentModalService) {}

  // show() {
  //   this.modalVisible = true;
  // }

  // hide() {
  //   this.modalVisible = false;
  // }
}