import { IndicatorFieldType } from "./indicator-field-type";

export class GlobalDatas {
    public languages: Array<any>;
    public userProfiles: Array<any>;
    public indicatorsFieldsTypes: Array<IndicatorFieldType>;

    constructor(
        languages: Array<any>,
        userProfiles: Array<any>,
        indicatorsFieldsTypes: Array<IndicatorFieldType>,
        ) {
        this.languages = languages;
        this.userProfiles = userProfiles;
        this.indicatorsFieldsTypes = indicatorsFieldsTypes;
    }
}