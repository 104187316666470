import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth/auth.service';
import { ApiAlertsListService } from "../../../services/api/api-alerts-users";

import { Client } from '../../../models/client';
import { AlertsType } from "../../../models/alerts-users-type-list";
import { AlertsFrequencies } from "../../../models/alerts-users-frequencies-list";

import {TranslocoModule, TranslocoService} from '@ngneat/transloco';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { EditComponent } from '../../core/edit/edit.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import {ToastModule} from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import {InputNumberModule} from "primeng/inputnumber";

@Component({
  selector: 'app-alerts-for-users-add',
  templateUrl: './alerts-for-users-add.component.html',
  styleUrl: './alerts-for-users-add.component.css',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
    TooltipModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextareaModule,
    RadioButtonModule,
    SliderModule,
    InputTextModule,
    ToastModule,
    PanelModule,
    InputNumberModule
  ],
})

export class AlertsForUsersAddComponent extends EditComponent implements OnDestroy, OnInit {

  AlertsTypeSubscription: Subscription = new Subscription();
  AlertsFrequenciesSubscription: Subscription = new Subscription();

  name: string = '';
  licences : Client[] = [];
  clientId: string = '';

  alertsType: AlertsType[] = [];
  alertsFrequencies: AlertsFrequencies[] = [];

  editForm: FormGroup;
  dropdownAlertsType: { label: string, value: number }[] = [];
  dropdownAlertsFrequencies: { label: string, value: number }[] = [];

  selectedAlertTypeId: number | null = null;
  selectedBouttonRadio = "Important";

  value: number = 1;
  constructor(
    private apiAlertsListService: ApiAlertsListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
    private messageService: MessageService,
  ) {
    super(route);

    this.editForm = this.formBuilder.group({
      param_alertsType: ['', Validators.required],
      param_alertsFrequencies: ['', Validators.required],
      param_alertsDelay: [1, Validators.required],
      param_messagePerso: [''],
      alertGroup: [''],
    })
    this.dropdownAlertsType = [];
    this.dropdownAlertsFrequencies = [];
  }

  override ngOnInit() {
    super.ngOnInit();

    this.clientId = this.route.snapshot.paramMap.get('id') ?? '';
    this.titlePage = this.route.snapshot.data['path'].slice(-1)[0]['label'];
    this.route.queryParams.subscribe(params => {
      this.name = params['name'];
    });
    this.licences = this.authService.getUserLicences();

    this.AlertsTypeSubscription = this.apiAlertsListService
      .getAlertsType()
      .subscribe((dataAlertsType: AlertsType[]) => {
        this.alertsType = dataAlertsType;
        this.dropdownAlertsType = dataAlertsType.map(alertsType => ({
          label: alertsType.ale_name,
          value: alertsType.ale_id
        }));
      })

    this.AlertsFrequenciesSubscription = this.apiAlertsListService
      .getAlertsFrequencies()
      .subscribe((dataAlertsFrequencies: AlertsFrequencies[]) => {
        this.alertsFrequencies = dataAlertsFrequencies;
        this.dropdownAlertsFrequencies = dataAlertsFrequencies.map(alertsFrequencies => ({
          label: alertsFrequencies.afr_name,
          value: alertsFrequencies.afr_id
        }));
      })

    this.dataLoaded = true;
  }

  getLabelForValue(value: number | null, dropdownList: { label: string; value: number; }[]): string {
    const selectedOption = dropdownList.find(option => option.value === value);
    return selectedOption ? selectedOption.label : '';
  }
  save() {
    let formData = new FormData();
    const formControls = Object.keys(this.editForm.controls);
    formControls.forEach(controlName => {
      const control = this.editForm.get(controlName);
      if (control) {
        formData.append(controlName, control.value);
      }
    });

    const selectedLabelType = this.getLabelForValue(this.selectedAlertTypeId, this.dropdownAlertsType);
    let ico = "";
    let refHtml = "";
    let icoAlert = "";
    let doubleIco = "";
    const paramAlertsType = formData.get('param_alertsType');
    let buttonToGo = "";
    let debutPhrase = "";

    switch (Number(paramAlertsType)) {
      case 1:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 17:
      case 23:
        refHtml = "launcher";
        break;
      case 2:
      case 18:
        refHtml = "anomalies";
        break;
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        refHtml = "ls_list";
        break;
      case 19:
        refHtml = "traceability";
        break;
      case 20:
        refHtml = "temperatures";
        break;
      case 21:
        refHtml = "tasks";
        break;
      default: break;
    }
    switch (formData.get('alertGroup')) {
      case "Information":
        ico = "info";
        icoAlert = "info";
        break;
      case "Important":
        ico = "warning";
        icoAlert = "warning";
        break;
      case "Trés important":
        ico = "exclamation-triangle";
        icoAlert = "danger";
        break;
      default: break;
    }

    if (icoAlert == "danger") {
      doubleIco = "<i class='fa fa-exclamation-triangle fa-2x'></i>&nbsp;"
    } else{
      doubleIco = "<i class='fa fa-" + ico + "'>\n" + "</i>&nbsp;"
    }
    if (formData.get('param_alertsType') !== '24') {
      buttonToGo = "<div class='pull-center'><a href=\"" + refHtml + ".html\" class='btn my_button'>" + (selectedLabelType).substring(2) +  "</a></div><br>";
      debutPhrase = "Vous n'avez effectué ";
    } else{
      debutPhrase = "Vous avez des  ";
    }
    const rowData: any = {
      cal_id: 0,
      client : this.clientId,
      cal_ale_id : formData.get('param_alertsType'),
      cal_afr_id : formData.get('param_alertsFrequencies'),
      cal_repeat_delay: formData.get('param_alertsDelay') ? Number(formData.get('param_alertsDelay')) * 60 : 0,
      cal_to : "",
      cal_importance : formData.get('alertGroup'),
      cal_message : "<div class='alert alert-block alert-" + icoAlert + " fade in'>"
        + doubleIco
        + debutPhrase
        + (selectedLabelType).substring(2) + " !"
        + buttonToGo
        + formData.get('param_messagePerso') + "</div>",
      cal_message_perso : formData.get('param_messagePerso')
    };
     this.apiAlertsListService.updateAlert(rowData).subscribe((response) => {
       if (response) {
         this.router.navigate(['settings/alerts-for-users']);
         console.log('Update OK', rowData);
       } else {
         this.messageService.add({ severity: 'error', summary: 'Ajout non effectué', detail: 'Cette alerte existe déjà.', life: 3000 });
         console.log('Update KO', response);
       }
     });
    }

  ngOnDestroy() {
    this.AlertsTypeSubscription.unsubscribe();
    this.AlertsFrequenciesSubscription.unsubscribe();
  }
}
