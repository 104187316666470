<form [formGroup]="editForm" style="display: block" (ngSubmit)="save()">
  <p-panel [header]="titlePage">
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="label" class="label-required">{{
          "Titre" | transloco
        }}</label>
        <input
          type="text"
          pInputText
          id="label"
          formControlName="label"
          required="true"
          autofocus
          class="p-inputtext w-full"
        />
        @if(isErrorFormControl(editForm.controls['label'])) {
        <small class="text-red-500">{{ "Titre requis" | transloco }}</small>
        }
      </div>

      <div class="field col-12 md:col-3 xl:col-3">
        <label for="field_type" class="label-required">{{
          "Type de champ" | transloco
        }}</label>
        <p-dropdown
          autoWidth="false"
          [style]="{ width: '100%' }"
          [options]="globalDatas?.indicatorsFieldsTypes"
          class="w-full"
          formControlName="field_type"
          optionLabel="label"
          optionValue="id"
        ></p-dropdown>
        @if(isErrorFormControl(editForm.controls['field_type'])) {
        <small class="text-red-500">{{
          "Type de champ requis" | transloco
        }}</small>
        }
      </div>
      <div class="field col-12 md:col-3 xl:col-3">
        <label for="field_type" class="label-required">{{
          "Champ parent" | transloco
        }}</label>
        <p-treeSelect 
          class="md:w-20rem w-full" 
          formControlName="parent_field_id"
          containerStyleClass="w-full" 
          [options]="indicatorFields" />
      </div>
      <div class="field col-12 md:col-3 xl:col-3">
        <label for="order" class="label-required">{{
          "Ordre d'affichage" | transloco
        }}</label>
        <br />
        <p-inputNumber
          inputId="order"
          formControlName="order"
          [min]="-50"
          [max]="5000"
          [showButtons]="true"
          [required]="true"
        ></p-inputNumber>
        @if(isErrorFormControl(editForm.controls['order'])) {
        <small class="text-red-500">{{ "Ordre requis" | transloco }}</small>
        }
      </div>
    </div>

    @if(saveError) {
    <div class="text-red-500">
      {{ "Une erreur est survenue lors de l'enregistrement" | transloco }}
    </div>
    }
    <div class="flex overflow-hidden">
      <div class="flex-grow-1 flex align-items-center justify-content-center">
        <p-button
          type="submit"
          icon="pi pi-save"
          [disabled]="!editForm.valid"
          [label]="'Enregistrer' | transloco"
        ></p-button>
      </div>
      <div
        class="flex-none flex align-items-center justify-content-center mr-3"
      >
        <div class="label-required">
          {{ "Champs obligatoires" | transloco }}
        </div>
      </div>
    </div>
  </p-panel>
</form>
