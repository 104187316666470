
@defer (when dataLoaded) {
  <p-panel [header]="titlePanel">
    <form [formGroup]="searchForm">
      <div class="formgroup-inline">
        <div class="field">
          <label>{{ "Période du" | transloco }}</label
          >&nbsp;
          <p-calendar
            [readonlyInput]="true"
            [showTime]="true"
            aria-label="Période"
            dateFormat="dd/mm/yy"
            (onSelect)="loadTemperatures()"
            formControlName="periodStart"
            onse
          ></p-calendar>
        </div>
        <div class="field">
          <label>{{ "au" | transloco }}</label
          >&nbsp;
          <p-calendar
            [readonlyInput]="true"
            [showTime]="true"
            aria-label="Période"
            dateFormat="dd/mm/yy"
            (onSelect)="loadTemperatures()"
            formControlName="periodEnd"
          ></p-calendar>
        </div>
      </div>
    </form>
    <p-chart
      #chartTemperatures
      type="line"
      [data]="dataChart"
      [options]="optionChart"
    ></p-chart>
  </p-panel>
}
@placeholder {
    <div class="text-center">{{ 'Chargement en cours...' | transloco }}</div>
}