import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ApiUsersService } from '../../../../services/api/api-users.service';
import { ActivatedRoute } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';

import { Client } from '../../../../models/client';
import { ClientGroup } from '../../../../models/client-group';
import { User } from '../../../../models/user';
import { ApiIndicatorsService } from '../../../../services/api/api-indicators.service';
import { IndicatorUser } from '../../../../models/indicator-user';

@Component({
  selector: 'app-indicators-users',
  templateUrl: './indicators-users.component.html',
  styleUrl: './indicators-users.component.css',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    TranslocoModule,
  ],
})
export class IndicatorsUsersComponent implements OnInit, OnDestroy {
  allUsers: User[] = [];
  licences: Client[] = [];
  availableUsers: User[] = [];

  indicatorsUser: IndicatorUser[] = [];
  indicatorsIdUser: Number[] = [];
  groups: ClientGroup[] = [];

  @ViewChild('tableAvailable') usersAvailableRef!: Table;
  @ViewChild('tableUsers') indicatorUserRef!: Table;


  constructor(
    private authService: AuthService,
    private apiUsersService: ApiUsersService,
    private apiIndicatorsService: ApiIndicatorsService,
    private route: ActivatedRoute
  ) {}

  dataLoaded: boolean = false;
  usersSubscription: Subscription = new Subscription();
  indicUsersSubscription: Subscription = new Subscription();
  indicatorId: string = '';

  ngOnInit(): void {
    this.indicatorId = this.route.snapshot.paramMap.get('id') ?? '';

    if (this.indicatorId != '') {

      this.licences = this.authService.getUserLicences();
      this.groups = this.authService.getUserGroups();

      this.usersSubscription = this.apiUsersService
        .getUsers(true)
        .subscribe((data: User[]) => {
          this.allUsers = data;
          
          this.refreshUsers();
        });
      
    }
  }

  refreshUsers() {
    //on va chercher les users déja affectés
    this.indicUsersSubscription = this.apiIndicatorsService
      .getIndicatorUsers(this.indicatorId)
      .subscribe((lstIndicatorUsers: IndicatorUser[]) => {
        lstIndicatorUsers.forEach((oneIndicatorUser) => {
            // on recupere l'objet User complet
            let oUser = this.allUsers.find(oneUser => oneUser.id == oneIndicatorUser.userId);

            if(oUser) {
              oneIndicatorUser.user = JSON.parse(JSON.stringify(oUser));

              this.indicatorsUser.push(oneIndicatorUser);
              this.indicatorsIdUser.push(oUser.id);
            }

        });

        // dans les users disponibles (pour ajout)
        // on enleve les users déja affectés
        this.availableUsers = this.allUsers.filter((oneUser) => {
          return this.indicatorsIdUser.indexOf(oneUser.id) < 0;
        });

        this.dataLoaded = true;
      });
  }

  canWrite(): boolean {
    return this.authService.canWrite();
  }

  addUser(dataUser: User) {
    this.apiIndicatorsService
      .addUser(parseInt(this.indicatorId), dataUser.id)
      .subscribe((oneIndicatorUser: any) => {
        if (oneIndicatorUser) {

          const index = this.availableUsers.findIndex(
            (user) => user.id == oneIndicatorUser.userId
          );

          if (index !== -1) {
            const userToMove = this.availableUsers.splice(index, 1)[0];

            oneIndicatorUser.user = JSON.parse(JSON.stringify(userToMove));
            this.indicatorsUser.push(oneIndicatorUser);

            this.indicatorUserRef.totalRecords += 1;
            this.usersAvailableRef.totalRecords -= 1;
          }

        }
      });       
  }

  removeUser(dataIndicUser: IndicatorUser) {
    if(dataIndicUser.user) {
      this.apiIndicatorsService
        .removeUser(parseInt(this.indicatorId), dataIndicUser.user.id)
        .subscribe((retourApi: any) => {
          if (retourApi) {

            const index = this.indicatorsUser.findIndex(
              (indicatorUsr) => indicatorUsr.id === dataIndicUser.id
            );
            
            if (index !== -1) {
              const usrToMove = this.indicatorsUser.splice(index, 1)[0];

              if(usrToMove.user) {
                this.availableUsers.push(usrToMove.user);
                
                this.indicatorUserRef.totalRecords -= 1;
                this.usersAvailableRef.totalRecords += 1;
              }
            }

          }
        });      
    }
  }
  
  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
    if (this.indicUsersSubscription) {
      this.indicUsersSubscription.unsubscribe();
    }
  }
}
