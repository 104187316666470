import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ContentModalService {
  public modalVisible: boolean = false;
  private modalTitle:string = '';
  private modalHtml:string = '';

  constructor(private http: HttpClient) { }

  show(modalTitle:string, modalHtml:string = ''): void {
    this.modalVisible = true;
    this.modalTitle = modalTitle;
    this.modalHtml = modalHtml;
  }

  showFile(modalTitle:string, modalFile:string = ''): void {
    this.http.get('assets/docs/'+ modalFile, { responseType: 'text' }).subscribe(
      (htmlContent) => {
        this.show(modalTitle, htmlContent);
      },
      (error) => {
        console.error('Erreur lors du chargement du fichier HTML :', error);
      }
    );
  }

  hide(): void {
    this.modalVisible = false;
  }

  getTitle(): string {
    return this.modalTitle;
  }
  getHtml(): string {
    return this.modalHtml;
  }
}