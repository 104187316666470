import { Component, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../../../services/auth/auth.service';
import { GlobalDatas } from '../../../models/global-datas';
import { SessionStorageService } from '../../../services/storage/session-storage.service';

@Component({
  selector: 'app-left-menu',
  standalone: true,
  imports: [
    ButtonModule,
    MenuModule,
    PanelMenuModule,
    TranslocoModule
  ],
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.css'
})


export class LeftMenuComponent implements OnInit {
  items: MenuItem[] = [];
  restructuredItems: MenuItem[] = [];
  globalDatas: GlobalDatas | null = null;

  constructor(
    private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    // j'ai du mettre un léger timeout sinon la traduction n'est pas totalement initialisée en cas de F5
    setTimeout(() => {
      this.globalDatas = this.sessionStorageService.get('globalDatas');

      let eshopCategories: any = [];
        this.globalDatas?.eshopCategories.forEach(eshopCategory => {
          eshopCategories.push({
            label: this.translocoService.translate(eshopCategory.title),
            icon: 'fa ' + eshopCategory.icon + ' pi-fw',
            routerLink: ['eshop/category/', eshopCategory.id, eshopCategory.titleForUrl]
          });
        });

        eshopCategories.push({
          label: 'Tous les produits',
          icon: 'fa fa-list pi-fw',
          routerLink: 'eshop/all/'
        });

      this.items = [
        {
          label: this.translocoService.translate('Boutique'),
          expanded: true,
          icon: 'fa fa-cart-shopping pi-fw',
          items: [
            {
              label: this.translocoService.translate('Catégories'),
              icon: 'fa fa-folder pi-fw',
              expanded: true,
              items: eshopCategories
            },
            {
              label: this.translocoService.translate('Commandes'),
              icon: 'fa fa-cart-flatbed-suitcase pi-fw',
              routerLink: 'eshop/orders-list'
            },
            {
              label: this.translocoService.translate('Statistiques'),
              icon: 'fa fa-sack-dollar pi-fw',
              routerLink: 'eshop/stats',
              visible: this.authService.isRoot()
            }
          ]
        },
        {
          label: this.translocoService.translate('Tableaux de bord'),
          expanded: true,
          icon: 'fa fa-gauge-high pi-fw',
          items: [
            {
              label: this.translocoService.translate('Alertes'),
              icon: 'fa fa-bell pi-fw',
              routerLink: 'dashboards/sensors-triggered-alert',
            },
            {
              label: this.translocoService.translate('Monitoring CouchDB'),
              icon: 'fa fa-chart-column pi-fw',
              routerLink: 'dashboards/monitoring-couchdb',
              visible: this.authService.isRoot()
            }
          ]
        },
        {
          label: this.translocoService.translate('Paramétrages'),
          expanded: true,
          icon: 'fa fa-sliders pi-fw',
          items: [
            {
              label: this.translocoService.translate('Indicateurs de satisfaction'),
              icon: 'fa fa-clipboard-list fa-lg pi-fw',
              routerLink: 'settings/indicators',
              visible: this.authService.isRoot()
            },
            {
              label: this.translocoService.translate('Objectifs'),
              icon: 'fa fa-bullseye fa-lg pi-fw',
              routerLink: 'settings/stats-targets'
            },
            {
              label: this.translocoService.translate('Message d\'avertissement'),
              icon: 'fa fa-warning fa-lg pi-fw',
              routerLink: 'settings/alerts-for-users'
            },
            {
              label: this.translocoService.translate('Process'),
              expanded: true,
              icon: 'fa fa-list-ul pi-fw',
              visible: this.authService.isRoot(),
              items: [
                {
                  label: this.translocoService.translate('Process de traçabilité'),
                  icon: 'fa fa-arrows-spin pi-fw',
                  routerLink: 'settings/process/process-list-traceability',
                },
                {
                  label: this.translocoService.translate('Process de réception'),
                  icon: 'fa fa-truck fa-lg pi-fw',
                  routerLink: 'settings/process/process-reception',
                }
              ]
            },
            {
              label: this.translocoService.translate('Recettes'),
              icon: 'fa fa-blender fa-lg pi-fw',
              routerLink: 'settings/dishes',
              visible: this.authService.isRoot()
            },
            {
              label: this.translocoService.translate('Sondes'),
              icon: 'fa fa-podcast fa-lg pi-fw',
              expanded: true,
              items: [
                {
                  label: this.translocoService.translate('Liste'),
                  icon: 'fa fa-list pi-fw',
                  routerLink: 'settings/sensors',
                },
                {
                  label: this.translocoService.translate('Alertes'),
                  icon: 'fa fa-bell pi-fw',
                  routerLink: 'settings/sensors-alert',
                }
              ]
            },
            {
              label: this.translocoService.translate('Tables'),
              icon: 'fa fa-table-list fa-lg pi-fw',
              routerLink: 'settings/tables',
              visible: this.authService.isRoot()
            },
            {
              label: this.translocoService.translate('Utilisateurs'),
              icon: 'fa fa-users fa-lg pi-fw',
              routerLink: 'settings/users',
              visible: this.authService.isRoot()
            }
          ],
        },
      ];

      this.items.forEach(item => {
        const newItem: MenuItem = {
          label: item.label,
          expanded: item.expanded,
          icon: item.icon,
          routerLink: item.routerLink,
          disabled: item.disabled
        };

        if (item.items) {
          const topLevelItems: MenuItem[] = [];
          item.items.forEach(subItem => {

            if (subItem.items) {
              topLevelItems.push({
                label: subItem.label,
                icon: subItem.icon,
                routerLink: subItem.routerLink,
                disabled: true,
                styleClass: 'full-opacity'
              });

              subItem.items.forEach(nestedItem => {
                topLevelItems.push({
                  label: nestedItem.label,
                  icon: nestedItem.icon,
                  routerLink: nestedItem.routerLink,
                  disabled: nestedItem.disabled,
                  styleClass: 'ml-3'
                });
              });
            } else {
              topLevelItems.push({
                label: subItem.label,
                icon: subItem.icon,
                routerLink: subItem.routerLink,
                disabled: subItem.disabled
              });
            }
          });
          newItem.items = topLevelItems;
        }

        this.restructuredItems.push(newItem);
      });
    }, 100);



  }

  onNodeCollapse(event: any) {
    const collapsedNode = event.node;
  
    // Vérifie si l'élément ne peut pas être réduit
    if (collapsedNode.unclosable) {
      collapsedNode.expanded = true; // Force l'élément à rester ouvert
    }
  }
}
