import { Injectable } from '@angular/core';
import { SessionStorageService } from '../storage/session-storage.service';
import { TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';

import { Client } from '../../models/client';
import { ClientGroup } from '../../models/client-group';
import { ApiIndicatorsService } from '../api/api-indicators.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn: boolean = false;
  private tokenExpirationTimer: any;
  public tokenLogin: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
    private primeNgConfig: PrimeNGConfig,
    private apiIndicatorsService: ApiIndicatorsService
  ) {
    if (
      this.sessionStorageService.hasKey('appToken') &&
      this.sessionStorageService.get('appToken')
    ) {
      let appToken = this.sessionStorageService.get('appToken');
      this.autoLogout(appToken);

      let usrData = this.sessionStorageService.get('usr_data');
      this.setLanguage(usrData.lang);

      this.translatePrimeNg();
    }
  }

  login(dataLogin: any) {
    this.sessionStorageService.set('appToken', dataLogin.appToken);
    this.sessionStorageService.set('usr_id', dataLogin.usr_id);
    this.sessionStorageService.set('usr_pro_access', dataLogin.pro_access);
    this.sessionStorageService.set('usr_writer', parseInt(dataLogin.usr_writer));
    this.sessionStorageService.set('usr_data', {
      first_name: dataLogin.usr_first_name,
      last_name: dataLogin.usr_last_name,
      lang: dataLogin.usr_lang,
    });
    this.sessionStorageService.set('usr_licences', dataLogin.licences);
    this.sessionStorageService.set('usr_groups', dataLogin.groups);
    this.sessionStorageService.set('isAuth', true);

    this.autoLogout(dataLogin.appToken);
    this.setLanguage(dataLogin.usr_lang);
    this.tokenLogin = false;

    this.translatePrimeNg();
  }

  translatePrimeNg() {
    setTimeout(() => {
      // a voir si on peut automatiser la traduction
      this.primeNgConfig.setTranslation({
        dateFormat: 'dd/mm/yy',
        monthNames: [
          this.translocoService.translate('Janvier'),
          this.translocoService.translate('Février'),
          this.translocoService.translate('Mars'),
          this.translocoService.translate('Avril'),
          this.translocoService.translate('Mai'),
          this.translocoService.translate('Juin'),
          this.translocoService.translate('Juillet'),
          this.translocoService.translate('Août'),
          this.translocoService.translate('Septembre'),
          this.translocoService.translate('Octobre'),
          this.translocoService.translate('Novembre'),
          this.translocoService.translate('Décembre'),
        ],
        dayNamesMin: [
          this.translocoService.translate('Di'),
          this.translocoService.translate('Lu'),
          this.translocoService.translate('Ma'),
          this.translocoService.translate('Me'),
          this.translocoService.translate('Je'),
          this.translocoService.translate('Ve'),
          this.translocoService.translate('Sa')
        ],
        emptyMessage: this.translocoService.translate('Aucun résultat'),
        noFilter: this.translocoService.translate('Effacer la recherche'),
        passwordPrompt: this.translocoService.translate('Entrez un mot de passe'),
        weak: this.translocoService.translate('Faible'),
        medium: this.translocoService.translate('Moyen'),
        strong: this.translocoService.translate('Fort'),
      });
    }, 200);
    
  }

  autoLogout(appToken: string) {
    const tokenData = JSON.parse(atob(appToken.split('.')[1]));
    const expiresIn = tokenData.exp * 1000 - Date.now();

    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expiresIn);
  }

  logout() {
    this.isLoggedIn = false;
    this.sessionStorageService.destroy();
  }

  isLoggedInUser(): boolean {
    return this.sessionStorageService.get('isAuth');
  }

  getUserData(): any {
    return this.sessionStorageService.get('usr_data');
  }
  getUserLicences(): Client[] {
    let dataLicences = this.sessionStorageService.get('usr_licences');
    let licences: Client[] = [];

    dataLicences.forEach((oneLicence: any) => {
      licences.push(
        new Client(
          parseInt(oneLicence['cli_id']),
          oneLicence['cli_name'],
          oneLicence['cli_city'],
          new ClientGroup(parseInt(oneLicence['cgr_id']), oneLicence['cgr_name'])
        )
      );
    });

    return licences;
  }
  getUserGroups(): ClientGroup[] {
    let dataGroups = this.sessionStorageService.get('usr_groups');
    let groups: ClientGroup[] = [];

    dataGroups.forEach((oneLicence: any) => {      
      groups.push(
        new ClientGroup(
          parseInt(oneLicence['cgr_id']),
          oneLicence['cgr_name']
        )
      );
    });

    return groups;
  }
  canWrite(): boolean {
    return this.sessionStorageService.get('usr_writer') === 1;
  }
  isRoot(): boolean {
    return this.sessionStorageService.get('usr_pro_access') == '999';
  }
  setLanguage(codeLang: string) {
    this.translocoService.setActiveLang(codeLang);
  }
  setToken(token:string) {
    this.sessionStorageService.set('appToken', token);
  }
}
